import './index.scss';
import { FC, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { fetchImage, goBack } from '../../nonUIFuncs';
// import { selectReduxSlice } from '../../../Store/store';
// import { useAppSelector } from '../../../Store/hooks';

interface NavButton {
  pathname: string,
  icon: string,
  search: string,
};
const TopNavigationButton: FC<NavButton> = ({pathname, icon, search}) => {
  return (
    <Link
      to={{
        pathname: pathname,
        search: search,
      }}
      className={`topnavigation_button_container_${icon}`}
    />
  );
};


interface TopNavProps {
  showNav: boolean;
  showClearData: boolean;
  setShowClearData: Function;
}

const TopNavigation: FC<TopNavProps> = (
  {showNav, showClearData, setShowClearData}
) => {
  const routeLocation = useLocation();
  // const params = routeLocation.search;
  // const reduxState = useAppSelector(selectReduxSlice);
  let unitName = '';
  let Key:number = 1;
  let searchArr = routeLocation.search.replace('?', '').split('&').find((str:string) => str.includes('unit='));
  if (searchArr !== undefined) {
    unitName = searchArr.split('=')[1].replaceAll('%20', ' ').replaceAll('%27', "'");
    if (isNaN(Number(unitName.slice(0,2)))) {
      Key = Number(unitName.slice(0,1));
      unitName = unitName.slice(1);
    } else {
      Key = Number(unitName.slice(0,2));
      unitName = unitName.slice(2);
    }
  }

  if (showNav === true) {
    return (
      <div className='topnavigation_container'>
        <div className='topnavigation_backgroundimage' />
        <div className='topnavigation_contents'>
          <TopNavigationButton pathname="/" icon="home" search="" />
          <TopNavigationButton
            pathname={goBack(routeLocation).pathname}
            icon="back"
            search={goBack(routeLocation).search}
          />
          {/* <TopNavigationButton pathname="/shoot-me" icon="plus" search="" />
          <TopNavigationButton pathname="/" icon="menu" search="" />
          <TopNavigationButton pathname="/" icon="pause" search="" /> */}
          <div
            className="topnavigation_button_container_exit"
            onClick={() => setShowClearData(!showClearData)}
          />
        </div>
        {unitName.length > 0 ? (
          <div className='topnavigation_headercontainer'>
            <h1 className='topnavigation_header'>{unitName}</h1>
          </div>
        ) : null}
        {unitName.length > 0 ? (
          <div className='topnavigation_badgecontainer'>
            <div className='topnavigation_badge' style={{backgroundImage: `url(${fetchImage(`Common_Category_Badge_${Key}2x.png`)}`}} />
          </div>
        ) : null}
      </div>
    );
  } else { return null; }
};

export default TopNavigation;
export {TopNavigationButton};
