import './index.scss';
import '../../../App.scss';
import { FC, useLayoutEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { findValueFromClamp } from '../../nonUIFuncs';
import { selectReduxSlice } from '../../../Store/store';
import { useAppSelector } from '../../../Store/hooks';

let decProgress = 0;
let prevProgress = 0;
interface ProgressBarProps {
  width: string;
  id: string;
  progress: string;
  isTimer?: boolean;
};

const textopacity = keyframes`
  0% {
    opacity: ${prevProgress === 0 ? 0 : 1};
  } 95% {
    opacity: ${prevProgress === 0 ? 0 : 1};
  } 100% {
    opacity: 1;
  }
`;

const textopacityalt = keyframes`
  0% {
    opacity: 1;
  } 100% {
    opacity: 1;
  }
`;


interface progressBarDimsProps {
  width: number;
  height: number;
}

const ProgressBar: FC<ProgressBarProps> = ({width, id, progress, isTimer}) => {
  const reduxState = useAppSelector(selectReduxSlice);
  const progressBarDimsRef = useRef<progressBarDimsProps>();
  const [progressBarDims, setProgressBarDims] = useState<progressBarDimsProps>({width: 1000, height: 1000});
  progressBarDimsRef.current = progressBarDims;
  useLayoutEffect(() => {
    findProgressBarDims();
    window.addEventListener('resize', findProgressBarDims);
    return () => {
      window.removeEventListener('resize', findProgressBarDims);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  decProgress = Number(progress.replace('%', '').replace('.', '')) / 100;
  const progressbaranimation = keyframes`
    0% {
      width: ${prevProgress}px;
    } 100% {
      width: calc(calc(${width} * 0.9) * ${decProgress});
      background-color: ${
        progress === '100%'
        ? 'rgba(120,166,90,1)'
        : 'rgba(120,90,166,1)'
      };
    }
  `;
  let widthVal = findValueFromClamp(width, reduxState.width);
  setTimeout(() => {prevProgress = ((widthVal * 0.9) * decProgress)}, 50)
  const Progressbar = styled.div`
    width: ${prevProgress}px;
    animation-name: ${progressbaranimation};
  `;
  const ProgressbarP = styled.p`
    animation-name: ${isTimer ? textopacityalt : textopacity};
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  `;
  const findProgressBarDims = () => {
    let element = document.getElementById(id);
    if (element?.getBoundingClientRect().width !== undefined) {
      setProgressBarDims(element?.getBoundingClientRect());
    }
  };
  return (
    <div
      className='progressbar_container'
      style={{width: width, height: `calc(${width} / 5)`}}>
      <div
        className='progressbar_contents'
        id={id}
        style={{height: `calc(${width} / 5)`}}>
        <Progressbar
          className={`progressbar_purplebar${isTimer ? '_timer' : ''}`}
          style={{
            width: `${prevProgress}px`,
            margin: `0px calc(${width} / 20)`,
            top:
              progressBarDimsRef.current.width < 75
              ? '0.5px'
              : progressBarDimsRef.current.width < 136
                ? '1px'
                : 'clamp(0.5px, 0.5vmin, 3px)'
          }}>
          <div
            className='progressbar_stripedbar'>
            {Number(progress.replace('%', '')) > 22 && progressBarDims.width > 80 ? (
              <ProgressbarP
                style={{
                  opacity: isTimer ? 1 : 0,
                  fontSize:
                    progressBarDimsRef.current.height < 12
                      ? '7px'
                      : progressBarDimsRef.current.height < 18
                        ? '8px'
                        : progressBarDimsRef.current.height < 26
                          ? '10px'
                          : 'clamp(11px, 1.2vmin, 1.1em)'
                }}>
                {progress}
              </ProgressbarP>
            ) : null}
          </div>
        </Progressbar>
      </div>
    </div>
  );
};

export default ProgressBar;
