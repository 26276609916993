import './index.scss';
import { FC } from 'react';
import { fetchImage } from '../../nonUIFuncs';

interface FloatingNumberProps {
  correctness: string|null;
};

let plusOne = fetchImage('+1.png')
let minusOne = fetchImage('-1.png')

const FloatingNumber: FC<FloatingNumberProps> = ({correctness}) => {
  if (correctness !== null && correctness.length > 0) {
    return (
      <img
        src={correctness === 'correct' ? plusOne : minusOne}
        className='floatingnumber_plusminusimage'
        alt=''
      />
    );
  } else {
    return null;
  }

};

export default FloatingNumber;
