import './index.scss';
// import '../../../App.scss';
import { FC } from 'react';

interface CloseEndlessProps {
  enabled: boolean;
  onClick: Function;
};


const CloseEndless: FC<CloseEndlessProps> = ({onClick, enabled}) => {
  if (enabled) {
    return (
      <div
        className="playagain_closebutton"
        onClick={() => onClick(true)}
      />
    );
  } else {
    return null;
  }
};

export default CloseEndless;
