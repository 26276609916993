import './index.scss';
import '../../../App.scss';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReadyToStart from '../ReadyToStart';
import { useAppSelector } from '../../../Store/hooks';
import { selectReduxSlice } from '../../../Store/store';
import { goBack } from '../../nonUIFuncs';

interface EndEndlessGameProps {
  enabled: boolean;
  currentLevel: number;
  setShowEndlessEnd: Function;
  updateLevelData: Function;
  endGame: Function|undefined;
};


const EndEndlessGame: FC<EndEndlessGameProps> = (
  {currentLevel, enabled, setShowEndlessEnd, endGame, updateLevelData}
) => {
  const reduxState = useAppSelector(selectReduxSlice);
  let location = useLocation();
  let gameType = reduxState.specificGame.game;
  if (location.state?.categories?.length > 1) {
    gameType = `${location.state?.categories?.length} Categories`;
  }
  let data = {
    gameType: gameType,
    category: location.state?.categories || null,
    difficulty: reduxState.difficulty,
    newLevel: currentLevel,
    spreadGameData: reduxState.specificGame.displayGameType,
  };
  if (enabled) {
    return (
      <ReadyToStart
        categories={undefined}
        children={
          <div className='endendlessgame_container'>
            <div className='endendlessgame_headercontainer'>
              <h1>Are you sure you want to end this game?</h1>
              <h1>
                If you end this game, your current level will be recorded.
              </h1>
            </div>
            <div className='endendlessgame_buttoncontainer'>
              <div 
                className='endendlessgame_button_red'
                onClick={() => setShowEndlessEnd(false)}>
                <p className='endendlessgame_buttontext'>Cancel</p>
              </div>
              <Link
                onClick={() => {
                  endGame !== undefined ? endGame() : console.log('no function');
                  updateLevelData(data);
                }}
                to={{
                  pathname: goBack(location).pathname,
                  search: goBack(location).search
                }}>
                  <div className='endendlessgame_button_green'>
                    <p className='endendlessgame_buttontext'>Continue</p>
                  </div>
              </Link>
            </div>
          </div>
        }
      />
    );
  } else {
    return null;
  }

};

export default EndEndlessGame;
