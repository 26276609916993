import { useLocation } from "react-router-dom";

const AUDIO_PRE = 'https://storage.googleapis.com/denaakke-media/Audio/';
const IMAGE_PRE = 'https://storage.googleapis.com/denaakke-media/Image/';
const AUDIO_PRE_SECONDARY = 'https://storage.googleapis.com/benhti-media/Audio/';
const IMAGE_PRE_SECONDARY = 'https://storage.googleapis.com/benhti-media/Image/';

const fetchImage = (fileName: string, dataset?: string) => {
  if (dataset !== 'b_') {
    return `${IMAGE_PRE}${fileName}`;
  } else {
    return `${IMAGE_PRE_SECONDARY}${fileName}`;
  }
};
const fetchAudio = (fileName: string, dataset?: string) => {
  if (dataset !== 'b_') {
    return `${AUDIO_PRE}${fileName}`;
  } else {
    return `${AUDIO_PRE_SECONDARY}${fileName}`;
  }
};
const useURLParamQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const urlParams = new URLSearchParams();
const useLocationParams = (addStr:string, removeArr:string[]) => {
  let params = useLocation().search;
  if (addStr.length !== 0 || removeArr.length !== 0) {
    return (`${params}&${addStr}`);
  } else {
    return '';
  }
};
const redirectFromParams = (expectedParams:string[]) => {
  // TODO: Redirect from incorrect parameters
    // Must bring in data to know what 'incorrect parameters are
  let params = window.location.search.split('&');
  let paramNames = params.map((str:string) => {
    let newStr = str.replace('?', '');
    return newStr.split('=')[0];
  });
  let missingParams:string[] = [];
  expectedParams.forEach((param:string) => {
    if (!paramNames.includes(param)) {
      missingParams.push(param);
    }
  });
  let redirectPath = '';
  if (missingParams.length > 0) {
    if (missingParams.includes('difficulty')) {
      redirectPath = '/';
    } else if (missingParams.includes('location')) {
      redirectPath = `/choose-location${window.location.search}`;
    } else if (missingParams.includes('unit')) {
      redirectPath = `/choose-unit${window.location.search}`;
    } else if (missingParams.includes('game-type')) {
      redirectPath = `/choose-game-type${window.location.search}`;
    } else if (missingParams.includes('game-name')) {
      redirectPath = `/choose-sub-unit${window.location.search}`;
    }
  }
  return redirectPath;
};
interface locationPropObject {
  pathname: string;
  search: string;
}
const goBack = (location:locationPropObject) => {
  interface locationObj {
    Key: number;
    pathname: string;
    expectedParams: string[];
  }
  const locationArr: locationObj[] = [
    { Key: 0, pathname: '/', expectedParams: [] },
    { Key: 1, pathname: '/choose-location', expectedParams: ['difficulty'] },
    {
      Key: 2,
      pathname: '/choose-unit',
      expectedParams: ['difficulty', 'location'],
    },
    {
      Key: 3,
      pathname: '/choose-game-type',
      expectedParams: ['difficulty', 'location', 'unit'],
    },
    {
      Key: 4,
      pathname: '/choose-sub-unit',
      expectedParams: ['difficulty', 'location', 'unit', 'game-type'],
    },
    {
      Key: 5,
      pathname: '/start-game',
      expectedParams: ['difficulty', 'location', 'unit', 'game-type', 'game-name'],
    },
  ]
  let newURL:locationPropObject = {pathname: '/', search: '' };
  let newURLKeyObject:locationObj[] = locationArr.filter(
    (loc) => loc.pathname === location.pathname,
  );
  let newURLKey = newURLKeyObject.length > 0 ? newURLKeyObject[0].Key - 1 : 0;
  if (newURLKeyObject.length === 0) {
    newURL.pathname = '/';
  } else {
    let obj = locationArr.find((loc) => loc.Key === newURLKey);
    newURL.pathname = obj?.pathname ?? '/';
    newURL.search = filterURLsearch(obj ?? newURLKeyObject[0], location.search);
  }
  return newURL;
};
const filterURLsearch = (
  location: {
    Key: number,
    pathname: string,
    expectedParams: string[]
  },
  search:string
) => {
  let searchToReturn = '?';
  if (location.expectedParams.length === 0) {
    searchToReturn = '';
  } else {
    let searchArr = search.replace('?', '').split('&'); // difficulty=1
    location.expectedParams.forEach((param: string) => {
      searchArr.forEach((str: string) => {
        if (str.includes(param)) {
          searchToReturn = searchToReturn.concat(`&${str}`);
        }
      });
    });
  }
  return searchToReturn;
};




const getSearchParams = (location:locationPropObject) => {
  let paramArray:Array<any> = [];
  let splitLoc: Array<any> = location.search.replaceAll('%20', ' ').replaceAll('%27', "'").replace('?', '').split('&').filter((s) => s.length > 0);
  splitLoc.forEach((s) => {
    let newS = s.split('=');
    let modifier = '';
    if (newS[0] === 'unit') {
      if (isNaN(newS[1].slice(0,2))) {
        modifier = newS[1].slice(0,1);
        newS[1] = newS[1].slice(1);
      } else {
        modifier = newS[1].slice(0,2);
        newS[1] = newS[1].slice(2);
      }
    }
    paramArray.push({id: newS[0], value: newS[1], modifier: modifier});
  });
  return paramArray;
};





const compare = (a:string|number, b:string|number) => {
  let engA = a.toString().toUpperCase();
  let engB = b.toString().toUpperCase();
  let comparison = 0;
  if (!isNaN(Number(engA)) && !isNaN(Number(engB))) {
    if (Number(engA) > Number(engB)) {
      comparison = 1;
    } else {
      comparison = -1;
    }
  } else {
    if (engA > engB) {
      comparison = 1;
    } else {
      comparison = -1;
    }
  }
  return comparison;
};
const shuffle = (array:Array<any>) => {
  let newArray = [...array];
  let j, x, i;
  for (i = newArray.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() *(i + 1));
    x = newArray[i];
    newArray[i] = newArray[j];
    newArray[j] = x;
  }
  return newArray;
};
const placementArray = (
  xStart: number,
  yStart: number,
  xEnd: number,
  yEnd: number,
  xInc: number,
  yInc: number,
) => {
  let array = [];
  let x;
  let y;
  for (x = xStart; x < xEnd; x = x + xInc) {
    for (y = yStart; y < yEnd; y = y + yInc) {
      array.push({x: x, y: y});
    }
  }
  return array;
};
const getLocationFromPath = (string:string) => {
  let locationArr = string.split('/').filter((str) => str.length > 0);
  locationArr = locationArr.map((str) => {
    let newStr = str.replace(/%20/g, ' ');
    return newStr;
  });
  return locationArr;
};
const findValueFromClamp = (clampString: string, max: number) => {
  let fixedString = clampString
    .replace('clamp(', '')
    .replace(')', '')
    .replace(' ', '')
    .split(',');
  let findNum = /\d+/;
  let minValue = Number(fixedString[0].replace('px', ''));
  let variableValue = Number(fixedString[1].match(findNum)) / 100;
  let maxValue = Number(fixedString[2].replace('px', ''));
  let finalValue = max * variableValue;
  if (finalValue > maxValue) {
    finalValue = maxValue;
  } else if (finalValue < minValue) {
    finalValue = minValue;
  }
  return finalValue;
};
const findSingleGameData = (game:{target: string}, reduxState:any, locationParam: any, dataset: string) => {
  let finalData: Array<{}> = [];
  let gameData = locationParam === 'Minto'
    ? reduxState.b_gameData.filter((d: any) => d.subCat === game.target)
      : reduxState.gameData.filter((d: any) => d.subCat === game.target);
  if (dataset === 'b_') {
    return gameData; // Exit out with Benhti data because it doesn't have setIDs or extra logic to run
  }
  let dLocation =
    reduxState.dialectStructure.find((d: any) => d.display === locationParam)
      || reduxState.dialectStructure[0];
  let setIDs:Array<string> = [];
  gameData.forEach((d: any) => {
    if (!setIDs.includes(d.Set)) {
      setIDs.push(d.Set);
    }
  });
  setIDs.forEach((str) => {
    let setArray: Array<{Dialect: string}> = [];
    gameData.forEach((obj: any) => {
      if (obj.Set === str) {
        setArray.push(obj);
      }
    });
    for (let i = 0; i <= dLocation?.structure.length; i++) {
      let obj = dLocation?.structure.find((c: any) => c.Key === i);
      let match = setArray.find((d) => d.Dialect === obj?.code);
      if (match !== undefined) {
        finalData.push(match);
        break;
      } else if (i === dLocation?.structure.length - 1) {
        console.log('No matches were found', setArray);
      }
    }
  });
  return finalData;
};
const findSquishName = (string: string|undefined) => {
  let squishName = '';
  if (string !== undefined) {
    squishName =
      string
        .replaceAll(' ', '')
        .replaceAll('-', '')
        .replaceAll(/[0-9]/g, '');
  }
  return squishName;
};
const findDataset = (datasetprefix: string, str:string) => {
  if (datasetprefix === 'b_') {
    return `b_${str}`;
  } else {
    return str;
  }
};

const capEveryWord = (string: string) => {
  let wordArray = string.split(" ");
  for (let i = 0; i < wordArray.length; i++) {
    wordArray[i] = wordArray[i][0].toUpperCase() + wordArray[i].substr(1);
  }
  return wordArray.join(" ");
};

export {
  fetchImage,
  fetchAudio,
  useURLParamQuery,
  urlParams,
  useLocationParams,
  redirectFromParams,
  goBack,
  compare,
  shuffle,
  getLocationFromPath,
  placementArray,
  getSearchParams,
  findValueFromClamp,
  findSingleGameData,
  findSquishName,
  findDataset,
  capEveryWord,
};