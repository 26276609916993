import './index.scss';
import '../../App.scss'
import { FC, useEffect } from 'react';
import DataImageTest from '../DataImageTest';

const Welcome: FC<{dataset: string}> = ({dataset}) => {
  useEffect(() => {
    document.title = `${dataset === 'b_' ? 'Benhti' : "Denaakk'e"} Kids Zone - Home`;
  }, []);
  return (
    <div className='welcome_container all_pages_container'>
      <div className='welcome_seasonstree'>
        <div className='welcome_modal'>
          <div className='welcome_guidecharacter' />
          <h1 className='welcome_modaltext'>Welcome Back!</h1>
          <h1 className='welcome_modaltext'>Choose your Level!</h1>
        </div>
      </div>
      {/* <DataImageTest /> */}
    </div>
  );
};

export default Welcome;
