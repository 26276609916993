
import './index.scss';
import '../../../App.scss';
import { useAppSelector } from '../../../Store/hooks';
import { selectReduxSlice } from '../../../Store/store';

import { FC, useRef, useEffect } from 'react';

interface RoundBannerProps {
  round: number;
  findStars: Function;
  starsObj: {starOne: boolean; starTwo: boolean; starThree: boolean};
}

const RoundBanner: FC<RoundBannerProps> = ({round, findStars, starsObj}) => {
  const reduxState = useAppSelector(selectReduxSlice);
  const reduxStateRef = useRef(reduxState);
  reduxStateRef.current = reduxState;
  useEffect(() => {
    findStars();
  }, []);
  let finalText = 'You win!';
  if (starsObj.starThree) {
    finalText = 'You Win!';
  } else if (starsObj.starTwo) {
    finalText = 'So Close!';
  } else if (starsObj.starOne) {
    finalText = 'Try Again!'
  }

  return (
    <div className="roundbanner_banner">
      <h1 className="roundbanner_text">{round <= 3 ? `Round ${round}` : finalText}</h1>
    </div>
  );
};

export default RoundBanner;
