import './index.scss';
import { FC, useLayoutEffect } from 'react';
import { fetchImage } from '../../nonUIFuncs';

interface DiffBadgeProps {
  id: number,
  name: string,
  locked: boolean,
  currentScore: number|undefined,
  totalScore: number|undefined,
};

const DifficultyBadge: FC<DiffBadgeProps> = (
  {id, name, locked, currentScore, totalScore}
) => {
  useLayoutEffect(() => {
    let badges = Array.from(document.getElementsByClassName('difficultybadge_container') as HTMLCollectionOf<HTMLElement>);
    let badgeCollection = Array.prototype.slice.call(badges);
    badgeCollection.forEach((element, index) =>
      element.style['animation-delay'] = `${(index * 0.5)}s`
    );
    let scoreContainers = Array.from(document.getElementsByClassName('difficultybadge_scorecontainer') as HTMLCollectionOf<HTMLElement>);
    let scoreContainerCollection = Array.prototype.slice.call(scoreContainers);
    scoreContainerCollection.forEach((element, index) =>
      element.style['animation-delay'] = `${(index * 0.5) + 2.5}s`
    );
  }, []);
  let badgeImage = `url(${fetchImage('Level_'+id+'_Button_Base2x.png')})`;
  return (
    <div className='difficultybadge_container'>
      <div
        className={`difficultybadge_level_${id}`}
        style={{backgroundImage: badgeImage}}>
        <div className={`difficultybadge_cover${locked ? '' : '_unlocked'}`}>
          {locked ? <div className='difficultybadge_lock' /> : null}
        </div>
        {!locked ? (
          <div className='difficultybadge_scorecontainer'>
            <div className='difficultybadge_star' />
            <div className='difficultybadge_score'>
              <p>{currentScore}/{totalScore}</p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DifficultyBadge;
