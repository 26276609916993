import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getLocationFromPath, getSearchParams, shuffle } from "../Components/nonUIFuncs";

import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "@firebase/firestore";
import type { RootState } from "./index";
import PlayerLevelData from '../data/playerLevel.json';

const firebaseConfig = {
  apiKey: "AIzaSyArxfkhCtaBt5eA4quACm8SJZSWpv4C9C4",
  authDomain: "denaakke-6642e.firebaseapp.com",
  projectId: "denaakke-6642e",
  storageBucket: "denaakke-6642e.appspot.com",
  messagingSenderId: "231748182238",
  appId: "1:231748182238:web:286cc46e1480d01d99224f",
  measurementId: "G-FRP2F5NX8W"
};
const firebaseConfigBenhti = {
  apiKey: "AIzaSyBpJI20tNFcBrMLa2yrSIb6GHCRTlYWUyo",
  authDomain: "benhti-7e074.firebaseapp.com",
  projectId: "benhti-7e074",
  storageBucket: "benhti-media",
  messagingSenderId: "870157528415",
  appId: "1:870157528415:web:fb7875a58f58e61dff1aea",
  measurementId: "G-93QSPHLTDC"
};
declare const window: any;
window.app = initializeApp(firebaseConfig);
window.app2 = initializeApp(firebaseConfigBenhti, 'secondary');
const db = getFirestore(window.app);
const dbBenhti = getFirestore(window.app2);
interface dataProps {
  Key: number;
  backgroundImg: string;
  badge: string;
  category: string;
  categoryLanguage: string;
  displayGameName: string;
  displayGameType: string;
  difficulty: number;
  finalScoreImg: string;
  firebaseID: string;
  game: string;
  gameScoreImg: string;
  keyWordImg: string;
  playAgainButton: string;
  playAudio: string;
  playButton: string;
  target: string;
  timerImg: string;
};
interface gameDataProps {
  Dialect: string;
  English: string;
  Key: string;
  Language: string;
  Notes: string;
  Set: string;
  SetID: string;
  App_Audio: string;
  firebaseID: string;
  homeName: string;
  App_Art: string;
  lesson: number;
  location: string;
  locationImage: string;
  subCat: string;
  target: string;
};
interface gameDescriptionsProps {
  game: string;
  description: string;
};
interface goldStarProps {
  Key: number;
  firebaseID: string;
  fiveStar: number;
  fiveStarTwo: number;
  fourStar: number;
  fourStarTwo: number;
  game: string;
  notImplimented: string;
  oneStarEasy: number;
  oneStarTwoEasy: number;
  twoStarEasy: number;
  twoStarTwoEasy: number;
  threeStarEasy: number;
  threeStarTwoEasy: number;
  oneStarMedium: number;
  oneStarTwoMedium: number;
  twoStarMedium: number;
  twoStarTwoMedium: number;
  threeStarMedium: number;
  threeStarTwoMedium: number;
  oneStarHard: number;
  oneStarTwoHard: number;
  twoStarHard: number;
  twoStarTwoHard: number;
  threeStarHard: number;
  threeStarTwoHard: number;
  scoreType: string;
  secondType: string;
  type: string;
};
interface popupWordsProps {
  English: string;
  Key: number;
  Language: string;
  firebaseID: string;
  type: string;
};
interface uiWordsProps {
  English: string;
  Key: number;
  Language: string;
  Number: number;
  firebaseID: string;
};
interface dialectStructureProps {
  display: string;
  code: string;
  structure: Array<{
    Key: number;
    code: string;
  }>
}
interface roundStatsProps {
  hits: number;
  misses: number;
  score: number;
}
interface statsProps {
  hits: number;
  misses: number;
  score: number;
  roundStats: roundStatsProps;
}
interface playerLevelNumbersProps {
  playerLevel: number;
  allTotalStars: number;
  currentStars: number;
  // TODO: 4th Property here
};
interface valueProps {
  width: number;
  height: number;
  stats: statsProps;
  firebaseSheetsPulled: string[];
  location: string;
  homeDifficulty: string;
  data: Array<dataProps>;
  gameData: Array<gameDataProps>;
  b_data: Array<dataProps>;
  b_gameData: Array<gameDataProps>;
  numberData: Array<gameDataProps>;
  b_numberData: Array<gameDataProps>;
  sortedData: Array<gameDataProps>;
  gameDescriptions: Array<gameDescriptionsProps>;
  goldStar: Array<goldStarProps>;
  popupWords: Array<popupWordsProps>;
  uiWords: Array<uiWordsProps>;
  dialectStructure: Array<dialectStructureProps>;
  displayCanvas: boolean;
  counter: number;
  difficulty: string;
  screenDisplay: string;
  specificGame:
    dataProps | {
      category: string;
      game: string;
      Key: number;
      displayGameType: string;
    };
  bottomBarStarValues: {
    currentStars: number,
    totalStars: number,
  };
  playerLevelNumbers: playerLevelNumbersProps;
  ping: number;
};
const initialState: {
  value: valueProps,
} = {
  value: {
    width: window.innerWidth,
    height: window.innerHeight,
    stats: {
      hits: 0,
      misses: 0,
      score: 0,
      roundStats: {
        hits: 0,
        misses: 0,
        score: 0,
      },
    },
    ping: 6000,
    firebaseSheetsPulled: [],
    location: '',
    homeDifficulty: '',
    data: [],
    gameData: [],
    b_data: [],
    b_gameData: [],
    numberData: [],
    b_numberData: [],
    sortedData: [],
    gameDescriptions: [],
    goldStar: [],
    popupWords: [],
    uiWords: [],
    dialectStructure: [
      {
        display: 'Hughes',
        code: 'DC',
        structure: [
          {Key: 0, code: 'DC'},
          {Key: 1, code: 'KR'},
          {Key: 2, code: 'DS'},
        ]
      },
      {
        display: 'Huslia',
        code: 'DC',
        structure: [
          {Key: 0, code: 'DC'},
          {Key: 1, code: 'KR'},
          {Key: 2, code: 'DS'},
        ]
      },
      {
        display: 'Allakaket',
        code: 'DC',
        structure: [
          {Key: 0, code: 'DC'},
          {Key: 1, code: 'KR'},
          {Key: 2, code: 'DS'},
        ]
      },
      {
        display: 'Alatna',
        code: 'AET',
        structure: [
          {Key: 0, code: 'AET'},
          {Key: 1, code: 'DC'},
          {Key: 2, code: 'KR'},
          {Key: 3, code: 'DS'},
        ]
      },
      {
        display: 'Ruby',
        code: 'DC',
        structure: [
          {Key: 0, code: 'DC'},
          {Key: 1, code: 'YR'},
          {Key: 2, code: 'DS'},
        ]
      },
      {
        display: 'Koyukuk',
        code: 'DC',
        structure: [
          {Key: 0, code: 'DC'},
          {Key: 1, code: 'YR'},
          {Key: 2, code: 'DS'},
        ]
      },
      {
        display: 'Nulato',
        code: 'NUL',
        structure: [
          {Key: 0, code: 'NUL'},
          {Key: 1, code: 'DL'},
          {Key: 2, code: 'YR'},
          {Key: 3, code: 'DS'},
        ]
      },
      {
        display: 'Kaltag',
        code: 'KAL',
        structure: [
          {Key: 0, code: 'KAL'},
          {Key: 1, code: 'DL'},
          {Key: 2, code: 'YR'},
          {Key: 3, code: 'DS'},
        ]
      },
      {
        display: 'Tanana',
        code: 'DU',
        structure: [
          {Key: 0, code: 'DU'},
          {Key: 1, code: 'YR'},
          {Key: 2, code: 'DS'},
        ]
      },
      {
        display: 'Rampart',
        code: 'DU',
        structure: [
          {Key: 0, code: 'DU'},
          {Key: 1, code: 'YR'},
          {Key: 2, code: 'DS'},
        ]
      },
      {
        display: 'Manley',
        code: 'DU',
        structure: [
          {Key: 0, code: 'DU'},
          {Key: 1, code: 'YR'},
          {Key: 2, code: 'DS'},
        ]
      },
      {
        display: 'Galena',
        code: 'DC',
        structure: [
          {Key: 0, code: 'DC'},
          {Key: 1, code: 'YR'},
          {Key: 2, code: 'DS'},
        ]
      },
      {
        display: 'Minto',
        code: 'Benhti',
        structure: [
          {Key: 0, code: 'Benhti'},
        ],
      },
    ],
    displayCanvas: false,
    counter: 0,
    difficulty: 'easy',
    specificGame: {
      displayGameType: '',
      game: '',
      Key: 0,
      category: '',
    },
    screenDisplay: '',
    bottomBarStarValues: {
      currentStars: 0,
      totalStars: 1,
    },
    playerLevelNumbers: {
      playerLevel: 0,
      allTotalStars: 1,
      currentStars: 0,
    },
  },
}
export const reduxSlice = createSlice({
  name: "reduxState",
  initialState,
  reducers: {
    getDifficulty: (state, action) => {
      state.value = { ...state.value, difficulty: action.payload };
    },
    getSpecificGame: (state, action) => {
      state.value = { ...state.value, specificGame: action.payload };
    },
    getSortedData: (state, action) => {
      state.value = { ...state.value, sortedData: action.payload };
    },
    // getEndlessCategories: (state, action) => {
    //   state = { ...state, endlessCategories: action.payload };
    // },
    setScreenDisplay: (state, action) => {
      state.value = { ...state.value, screenDisplay: action.payload };
    },
    getStats: (state, action) => {
      state.value = {
        ...state.value,
        stats: {
          ...state.value.stats,
          ...action.payload,
          roundStats: {
            ...state.value.stats.roundStats,
            ...action.payload.roundStats,
          }
        },
      };
    },
    getWidth: (state, action: PayloadAction<number>) => {
      state.value = { ...state.value, width: action.payload };
    },
    getHeight: (state, action: PayloadAction<number>) => {
      state.value = { ...state.value, height: action.payload };
    },
    getHomeDifficulty: (state, action: PayloadAction<string>) => {
      state.value = { ...state.value, homeDifficulty: action.payload };
    },
    getLocation: (state, action: PayloadAction<string>) => {
      state.value = { ...state.value, location: action.payload };
    },
    setFirestoreData: (
      state,
      action: PayloadAction<{nameToStore: string, sortedData: object[]}>
    ) => {
      let firebaseSheets : string[] = [...state.value.firebaseSheetsPulled];
      if (!firebaseSheets.includes(action.payload.nameToStore)) {
        firebaseSheets.push(action.payload.nameToStore);
      }
      state.value = {
        ...state.value,
        [action.payload.nameToStore]: action.payload.sortedData,
        firebaseSheetsPulled: firebaseSheets,
      };
    },
    setDisplayCanvas: ( state, action: PayloadAction<boolean> ) => {
      state.value = {
        ...state.value,
        displayCanvas: action.payload,
      };
    },
    setCounter: (state, action: PayloadAction<number>) => {
      state.value = {
        ...state.value,
        counter: action.payload,
      }
    },
    setBottomPercentageValues: (
      state,
      action: PayloadAction<{currentStars: number, totalStars: number}>
    ) => {
      state.value = {
        ...state.value,
        bottomBarStarValues: {
          currentStars: action.payload.currentStars,
          totalStars: action.payload.totalStars,
        },
      }
    },
    setPlayerLevel: (state, action: PayloadAction<playerLevelNumbersProps>) => {
      state.value = {
        ...state.value,
        playerLevelNumbers: action.payload,
      }
    },
    getDialectNumberData: (
      state,
      action: PayloadAction<Array<gameDataProps>>
    ) => {
      state.value = {
        ...state.value,
        numberData: action.payload,
      }
    },
    setPing: (state, action: PayloadAction<number>) => {
      state.value = {
        ...state.value,
        ping: action.payload,
      }
    }
  },
});

export const {
  getDifficulty,
  getSpecificGame,
  getSortedData,
  // getEndlessCategories,
  setScreenDisplay,
  getStats,
  getWidth,
  getHeight,
  getHomeDifficulty,
  getLocation,
  setFirestoreData,
  setDisplayCanvas,
  setCounter,
  setBottomPercentageValues,
  // setAnimObj,
  setPlayerLevel,
  getDialectNumberData,
  setPing,
} = reduxSlice.actions;

// export const fetchData = async (collection_name: string) => {
//   console.log(collection_name);
//   const docSnap = await getDocs(collection(db, collection_name));
//   const docs = docSnap.docs.map((doc) => {
//     return { ...doc.data(), firebaseID: doc.id };
//   });
//   console.log('Retrieved Docs:', docs);
// };

export const setDifficulty = (difficulty: string) => async (dispatch: any) => {
  dispatch(getDifficulty(difficulty));
};

export const setInitialGameData = (
  loc:any,
  data:Array<dataProps>,
  gameData:Array<gameDataProps>,
  gameType:string,
  singleGameData:Array<gameDataProps>,
) => async (dispatch:any) => {
  let gameTypeCondensed = gameType.replace(/\s/g, "");
  let difficulty = null;
  let specificGameArray:Array<dataProps> = [];
  let specificGame:any = {};
  let newFilteredData:Array<gameDataProps> = [];
  let locationArr = getSearchParams(loc);
  let isEndless = loc.state?.endless || false;
  if (isEndless) {
    difficulty = loc.state.difficulty.toLowerCase();
    document.title = `${gameType} - Endless - Kids Zone`;
    specificGameArray = [];
    data.map((datum) => {
      if (loc.state.categories.includes(datum.category)) {
        if (datum.game === gameTypeCondensed) {
          specificGameArray.push(datum);
        }
      }
    });
    specificGameArray = shuffle(specificGameArray);
    specificGame = specificGameArray[0];
    let englishArray: Array<any> = [];
    newFilteredData = [];
    gameData.forEach((datum) => {
      if (loc.state.categories.includes(datum.homeName)) {
        if (!englishArray.includes(datum.English)) {
          englishArray.push(datum.English);
          newFilteredData.push({
            ...datum, //TODO: why am I not just pushing {...datum}? What was my reasoning there?
            Key: datum.Key,
            homeName: datum.homeName,
            subCat: datum.subCat,
            English: datum.English,
            Language: datum.Language,
            App_Audio: datum.App_Audio,
            App_Art: datum.App_Art,
          });
        }
      }
    });
  } else {
    difficulty = "easy";
    specificGame = data.find(
      (game) =>
        game.category === locationArr.find((d) => d.id === 'unit').value &&
        game.displayGameName === locationArr.find((d) => d.id === 'game-name').value
    );
    newFilteredData = singleGameData;
  }
  dispatch(getDifficulty(difficulty));
  dispatch(getSpecificGame(specificGame));
  dispatch(getSortedData(newFilteredData));
  dispatch(setScreenDisplay('startScreen'));
};

export const setDialectNumberData = (
  dialectNumberData: Array<any>,
) => (dispatch: any) => {
  dispatch(getDialectNumberData(dialectNumberData));
}


export const findPlayerLevel = (
  newStarNum: number,
  currentPlayerLevelNumbersState: playerLevelNumbersProps,
) => async (dispatch:any) => {
  // Passing only the difference between last and new number of stars for the
  // current game and difficulty. The player can never lose stars, only gain.
  let totalStars = currentPlayerLevelNumbersState.allTotalStars;
  let currentStars = currentPlayerLevelNumbersState.currentStars + newStarNum;
  let playerLevel = 0;
  let percentage = (currentStars / totalStars) * 100;
  for (let i = 0; i <= PlayerLevelData.length; i++) {
    let d = PlayerLevelData[i];
    if (d.Progress <= percentage) {
      playerLevel = d.Level;
    } else {
      break;
    }
  }
  dispatch(
    setPlayerLevel({
      playerLevel: playerLevel,
      allTotalStars: totalStars,
      currentStars: currentStars,
    })
  );
};

export const findInitialPlayerLevelOnLoad = (
  currentStars: number,
  totalStars: number,
) => async (dispatch:any) => {
  // Based on currentStars and totalStars, find the player level from
  // PlayerLevelData and set state
  let playerLevel = 0;
  let percentage = (currentStars / totalStars) * 100;
  for (let i = 0; i <= PlayerLevelData.length; i++) {
    let d = PlayerLevelData[i];
    if (d.Progress <= percentage) {
      playerLevel = d.Level;
    } else {
      break;
    }
  }
  dispatch(
    setPlayerLevel({
      playerLevel: playerLevel,
      allTotalStars: totalStars,
      currentStars: currentStars,
    })
  );
};



// export const setSpecificGame = (specificGame) => async (dispatch) => {
//   dispatch(getSpecificGame(specificGame));
// };
// export const setSortedData = (sortedData: Array<any>) => async (dispatch:any) => {
//   dispatch(getSortedData(sortedData));
// };
// export const setEndlessCategories = (categories) => async (dispatch) => {
//   dispatch(getEndlessCategories(categories));
// };

// export const getScreen = (display) => async (dispatch) => {
//   dispatch(setScreenDisplay(display));
// };
export const setStats = (stats: {}) => async (dispatch: any) => {
  // Expected data format: { hits: 4, misses: 1, score: 3, time: 15 }
  dispatch(getStats(stats));
};
export const clearStats = () => async (dispatch: any) => {
  dispatch(
    getStats({
      hits: 0,
      misses: 0,
      score: 0,
      roundStats: {
        hits: 0,
        misses: 0,
        score: 0,
      },
    })
  );
};
export const setWidth = (width: number) => (dispatch: any) => {
  dispatch(getWidth(width));
};
export const setHeight = (height: number) => (dispatch: any) => {
  dispatch(getHeight(height));
};
export const setHomeDifficulty = (difficulty: string) => (dispatch: any) => {
  dispatch(getHomeDifficulty(difficulty));
};
export const setLocation = (location: string) => (dispatch: any) => {
  dispatch(getLocation(location));
};
export const getDisplayCanvas = (bool: boolean) => (dispatch: any) => {
  dispatch(setDisplayCanvas(bool));
};
export const updateCounter = (number: number) => (dispatch: any) => {
  dispatch(setCounter(number));
};
export const getData = (
  collectionName: string,
  nameToStore: string,
) => async (dispatch: any) => {
  const started: any = new Date().getTime();
  const docSnap = await getDocs(collection(db, collectionName));
  const docs = docSnap.docs.map((doc, idx) => {
    let newDoc: {
      Key: number,
      firebaseID: string,
    } = { ...doc.data(), firebaseID: doc.id, Key: doc.data().Key !== undefined ? doc.data().Key : idx };
    return newDoc;
  });
  let filteredData: any = [];
  let idArray: any = [];
  docs.forEach((datum) => {
    if (!idArray.includes(datum.Key) && isNaN(datum.Key) === false) {
      filteredData.push(datum);
      idArray.push(datum.Key);
    }
  });
  let sortedData = filteredData.sort((a: any, b: any) => a.Key - b.Key);
  let cleanedData = [...sortedData];
  if (collectionName === 'KZ_AppData') {
    const ended: any = new Date().getTime();
    dispatch(setPing((ended - started) + 2000));
    cleanedData = sortedData.map((d:{
      SetID: string,
      Set: string,
      Dialect: string,
    }) => {
      let newD = {...d};
      let newSetID = newD.SetID.split('/');
      newD.Set = newSetID[newSetID.length - 1];
      let dialect = newD.Dialect;
      if (dialect.includes('<')) {
        dialect = dialect.substring(
          dialect.indexOf('<') + 1,
          dialect.indexOf('>')
        );
      }
      newD.Dialect = dialect.toUpperCase();
      return newD;
    });
  }
  if (nameToStore === 'gameData') {
    let numberData = cleanedData.filter((d) => d.subCat === 'How Many Are There? 1');
    dispatch(
      setFirestoreData({ sortedData: numberData, nameToStore: 'numberData' })
    );
  }
  dispatch(
    setFirestoreData({ sortedData: cleanedData, nameToStore: nameToStore })
  );
  if (nameToStore === 'gameData' || nameToStore === 'data') {
    dispatch(getBenhtiData(collectionName, `b_${nameToStore}`))
  }
};
export const getBenhtiData = (
  collectionName: string,
  nameToStore: string,
) => async (dispatch: any) => {
  const docSnap = await getDocs(collection(dbBenhti, collectionName));
  const docs = docSnap.docs.map((doc, idx) => {
    let newDoc: {
      Key: number,
      firebaseID: string,
    } = { ...doc.data(), firebaseID: doc.id, Key: doc.data().Key !== undefined ? doc.data().Key : idx };
    return newDoc;
  });
  let filteredData: any = [];
  let idArray: any = [];
  docs.forEach((datum) => {
    if (!idArray.includes(datum.Key) && isNaN(datum.Key) === false) {
      filteredData.push(datum);
      idArray.push(datum.Key);
    }
  });
  let sortedData = filteredData.sort((a: any, b: any) => a.Key - b.Key);
  let cleanedData = [...sortedData];
  if (collectionName === 'KZ_AppData') {
    cleanedData = sortedData.map((d:{
      Dialect: string|undefined,
    }) => {
      let newD = {...d};
      newD.Dialect = 'Benhti';
      return newD;
    });
  }
  if (nameToStore === 'gameData') {
    let numberData = cleanedData.filter((d) => d.subCat === 'How Many Are There? 1');
    dispatch(
      setFirestoreData({ sortedData: numberData, nameToStore: 'b_numberData' })
    );
  }
  dispatch(
    setFirestoreData({ sortedData: cleanedData, nameToStore: nameToStore })
  );
};

export const getBottomPercentageValues = (
  currentStars: number,
  totalStars: number,
) => async (dispatch: any) => {
  dispatch(
    setBottomPercentageValues(
      {currentStars: currentStars, totalStars: totalStars}
    )
  );
};

export default reduxSlice.reducer;

export const selectReduxSlice = (state: RootState) => state.store.value;
