import './index.scss';
import '../../App.scss';

import { useEffect, useRef, useState, FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { selectReduxSlice, setStats } from '../../Store/store';
import { useLocation } from 'react-router-dom';
import {
  fetchAudio,
  fetchImage,
  shuffle,
  getSearchParams,
  capEveryWord,
} from '../nonUIFuncs';
import styled from 'styled-components';

import PopupWord from '../UI/PopupWord';
import CloseEndless from '../UI/CloseEndless';
import EndEndlessGame from '../UI/EndEndlessGame';
import FloatingNumber from '../UI/FloatingNumber';
import RoundBanner from '../UI/RoundBanner';



let audioFile: any = null;
let correctSound = new Audio(`${fetchAudio('correctSound.mp3')}`);
let smallerDim = 4;
let chosenTarget: any = null;
let chosenDistractors = [];
let fullRoundData: Array<any> = [];
let flipWord = false;
let fullTime = 30;
let showPopup = false;
let round = 0;
let ready = false;
let switchDisplayVar = 'stats';
let shortTimer = 0;

interface GameContainerProps {
  checkWorld: Function;
  setProgress: Function;
  updateLevelData: Function;
  children: any;
  findStars: Function;
  starsObj: {starOne: boolean; starTwo: boolean; starThree: boolean};
  dataset: string;
}

const WhackAMole: FC<GameContainerProps> = ({
  checkWorld,
  setProgress,
  updateLevelData,
  children,
  findStars,
  starsObj,
  dataset,
}) => {
  let updateClock: any;
  let timer: any;

  const dispatch = useAppDispatch();
  const reduxState = useAppSelector(selectReduxSlice);
  const Location = useLocation();
  const Params = getSearchParams(Location);
  const difficultyRef = useRef('');
  const currentLevelRef = useRef(0);
  const currentLevelProgressRef = useRef(0);
  const reduxStateRef = useRef(reduxState);
  const bodyXRef = useRef(0);
  const bodyYRef = useRef(0);
  const mouseClickXRef = useRef(0);
  const mouseClickYRef = useRef(0);
  const gameDOMArrayRef = useRef<Array<any>>([]);

  const [keyword, setKeyword] = useState('');
  const [correctness, setCorrectness] = useState('');
  const [mouseClickX, setMouseClickX] = useState(0);
  const [mouseClickY, setMouseClickY] = useState(0);
  const [showEndlessEnd, setShowEndlessEnd] = useState(false);
  const [chosenPopupWord, setChosenPopupWord] = useState('');
  const [currentLevel, setCurrentLevel] = useState(0);
  const [displayedClock, setDisplayedClock] = useState(0);
  const [difficulty, setDifficulty] = useState('easy');
  const [currentLevelProgress, setCurrentLevelProgress] = useState(0);
  const [bodyX, setBodyX] = useState(0);
  const [bodyY, setBodyY] = useState(0);
  const [gameDOMArray, setGameDOMArray] = useState<Array<any>>([])

  difficultyRef.current = difficulty;
  currentLevelProgressRef.current = currentLevelProgress;
  currentLevelRef.current = currentLevel;
  reduxStateRef.current = reduxState;
  bodyXRef.current = bodyX;
  bodyYRef.current = bodyY;
  mouseClickXRef.current = mouseClickX;
  mouseClickYRef.current = mouseClickY;
  gameDOMArrayRef.current = gameDOMArray;

  useEffect(() => {
    let string = `${Params.find((d) => d.id === "unit").value} - ${Params.find((d) => d.id === "game-name").value} - ${reduxStateRef.current.difficulty}`;
    document.title = capEveryWord(string);
    switchDisplayVar = 'stats';
    round = 0;
    shortTimer = 0;
    setGameDOMArray([]);
    intervalEntity();
    smallerDim = reduxStateRef.current.height;
    if (reduxStateRef.current.height > reduxStateRef.current.width) {
      smallerDim = reduxStateRef.current.width;
    }
    setCurrentLevel(0);
    setCurrentLevelProgress(0);
    setDifficulty(reduxStateRef.current.difficulty);
    setDisplayedClock(0);
    getRoundData(reduxStateRef.current.difficulty);
    return (() => {
      setGameDOMArray([]);
      clearInterval(timer);
      clearInterval(updateClock);
      switchDisplayVar = 'stats';
      round = 0;
      shortTimer = 0;
      pauseAllAudio();
      setDifficulty('easy');
    });
  },[]);
  const intervalEntity = () => {
    let newItemInterval = 1800;
    if (difficultyRef.current === 'medium') {
      newItemInterval = 1500;
    } else if (difficultyRef.current === 'hard') {
      newItemInterval = 1200;
    }
    timer = setInterval(() => changeDisplay(), newItemInterval);
    if (Location?.state?.endless === true) {
      updateClock = setInterval(() => getRoundData(difficultyRef.current), 10000);
    } else {
      updateClock = setInterval(updateDisplayedClock, 1000);
    }
  };
  const nextEndlessLevel = () => {
    setCurrentLevel(currentLevelRef.current + 1);
    setCurrentLevelProgress(0);
    let number = document.getElementById('levelNumber');
    number?.classList.add('levelNumber');
    setTimeout(() => number?.classList.remove('levelNumber'), 1200);
  };
  const updateDisplayedClock = () => {
    shortTimer = shortTimer + 1;
    if (shortTimer === 10 || shortTimer === 20) {
      pauseAllAudio();
      getRoundData(difficultyRef.current);
    }
    setDisplayedClock(shortTimer + 1);
    setProgress(`${Math.round(((shortTimer + 1) / fullTime)* 100)}%`);
  };
  const getRoundData = (dif: string) => {
    let previousTarget = chosenTarget;
    let shuffled = shuffle(reduxState.sortedData);
    chosenTarget = shuffled[0];
    if (previousTarget !== null) {
      while (previousTarget.English === chosenTarget.English) {
        shuffled = shuffle(reduxState.sortedData);
        chosenTarget = shuffled[0];
      }
    }
    setKeyword(chosenTarget.Language);
    let findDistractors = shuffled.filter(
      (d) => d.English !== chosenTarget.English
    );
    let shuffledDistractors = shuffle(findDistractors);
    if (dif === 'easy') {
      chosenDistractors = shuffledDistractors.slice(0, 1);
    } else if (dif === 'medium') {
      chosenDistractors = shuffledDistractors.slice(0, 2);
    } else {
      chosenDistractors = shuffledDistractors.slice(0, 3);
    }
    fullRoundData = [];
    if (dif === 'easy') {
      fullRoundData.push(chosenTarget);
      fullRoundData.push(chosenTarget);
    } else if (dif === 'medium') {
      fullRoundData.push(chosenTarget);
      fullRoundData.push(chosenTarget);
      fullRoundData.push(chosenTarget);
    } else {
      fullRoundData.push(chosenTarget);
      fullRoundData.push(chosenTarget);
      fullRoundData.push(chosenTarget);
      fullRoundData.push(chosenTarget);
    }
    chosenDistractors.forEach((d) => {
      fullRoundData.push(d);
    });
    playAudio(chosenTarget.App_Audio);
  };
  const pauseAllAudio = () => {
    correctSound.pause();
    correctSound.currentTime = 0;
    if (audioFile !== null) {
      audioFile.pause();
      audioFile.currentTime = 0;
    }
  };
  const playAudio = (fileName: string) => {
    audioFile = new Audio(`${fetchAudio(fileName, dataset)}`);
    audioFile.play();
  };
  const changeDisplay = () => {
    let imgDim = smallerDim / 5;
    let randomTarget = Math.floor(Math.random() * fullRoundData.length);
    let moleData = fullRoundData[randomTarget];
    let gameTarget = moleData.App_Art;
    let bodyXVar = bodyXRef.current;
    let bodyYVar = bodyYRef.current;
    let randomX = Math.random();
    let randomY = Math.random();
    let xDifficulty = 3;
    let yDifficulty = 6.6;
    if (difficultyRef.current === 'easy') {
      while (bodyXVar === bodyXRef.current && bodyYVar === bodyYRef.current) {
        randomX = Math.random();
        randomY = Math.random();
        if (randomY <= 0.5) {
          bodyYVar = 3;
        } else {
          bodyYVar = 5;
        }
        if (bodyYVar === 5) {
          if (randomX <= 0.5) {
            bodyXVar = 1;
          } else {
            bodyXVar = 2;
          }
        } else {
          bodyXVar = 1.5;
        }
      }
    } else if (difficultyRef.current === 'medium') {
      while (bodyXVar === bodyXRef.current && bodyYVar === bodyYRef.current) {
        randomX = Math.random();
        randomY = Math.random();
        if (randomY <= 0.5) {
          bodyYVar = 3;
        } else {
          bodyYVar = 5;
        }
        if (randomX <= 0.5) {
          bodyXVar = 1;
        } else {
          bodyXVar = 2;
        }
      }
    } else {
      while (bodyXVar === bodyXRef.current && bodyYVar === bodyYRef.current) {
        xDifficulty = 4;
        randomX = Math.random();
        randomY = Math.random();
        if (randomY <= 0.5) {
          bodyYVar = 3;
        } else {
          bodyYVar = 5;
        }
        if (randomX <= 0.33) {
          bodyXVar = 1;
        } else if (randomX <= 0.66) {
          bodyXVar = 2;
        } else {
          bodyXVar = 3;
        }
      }
    }
    let moleSprite = `${fetchImage(gameTarget, dataset)}`;
    let positionStyle = {
      backgroundImage: `url(${moleSprite})`,
      top: ((reduxStateRef.current.height * bodyYVar) / yDifficulty
        - imgDim / 2) - (reduxStateRef.current.height / 5),
      left: ((reduxStateRef.current.width * bodyXVar) / xDifficulty
        - imgDim / 2) - (reduxStateRef.current.width / 12),
      width: imgDim,
      height: imgDim,
    };
    let newGameDOMArray = gameDOMArrayRef.current.filter(
      (element) => element.props['data-position'] !== `${bodyXVar},${bodyYVar}`,
    );
    setGameDOMArray([
      ...newGameDOMArray,
      <div
        className='whackamole_mole'
        onClick={(e) => checkCorrectness(e)}
        key={`${randomX}${randomY}`}
        data-position={`${bodyXVar},${bodyYVar}`}
        data-english={moleData.English}
        style={positionStyle}
      />
    ]);
    setBodyX(bodyXVar);
    setBodyY(bodyYVar);
  };
  const checkCorrectness = (
    e: any
  ) => {
    setMouseClickX(e.pageX);
    setMouseClickY(e.pageY);
    pauseAllAudio();
    let clicked = fullRoundData.find((d) => d.English === e.target.dataset.english);
    setGameDOMArray(gameDOMArrayRef.current.filter((ele) => ele.props['data-position'] !== e.target.dataset.position));
    playAudio(clicked.App_Audio);
    if (clicked.English === chosenTarget.English) {
      let popupWords = reduxStateRef.current.popupWords.filter((d) => d.type === 'correct');
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      correctSound.volume = 0.2;
      correctSound.play();
      dispatch(
        setStats({
          hits: reduxStateRef.current.stats.hits + 1,
          score: reduxStateRef.current.stats.score + 1,
          roundStats: {
            hits: reduxStateRef.current.stats.roundStats.hits + 1,
            score: reduxStateRef.current.stats.roundStats.score + 1,
          },
        })
      );
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness('correct');
      let progress = currentLevelProgressRef.current + 1;
      setCurrentLevelProgress(progress);
      if (
        currentLevelRef.current + 2 === progress &&
        Location?.state?.endless === true
      ) {
          setTimeout(nextEndlessLevel, 700);
        }
    } else {
      let popupWords = reduxStateRef.current.popupWords.filter((d) => d.type === 'incorrect');
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          misses: reduxStateRef.current.stats.misses + 1,
          score: reduxStateRef.current.stats.score - 1,
          roundStats: {
            misses: reduxStateRef.current.stats.roundStats.misses + 1,
            score: reduxStateRef.current.stats.roundStats.score - 1,
          },
        })
      );
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness('wrong');
      if (currentLevelProgressRef.current > 0) {
        setCurrentLevelProgress(currentLevelProgressRef.current - 1);
      }
    }
    setTimeout(() => setCorrectness(''), 1000);
  };
  const stepDifficulty = () => {
    setDisplayedClock(0);
    setProgress('0%');
    ready = false;
    switchDisplayVar = 'game';
    if (round < 3) {
      round = round + 1;
      changeDisplay();
    } else {
      checkWorld();
    }
  };
  if (switchDisplayVar === 'stats') {
    if (Location?.state?.endless) {
      ready = false;
      switchDisplayVar = 'game';
      changeDisplay();
      return null;
    }
    if (shortTimer >= 1) {
      stepDifficulty();
    }
    pauseAllAudio();
  } else {
    if (fullTime <= shortTimer) {
      setDisplayedClock(0);
      shortTimer = 0;
      switchDisplayVar = 'stats';
    }
  }
  const FloatingNumberContainer = styled.div`
    position: absolute;
    z-index: 999999;
    top: calc(${mouseClickYRef.current}px - 10vh);
    left: ${mouseClickXRef.current}px;
    height: 2px;
    width: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `;
  return (
    <div style={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
      {children}
      {switchDisplayVar === 'stats' ? (
        <RoundBanner
          round={round + 1}
          findStars={findStars}
          starsObj={starsObj}
        />
      ) : (
      <div className='centerContentContainer'>
        <FloatingNumberContainer>
          <FloatingNumber correctness={correctness} />
        </FloatingNumberContainer>
        <div
          className='whackamole_keywordbox'
          onClick={() => playAudio(chosenTarget.App_Audio)}>
          <div
            className='keyword noselect whackamole_keyword'
            style={{
                fontSize:
                  keyword.length > 21 
                    ? '2vmin'
                    : keyword.length > 12 ? '3vmin' : '5vmin',
              }}>
            {keyword}
          </div>
        </div>
        <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
        {gameDOMArrayRef.current}
        <CloseEndless
          enabled={Location?.state?.endless}
          onClick={setShowEndlessEnd}
        />
        <EndEndlessGame
          enabled={showEndlessEnd}
          currentLevel={currentLevel}
          setShowEndlessEnd={setShowEndlessEnd}
          updateLevelData={updateLevelData}
          endGame={undefined}
        />
      </div>
    )}
    </div>
  );
};

export default WhackAMole;
