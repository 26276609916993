import './index.scss';
import '../../../App.scss';
import { FC, useEffect } from 'react';
import DifficultyBadge from '../DifficultyBadge';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../Store/hooks';
import { getDisplayCanvas, selectReduxSlice } from '../../../Store/store';
import { fetchImage } from '../../nonUIFuncs';
// import { useLocationParams } from '../../nonUIFuncs';
import ProgressBar from '../ProgressBar';

interface BottomNavProps {
  fullStarScores: Array<fullStarScores>,
};

interface fullStarScores {
  difficulty: number,
  currentScore: number,
  totalScore: number,
};

interface BottomContainerProps {
  children: any,
}


const BottomContainer: FC<BottomContainerProps> = ({children}) => {
  return (
    <div className='bottomnavigation_container'>
      <div className='bottomnavigation_backgroundimage' />
      {children}
    </div>
  );
};

const BottomNavigation: FC<BottomNavProps> = (
  {fullStarScores}
) => {
  const dispatch = useAppDispatch();
  const reduxState = useAppSelector(selectReduxSlice);
  let routeLocation = useLocation();

  const findStars = (
    difficultyNum: number,
    starType: string,
  ) => {
    let cumulativeScores = fullStarScores.filter((d) => d.difficulty <= difficultyNum);
    let stars = 0;
    cumulativeScores.forEach((d: any) => {
      stars = stars + d[starType];
    });
    return stars;
  };
  if (routeLocation.pathname === '/') {
    let difficultyArray : Array<{
      key: number,
      name: string,
      locked: boolean,
      currentScore: number,
      totalScore: number,
    }> = [
      {
        key: 1,
        name: 'Mouse',
        locked: false,
        currentScore: findStars(1, 'currentScore') || 0,
        totalScore: findStars(1, 'totalScore') || 1,
      },
      {
        key: 2,
        name: 'Rabbit',
        locked: false,
        currentScore: findStars(2, 'currentScore') || 0,
        totalScore: findStars(2, 'totalScore') || 1,
      },
      {
        key: 3,
        name: 'Beaver',
        locked: false,
        currentScore: findStars(3, 'currentScore') || 0,
        totalScore: findStars(3, 'totalScore') || 1,
      },
      {
        key: 4,
        name: 'Moose',
        locked: true,
        currentScore: findStars(4, 'currentScore') || 0,
        totalScore: findStars(4, 'totalScore') || 1,
      },
      {
        key: 5,
        name: 'Wolf',
        locked: true,
        currentScore: findStars(5, 'currentScore') || 0,
        totalScore: findStars(5, 'totalScore') || 1,
      },
    ];
    let locked = false;
    difficultyArray.map((d) => {
      let newD = d;
      newD.locked = locked;
      let percentage = (d.currentScore / d.totalScore) * 100;
      // At 75% of current highest difficulty progress, new difficulty will unlock
      if (percentage < -1) {
        locked = true;
      }
      return newD;
    });
    return (
      <BottomContainer>
        <div className='bottomnavigation_welcomecontents'>
          {difficultyArray.map((dif) => {
            return (
              <Link
                key={dif.key}
                to={{
                  pathname: '/choose-location',
                  search: `difficulty=${dif.key}`,
                }}
                onClick={() => dispatch(getDisplayCanvas(true))}
                className={dif.locked === true ? 'disabled_link' : ''}>
                <DifficultyBadge {...dif} id={dif.key} />
              </Link>
            );
          })}
        </div>
      </BottomContainer>
    );
  } else if (
    routeLocation.pathname === '/choose-unit' ||
    routeLocation.pathname === '/choose-game-type'
  ) {
    let difficulty = Number(
      routeLocation.search
        .replace('?', '')
        .split('&')
        .find((str:string) => str.includes('difficulty'))
        ?.split('=')[1]);
    let progress =
      Math.round(
        (reduxState.bottomBarStarValues.currentStars
          / reduxState.bottomBarStarValues.totalStars)
      * 100);
    return (
      <BottomContainer>
        <div className='bottomnavigation_chooseunitcontents'>
          <div
            className='bottomnavigation_chooseunitbadge'
            style={{
              backgroundImage:
                `url(${fetchImage('Level_'+difficulty+'_Button_Base2x.png')})`
            }}
          />
          <ProgressBar
            width='clamp(100px, 60vw, 220px)'
            id=''
            progress={`${progress}%`}
          />
          {/* TODO: remove lock on this display if it unlocked */}
          {difficulty <= 4 ? (
            <div
              className='bottomnavigation_chooseunitbadge'
              style={{
                backgroundImage:
                  `url(
                    ${fetchImage('Level_'+(difficulty+1)+'_Button_Base2x.png')}
                  )`
              }}>
              {progress < 70 ? (
                <div className='bottomnavigation_chooseunitbadgecover'>
                  <div className='bottomnavigation_chooseunitbadgelock' />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </BottomContainer>
    );
  } else if (routeLocation.pathname === '/start-game') {
    return null;
  } else {
    return <BottomContainer children={null} />;
  }
};

export default BottomNavigation;
