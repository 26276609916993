import './index.scss';
import '../../App.scss';
import { useEffect, useRef, useState, SetStateAction, FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { selectReduxSlice, setStats } from '../../Store/store';
import { useLocation } from 'react-router-dom';
import {
  fetchAudio,
  fetchImage,
  shuffle,
  placementArray,
  capEveryWord,
  getSearchParams,
} from '../nonUIFuncs';
import styled from 'styled-components';

import PopupWord from '../UI/PopupWord';
import CloseEndless from '../UI/CloseEndless';
import EndEndlessGame from '../UI/EndEndlessGame';
import FloatingNumber from '../UI/FloatingNumber';
import RoundBanner from '../UI/RoundBanner';

let difficultyVar = 'easy';
let displayArray:any = [];
let displayArrayDenom = [3,3];
let currentTarget: {
  App_Art: string,
  App_Audio: string,
  English: string,
  Language: string,
} = {
  App_Art: '',
  App_Audio: '',
  English: '',
  Language: '',
};
let imageArray: any = [];
let gameDOMArray:any = [];
let targetAudio:any = undefined;
let correctSoundEffect:any = null;
let testNum = 0;
let showPopup = false;
let roundTime = 60;
let ready = false;
let shortTimer = 0;

interface GameContainerProps {
  checkWorld: Function;
  updateLevelData: Function;
  children: any;
  setProgress: Function;
  findStars: Function;
  starsObj: {starOne: boolean; starTwo: boolean; starThree: boolean};
  dataset: string;
}

const GoodGuess: FC<GameContainerProps> = ({
  checkWorld,
  updateLevelData,
  children,
  setProgress,
  findStars,
  starsObj,
  dataset,
}) => {
  const dispatch = useAppDispatch();
  const reduxState = useAppSelector(selectReduxSlice);
  const Location = useLocation();
  const Params = getSearchParams(Location);

  const roundClockRef = useRef(0);
  const currentLevelRef = useRef(0);
  const currentLevelProgressRef = useRef(0);
  const reduxStateRef = useRef(reduxState);
  const mouseClickXRef = useRef(0);
  const mouseClickYRef = useRef(0);
  const roundRef = useRef(0);

  const [keyword, setKeyword] = useState('');
  const [chosenPopupWord, setChosenPopupWord] = useState('');
  const [display, setDisplay] = useState('stats');
  const [correctness, setCorrectness] = useState<string|null>(null);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [previousTarget, setPreviousTarget] = useState<SetStateAction<any>>(undefined);
  const [roundClock, setRoundClock] = useState(0);
  const [currentLevelProgress, setCurrentLevelProgress] = useState(0);
  const [showEndlessEnd, setShowEndlessEnd] = useState(false);
  const [ mouseClickX, setMouseClickX ] = useState(0);
  const [ mouseClickY, setMouseClickY ] = useState(0);
  const [ round, setRound ] = useState(0);

  roundClockRef.current = roundClock;
  currentLevelProgressRef.current = currentLevelProgress;
  currentLevelRef.current = currentLevel;
  reduxStateRef.current = reduxState;
  mouseClickXRef.current = mouseClickX;
  mouseClickYRef.current = mouseClickY;
  roundRef.current = round;

  useEffect(() => {
    let string = `${Params.find((d) => d.id === "unit").value} - ${Params.find((d) => d.id === "game-name").value} - ${reduxStateRef.current.difficulty}`;
    document.title = capEveryWord(string);
    let timer = setInterval(updateDisplayedClock, 1000);
    let changeDisplayTimer = setInterval(callNewDisplay, 6000);
    setCurrentLevelProgress(0);
    setDisplay('stats');
    difficultyVar = reduxStateRef.current.difficulty;
    correctSoundEffect = new Audio(`${fetchAudio('correctSound.mp3')}`);
    setRound(0);
    ready = true;
    return () => {
      setDisplay('stats');
      clearInterval(timer);
      clearInterval(changeDisplayTimer);
      setRoundClock(0);
      setRound(0);
      shortTimer = 0;
      difficultyVar = 'easy';
      if (targetAudio !== undefined) {
        targetAudio?.playableAudio?.pause();
        targetAudio.currentTime = 0;
        targetAudio = undefined;
      }
    };
  }, []);
  const callNewDisplay = () => {
    if (roundClockRef.current < roundTime) {
      changeDisplay();
    }
  };
  const updateDisplayedClock = () => {
    shortTimer = shortTimer + 1;
    let testVal = Math.floor((shortTimer/roundTime)*100);
    setProgress(testVal >= 100 ? '100%' : `${testVal}%`);
    if (Location?.state?.endless !== true) {
      setRoundClock(roundClockRef.current + 1);
    }
  };
  const changeDisplay = () => {
    setPreviousTarget(currentTarget);
    imageArray = [];
    let shuffled = shuffle(reduxStateRef.current.sortedData);
    currentTarget = shuffled[0];
    if (previousTarget !== undefined) {
      while (previousTarget.English === currentTarget.English) {
        shuffled = shuffle(reduxStateRef.current.sortedData);
        currentTarget = shuffled[0];
      }
    }
    playTargetAudio();
    setKeyword(currentTarget.Language);
    let correctAmount = 2;
    let i = 0;
    let distracterAmount = 1;
    if (difficultyVar === 'easy') {
      correctAmount = Math.floor(Math.random() * 2) + 1;
      for (i = 0; i < correctAmount; i++) {
        imageArray.push(currentTarget);
      }
      let fullDistractorArray = shuffled.filter(
        (d) => d.English !== currentTarget.English,
      );
      displayArray = placementArray(1, 1.5, 3, 3.5, 1, 1);
      displayArrayDenom = [4, 4];
      distracterAmount = displayArray.length - correctAmount;
      let slicedDistractorArray = fullDistractorArray.slice(0, distracterAmount);
      let doubledDistractors:Array<any> = [];
      slicedDistractorArray.forEach((d) => {
        doubledDistractors.push(d);
        doubledDistractors.push(d);
      });
      let shuffledDistractors = shuffle(doubledDistractors);
      let finalDistractors = shuffledDistractors.slice(0, distracterAmount);
      finalDistractors.forEach((d) => imageArray.push(d));
    } else if (difficultyVar === 'medium') {
      correctAmount = Math.floor(Math.random() * 3) + 1;
      for (i = 0; i < correctAmount; i++) {
        imageArray.push(currentTarget);
      }
      let fullDistractorArray = shuffled.filter((d) => d.English !== currentTarget.English);
      displayArray = placementArray(0.5, 1.5, 3.5, 3.5, 1, 1);
      displayArrayDenom = [4, 4];
      distracterAmount = displayArray.length - correctAmount;
      let slicedDistractorArray = fullDistractorArray.slice(0, distracterAmount);
      let doubledDistractors:Array<any> = [];
      slicedDistractorArray.forEach((d) => {
        doubledDistractors.push(d);
        doubledDistractors.push(d);
      });
      let shuffledDistractors = shuffle(doubledDistractors);
      let finalDistractors = shuffledDistractors.slice(0, distracterAmount);
      finalDistractors.forEach((d) => imageArray.push(d));
    } else if (difficultyVar === 'hard') {
      correctAmount = Math.floor(Math.random() * 4) + 1;
      for (i = 0; i < correctAmount; i++) {
        imageArray.push(currentTarget);
      }
      let fullDistractorArray = shuffled.filter((d) => d.English !== currentTarget.English);
      displayArray = placementArray(1.25, 1.25, 4.25, 3.5, 1, 1);
      displayArrayDenom = [6, 5];
      if (reduxStateRef.current.height > reduxStateRef.current.width) {
        displayArray = placementArray(0.5, 1, 3.5, 3.25, 1, 1);
        displayArrayDenom = [4, 5];
      }
      distracterAmount = displayArray.length - correctAmount;
      let slicedDistractorArray = fullDistractorArray.slice(0, distracterAmount);
      let doubledDistractors: Array<any> = [];
      slicedDistractorArray.forEach((d) => {
        doubledDistractors.push(d);
        doubledDistractors.push(d);
      });
      let shuffledDistractors = shuffle(doubledDistractors);
      let finalDistractors = shuffledDistractors.slice(0, distracterAmount);
      finalDistractors.forEach((d) => imageArray.push(d));
    }
    imageArray = shuffle(imageArray);
    gameDifficultyDisplay();
  };
  const nextEndlessLevel = () => {
    setCurrentLevel(currentLevelRef.current + 1);
    setCurrentLevelProgress(0);
    let number = document.getElementById('levelNumber');
    number?.classList.add('levelNumber');
    setTimeout(
      () => number?.classList.remove('levelNumber'),
      1200
    );
  };
  const checkCorrectness = (e:any) => {
    let clickedObject = gameDOMArray.find(
      (DOMElement:any) => DOMElement.props.id === e.target.id,
    );
    setMouseClickX(e.pageX);
    setMouseClickY(e.pageY);
    let clickedObjectImg = clickedObject.props.children.props.style.backgroundImage;
    let targetImgPath = `url(${fetchImage(currentTarget.App_Art, dataset)})`;
    if (targetImgPath === clickedObjectImg) {
      let popupWords = reduxStateRef.current.popupWords.filter((d) => d.type === 'correct');
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      correctSoundEffect.play();
      dispatch(
        setStats({
          hits: reduxStateRef.current.stats.hits + 1,
          score: reduxStateRef.current.stats.score + 1,
          roundStats: {
            hits: reduxStateRef.current.stats.roundStats.hits + 1,
            score: reduxStateRef.current.stats.roundStats.score + 1,
          },
        })
      );
      let progress = currentLevelProgressRef.current + 1;
      setCurrentLevelProgress(progress);
      if (currentLevelRef.current + 2 === progress && Location?.state?.endless === true) {
        setTimeout(nextEndlessLevel, 700);
      }
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness('correct');
    } else {
      let popupWords = reduxStateRef.current.popupWords.filter(
        (d) => d.type === 'incorrect'
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      if (currentLevelProgressRef.current > 0) {
        setCurrentLevelProgress(currentLevelProgressRef.current - 1);
      }
      dispatch(
        setStats({
          misses: reduxStateRef.current.stats.misses + 1,
          score: reduxStateRef.current.stats.score - 1,
          roundStats: {
            misses: reduxStateRef.current.stats.roundStats.misses + 1,
            score: reduxStateRef.current.stats.roundStats.score - 1,
          },
        })
      );
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness('wrong');
    }
    setTimeout(() => setCorrectness(null), 1000);
    gameDOMArray = gameDOMArray.filter(
      (DOMElement:any) => DOMElement.props.id !== e.target.id
    );
  };
  const stepDifficulty = () => {
    setProgress('0%');
    ready = false;
    setDisplay('game');
    if (roundRef.current < 3) {
      setRoundClock(0);
      setRound(roundRef.current + 1);
      changeDisplay();
    } else {
      checkWorld();
    }
  };
  const gameDifficultyDisplay = () => {
    let key = 0;
    gameDOMArray = [];
    imageArray.map((d: any) => {
      let imgDimm = reduxStateRef.current.width / 6;
      let buffer = 15;
      if (reduxStateRef.current.width > reduxStateRef.current.height) {
        imgDimm = reduxStateRef.current.height / 6;
      }
      if (difficultyVar !== 'hard') {
        if (imgDimm < 50) {
          imgDimm = 50;
        } else if (imgDimm > 375) {
          imgDimm = 375;
        }
      } else {
        imgDimm = reduxStateRef.current.width / 8;
        if (reduxStateRef.current.width > reduxStateRef.current.height) {
          imgDimm = reduxStateRef.current.height / 8;
        }
        if (imgDimm < 35) {
          imgDimm = 35;
        } else if (imgDimm > 300) {
          imgDimm = 300;
        }
      }

      let xyCoord = displayArray[key];
      key = key + 1;
      if (xyCoord !== undefined) {
        let vocabContainer = {
          top:
            (reduxStateRef.current.height * xyCoord.y) / displayArrayDenom[1] - 
            (imgDimm + buffer) / 2,
          left:
            (reduxStateRef.current.width * xyCoord.x) / displayArrayDenom[0] - 
            (imgDimm + buffer) / 2,
        };
        let vocabImg = {
          backgroundImage: `url(${fetchImage(d.App_Art, dataset)})`,
          height: imgDimm,
          width: imgDimm,
        };
        gameDOMArray.push(
          <div
            key={key}
            id={`${key}`}
            style={vocabContainer}
            className='goodguess_vocabcontainer'>
            <div
              id={`${key}`}
              style={vocabImg}
              className='goodguess_vocab'
              onClick={checkCorrectness}
            />
          </div>
        );
        ready = true;
      }
    })
  };
  const playTargetAudio = () => {
    if (targetAudio !== undefined) {
      targetAudio.pause();
      targetAudio.currentTime = 0;
      targetAudio = undefined;
    }
    if (currentTarget.App_Audio.length > 0) {
      targetAudio = new Audio(`${fetchAudio(currentTarget.App_Audio, dataset)}`);
      targetAudio.play();
    } else {
      console.log('no audio listed for', currentTarget);
    }
  };

  if (display === 'stats') {
    if (Location?.state?.endless ? Location?.state?.endless : false) {
      ready = false;
      setDisplay('game');
      changeDisplay();
      return null;
    }
    if (shortTimer > 2) {
      stepDifficulty();
    } else {
      if (targetAudio !== undefined) {
        targetAudio.pause();
        targetAudio.currentTime = 0;
        targetAudio = undefined;
      }
    }
  } else {
    if (testNum !== reduxState.height * reduxState.width) {
      testNum = reduxState.height * reduxState.width;
      gameDifficultyDisplay();
    }
    if (roundClockRef.current === roundTime && ready === true) {
      shortTimer = 0;
      setDisplay('stats');
    }
  }
  const FloatingNumberContainer = styled.div`
    position: absolute;
    z-index: 999999;
    top: calc(${mouseClickYRef.current}px - 10vh);
    left: ${mouseClickXRef.current}px;
    height: 2px;
    width: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `;
  return (
    <div className='goodguess_fullscreen'>
      {children}
      {display === 'stats' ? (
        <RoundBanner
          round={roundRef.current + 1}
          findStars={findStars}
          starsObj={starsObj}
        />
      ) : (
        <div className='centerContentContainer'>
          <FloatingNumberContainer>
            <FloatingNumber correctness={correctness} />
          </FloatingNumberContainer>
          <div
            className='goodguess_keywordbox'
            onClick={() => playTargetAudio()}>
            <div
              className='keyword noselect goodguess_keyword'
              style={{
                fontSize:
                  keyword.length > 21 
                    ? '2vmin'
                    : keyword.length > 12 ? '3vmin' : '5vmin',
              }}>
              {keyword}
            </div>
          </div>
          <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
          {gameDOMArray}
          <CloseEndless
            enabled={
              Location?.state?.endless ? Location?.state?.endless : false
            }
            onClick={setShowEndlessEnd}
          />
          <EndEndlessGame
            enabled={showEndlessEnd}
            currentLevel={currentLevel}
            setShowEndlessEnd={setShowEndlessEnd}
            updateLevelData={updateLevelData}
            endGame={undefined}
          />
        </div>
      )}
    </div>
  );
};

export default GoodGuess;
