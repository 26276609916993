import './index.scss';
import '../../App.scss'
import { FC, useEffect, useState } from 'react';
import {
  fetchImage,
  redirectFromParams,
  fetchAudio,
  capEveryWord,
} from '../nonUIFuncs';
import { useNavigate, useLocation } from 'react-router-dom';
import IndivGame from '../UI/GameTypeButton';
import { useAppSelector } from '../../Store/hooks';
import { selectReduxSlice } from '../../Store/store';
import { getSearchParams, findSingleGameData } from '../nonUIFuncs';

interface DifficultyProps {
  difficulty: number;
  firstStars: number;
  secondStars: number;
  stars: number;
}
interface SubUnitMenuProps {
  scoreData: Array<{
    category: string;
    gameType: string;
    gameName: string;
    target: string;
    difficultyScores: {
      easy: DifficultyProps;
      medium: DifficultyProps;
      hard: DifficultyProps;
    };
  }>;
  dataset: string;
};

const SubUnitMenu: FC<SubUnitMenuProps> = ({scoreData, dataset}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = getSearchParams(location);
  const locationParam = params.find((d) => d.id === 'location')?.value;
  const difficultyParam = params.find((d) => d.id === 'difficulty');
  const unitParam = params.find((d) => d.id === 'unit');
  const gameTypeParam = params.find((d) => d.id === 'game-type');
  const reduxState = useAppSelector(selectReduxSlice);
  const [gamesToDis, setGamesToDis] = useState<Array<any>>([]);
  const [singleGameData, setSingleGameData] = useState<Array<any>>([]);
  useEffect(() => {
    let string = `${params.find((d) => d.id === "unit").value} games - ${gameTypeParam.value}`;
    document.title = capEveryWord(string);
    let redirectPath:string =
      redirectFromParams(
        ['difficulty', 'location', 'unit', 'game-type']
      ) || '';
    if (redirectPath.length > 0) {
      navigate(redirectPath);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
        if (dataset === 'b_') {
      if (scoreData.length > 0) {
        findSubGameCats(reduxState.b_data);
      }
    } else {
      if (scoreData.length > 0) {
        findSubGameCats(reduxState.data);
      }
    }
  }, [scoreData, reduxState.data, reduxState.b_data, dataset]);
  const findSubGameCats = (data: any) => {
    let filteredData = data.filter(
      (d: any) => d.category === unitParam.value,
    );
    filteredData = filteredData.filter(
      (d: any) => d.game === gameTypeParam.value
          .replaceAll(' ', '')
          .replaceAll('-', ''),
    );
    filteredData = filteredData.map((datum: any) => {
      let currentStars = 0;
      let totalStars = 0;
      let theGame = scoreData.find(
        (d) => d.target === datum.target && d.gameType === datum.game,
      );
      // TODO: Update total and current stars based on theGame data
      let goldStarStats = reduxState.goldStar.find(
        (d) => d.game === theGame?.gameType,
      );
      if (theGame !== undefined) {
        if (
          theGame?.difficultyScores.easy.difficulty <= difficultyParam.value
        ) {
          currentStars = currentStars + theGame.difficultyScores.easy.stars;
          if (goldStarStats?.secondType !== 'none') {
            totalStars = totalStars + 6;
          } else {
            totalStars = totalStars + 3;
          }
        }
        if (
          theGame?.difficultyScores.medium.difficulty <= difficultyParam.value
        ) {
          currentStars = currentStars + theGame.difficultyScores.medium.stars;
          if (goldStarStats?.secondType !== 'none') {
            totalStars = totalStars + 6;
          } else {
            totalStars = totalStars + 3;
          }
        }
        if (
          theGame?.difficultyScores.hard.difficulty <= difficultyParam.value
        ) {
          currentStars = currentStars + theGame.difficultyScores.hard.stars;
          if (goldStarStats?.secondType !== 'none') {
            totalStars = totalStars + 6;
          } else {
            totalStars = totalStars + 3;
          }
        }
      }
      let progress = Math.round((currentStars / totalStars) * 100);
      let starProgress = 0;
      if (progress >= 99) {
        starProgress = 3;
      } else if (progress >= 50 && progress < 99) {
        starProgress = 2;
      } else if (progress >= 1 && progress < 50) {
        starProgress = 1;
      } else {
        starProgress = 0;
      }
      return {
        ...datum,
        progress: progress,
        starProgress: starProgress,
      };
    });
    setGamesToDis(filteredData);
  };
  return (
    <div className='all_pages_container subunitmenu_container'>
      <div className='subunitmenu_innercontainer'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          <div className='subunitmenu_contentcontainer'>
            {gamesToDis.map((game) => {
              let gameImg = game.displayGameType
                .replaceAll('-', '_')
                .replaceAll(' ', '_');
              return (
                <IndivGame
                  path='/start-game'
                  key={`${game.displayGameName}`}
                  name={`${game.displayGameName}`}
                  progress={`${game.progress}%`}
                  stars={game.starProgress}
                  image={`Game_Type_${gameImg}2x.png`}
                  paramToSet='game-name'
                  classNamePre='unitsubmenu'
                  onmouseover={
                    () => setSingleGameData(findSingleGameData(game, reduxState, locationParam, dataset))
                  }
                />
              );
            })}
          </div>
          {singleGameData.length > 0 ? (
            <div className='subunitmenu_lowercontent'>
              <div className='subunitmenu_lowercontentcontainer'>
                <div className='subunitmenu_innerlowercontentcontainer'>
                {singleGameData.map((datum) => {
                  let img = `url(${fetchImage(datum.App_Art, dataset)})`;
                  let audioFile: HTMLAudioElement = new Audio();
                  if (datum.App_Audio.length > 0) {
                    try {
                      new Audio(fetchAudio(datum.App_Audio, dataset));
                    } catch (e) {
                      console.log('Danger Will Robinson:', datum.Key, datum.English)
                    }
                    audioFile = new Audio(fetchAudio(datum.App_Audio, dataset));
                    // console.log(datum.English, fetchAudio(datum.App_Audio));
                  }
                  let vocabFontSize = 15;
                  let vMin = reduxState.width > reduxState.height
                    ? reduxState.height
                      : reduxState.width;
                  if (vMin < 410) {
                    vocabFontSize = 12;
                    if (datum.Language.length > 30) {
                      vocabFontSize = 7;
                    } else if (datum.Language.length > 20) {
                      vocabFontSize = 10;
                    }
                  } else if (vMin < 768) {
                    vocabFontSize = 13;
                    if (datum.Language.length > 20) {
                      vocabFontSize = 7.5;
                    }
                  } else {
                    if (datum.Language.length > 20) {
                      vocabFontSize = 11.5;
                    }
                  }
                  return (
                    <div
                      key={`${datum.Key}${datum.Language}`}
                      className='subunitmenu_lowercontainertile'
                      // style={{
                      //   backgroundColor:
                      //     datum.App_Audio.length === 0
                      //       ? 'black'
                      //       : 'rgba(0,0,0,0)',
                      // }}
                      onClick={() => audioFile.play()}>
                      <div
                        className='subunitmenu_tileimg'
                        style={{backgroundImage: img}}
                      />
                      <div className='subunitmenu_tileheader'>
                        <div
                          className='subunitmenu_tileheadertext'
                          style={{fontSize: vocabFontSize}}>
                          {datum.Language}
                        </div>
                      </div>
                    </div>
                  );
                })}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SubUnitMenu;
