import React, { useEffect } from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Welcome from '../Welcome';
import LocationMenu from '../LocationMenu';
import UnitMenu from '../UnitMenu';
import RedirectPage from '../Redirect';
import GameTypeMenu from '../GameTypeMenu';
import SubUnitMenu from '../UnitSubMenu';
import FullGameContainer from '../FullGameContainer';
import { getSearchParams } from '../nonUIFuncs';


interface DifficultyProps {
  difficulty: number;
  firstStars: number;
  secondStars: number;
  stars: number;
}
interface RouterProps {
  levelData: object[];
  updateLevelData(arg0: object): any;
  scoreData: Array<{
    category: string;
    gameType: string;
    gameName: string;
    target: string;
    difficultyScores: {
      easy: DifficultyProps;
      medium: DifficultyProps;
      hard: DifficultyProps;
    };
  }>;
  updateScoreData(arg0: object): any;
  setShowTopNav(arg0: boolean): any;
  dataset: string;
};

// TODO: redirect if a url parameter does not match the data

const AppRouter: React.FC<RouterProps> = ({
  levelData,
  updateLevelData,
  scoreData,
  updateScoreData,
  setShowTopNav,
  dataset,
}) => {
  const location = useLocation();
  const params = getSearchParams(location);
  useEffect(() => {
    let paramsToSend: any = {};
    params.forEach((d) => {
      paramsToSend[d.id] = d.value;
    })
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      params: JSON.stringify(paramsToSend),
    });
  }, [location.pathname]);
  let routesArr = useRoutes([
    {
      path: "/",
      element: <Welcome dataset={dataset} />,
    },
    {
      path: "/choose-location/",
      element: <LocationMenu />
    },
    {
      path: "/choose-unit/",
      element: <UnitMenu scoreData={scoreData} dataset={dataset} />
    },
    {
      path: "/choose-game-type/",
      element: <GameTypeMenu scoreData={scoreData} dataset={dataset} />
    },
    {
      path: "/choose-sub-unit/",
      element: <SubUnitMenu scoreData={scoreData} dataset={dataset} />
    },
    {
      path: "/start-game/",
      element: (
        <FullGameContainer
          setShowTopNav={setShowTopNav}
          updateLevelData={updateLevelData}
          updateScoreData={updateScoreData}
          scoreData={scoreData}
          dataset={dataset}
        />
      ),
    },
    {
      path: '*',
      element: <RedirectPage />,
    }
  ]);
  return routesArr;
};

export default AppRouter;
