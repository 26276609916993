import './index.scss';
import '../../App.scss';

import { useEffect, useRef, useState, SetStateAction, FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { selectReduxSlice, setStats } from '../../Store/store';
import { useLocation } from 'react-router-dom';
import {
  fetchAudio,
  fetchImage,
  shuffle, placementArray,
  getSearchParams,
  capEveryWord,
} from '../nonUIFuncs';
import styled from 'styled-components';

import PopupWord from '../UI/PopupWord';
import CloseEndless from '../UI/CloseEndless';
import EndEndlessGame from '../UI/EndEndlessGame';
import FloatingNumber from '../UI/FloatingNumber';
import RoundBanner from '../UI/RoundBanner';

let difficultyVar = 'easy';
let wordArray: Array<any> = [];
let displayArray: Array<{x:number, y:number}> = [];
let displayArrayDenom = [3,3];
let shortTimer = 0;
let imageDOMArray: Array<any> = [];
let wordDOMArray: Array<any> = [];
let correctSoundEffect: null|HTMLAudioElement = null;
let difficultyData: Array<any> = [];
let switchDisplayVar = 'stats';
let ready = false;
let timeout = false;
let testNum = 0;
let imageIndex: Array<number|string> = [];
let wordIndex: Array<number|string> = [];
let showPopup = false;
let audioFile: any = null;
let fullTime = 0;
let audioDOM:any = [];
let selectedColor = 'rgba(150, 200, 255, 1)';
let normalColor = 'rgba(202, 176, 133, 1)';
let round = 0;

interface WordToImageProps {
  checkWorld: Function;
  updateLevelData: Function;
  children: any;
  setProgress: Function;
  setScreenCover: Function;
  findStars: Function;
  starsObj: {starOne: boolean; starTwo: boolean; starThree: boolean};
  dataset: string;
}

const WordToImage: FC<WordToImageProps> = ({
  checkWorld,
  updateLevelData,
  children,
  setProgress,
  setScreenCover,
  findStars,
  starsObj,
  dataset,
}) => {
  const dispatch = useAppDispatch();
  const reduxState = useAppSelector(selectReduxSlice);
  const routeLocation = useLocation();
  const Params = getSearchParams(routeLocation);
  const firstSelectedRef = useRef<SetStateAction<number|string|null>>(null);
  const firstIDRef = useRef('');
  const currentLevelRef = useRef(0);
  const currentLevelProgressRef = useRef(0);
  const mouseClickXRef = useRef(0);
  const mouseClickYRef = useRef(0);
  const reduxStateRef = useRef(reduxState);
  const firstTargetRef = useRef<any>(null);

  const [ correctness, setCorrectness ] = useState<string|null>(null);
  const [ firstID, setFirstID ] = useState('');
  const [ firstSelected, setFirstSelected ] = useState<SetStateAction<number|string|null>>(null);
  const [ chosenPopupWord, setChosenPopupWord ] = useState('');
  const [ currentLevel, setCurrentLevel ] = useState(0);
  const [ currentLevelProgress, setCurrentLevelProgress ] = useState(0);
  const [ showEndlessEnd, setShowEndlessEnd ] = useState(false);
  const [ mouseClickX, setMouseClickX ] = useState(0);
  const [ mouseClickY, setMouseClickY ] = useState(0);
  const [ firstTarget, setFirstTarget ] = useState<any>(null);

  firstSelectedRef.current = firstSelected;
  firstIDRef.current = firstID;
  currentLevelProgressRef.current = currentLevelProgress;
  currentLevelRef.current = currentLevel;
  reduxStateRef.current = reduxState;
  mouseClickXRef.current = mouseClickX;
  mouseClickYRef.current = mouseClickY;
  firstTargetRef.current = firstTarget;
  useEffect(() => {
    let string = `${Params.find((d) => d.id === "unit").value} - ${Params.find((d) => d.id === "game-name").value} - ${reduxStateRef.current.difficulty}`;
    document.title = capEveryWord(string);
    let timer = setInterval(updateDisplayedClock, 1000);
    setCurrentLevel(0);
    difficultyVar = reduxState.difficulty;
    fullTime = 0;
    switchDisplayVar = 'stats';
    createAudio();
    changeDisplay();
    correctSoundEffect = new Audio(`${fetchAudio('correctSound.mp3')}`);
    ready = true;
    return () => {
      if (audioFile !== null) {
        audioFile.pause();
        audioFile.currentTime = 0;
        audioFile = null;
      }
      fullTime = 0;
      switchDisplayVar = 'stats';
      difficultyVar = 'easy';
      round = 0;
      clearInterval(timer);
      imageDOMArray = [];
      wordDOMArray = [];
      resetStats();
      setCorrectness(null);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateDisplayedClock = () => {
    shortTimer = shortTimer + 1;
  };
  const _selectCard = (
    e: any,
    key: number|string,
    audio: any,
    id: number|string,
  ) => {
    e.target.style.backgroundColor = selectedColor;
    if (
      key === firstSelectedRef.current &&
      e.target.id === firstIDRef.current
    ) {
      setFirstSelected(null);
      setFirstID('');
      setFirstTarget(null);
      e.target.style.backgroundColor = normalColor;
      return;
    } else if (firstIDRef.current.length > 0 && firstIDRef.current.includes('word') === e.target.id.includes('word')) {
      e.target.style.backgroundColor = selectedColor;
      firstTargetRef.current.style.backgroundColor = normalColor;
      setFirstSelected(key);
      setFirstID(e.target.id);
      setFirstTarget(e.target);
      return;
    } else if (firstSelectedRef.current === null) {
      setFirstSelected(key);
      setFirstID(e.target.id);
      setFirstTarget(e.target);
    } else if (
      firstSelectedRef.current !== null &&
      firstIDRef.current !== e.target.id
    ) {
      let secondTarget = key;
      setTimeout(checkCorrectness, 1000, e, e.target.id, secondTarget, audio);
    }
    setMouseClickX(e.pageX);
    setMouseClickY(e.pageY);
    if (isNaN(e.target.id)) {
      if (audioFile !== null) {
        audioFile.pause();
        audioFile.currentTime = 0;
        audioFile = null;
      }
      audioFile = document.getElementById(audio);
      if (audioFile !== null) {
        audioFile.play();
      }
    }
    if (firstIDRef.current.length > 0 && firstIDRef.current !== e.target.id) {
      timeout = true;
    }
  };
  const resetStats = () => {
    shortTimer = 0;
    timeout = false;
    switchDisplayVar = 'game';
  };
  const changeDisplay = () => {
    imageIndex = [];
    wordIndex = [];
    setScreenCover(false);
    let shuffled = shuffle(reduxState.sortedData);
    if (difficultyVar === 'easy') {
      difficultyData = shuffled.slice(0,3);
      wordArray = placementArray(1, 1, 2, 4, 1, 1);
      displayArray = placementArray(3.3, 1, 4, 4, 1, 1);
      if (reduxStateRef.current.height < reduxStateRef.current.width) {
        if (reduxStateRef.current.height < 550) {
          wordArray = placementArray(0.9, 1.2, 1, 4, 1, 1);
          displayArray = [
            { x: 2.25, y: 1 },
            { x: 3.25, y: 1.9 },
            { x: 2.25, y: 2.8 },
          ];
        }
      }
      displayArrayDenom = [5, 5];
    } else if (difficultyVar === 'medium') {
      difficultyData = shuffled.slice(0, 6);
      wordArray = placementArray(1, 0.8, 2, 6, 1, 0.9);
      displayArray = placementArray(2.65, 1.15, 4.5, 6, 0.75, 1.9);
      if (reduxStateRef.current.height < 550) {
        let difference = (5.8 - 1.6) / 5;
        wordArray = placementArray(1, 1.6, 2, 6, 1, difference);
        displayArray = placementArray(2.3, 2, 5, 5, 0.5, 2.5);
      }
      displayArrayDenom = [5, 8];
    } else if (difficultyVar === 'hard') {
      difficultyData = shuffled.slice(0, 8);
      displayArray = placementArray(2.3, 1.5, 4.3, 8, 1, 2);
      if (reduxStateRef.current.height > reduxStateRef.current.width) {
        wordArray = placementArray(1, 1.2, 2, 9, 1, 1);
      } else { 
        wordArray = placementArray(1.1, 1.3, 2, 9, 1, 1);
        displayArray = placementArray(2.3, 1.7, 4.3, 8, 1, 2);
        if (reduxStateRef.current.height < 550) {
          wordArray = placementArray(0.75, 2.6, 2, 9, .8, 1.8);
          displayArray = placementArray(1.85, 2, 5, 7, 0.58, 4);
        }
      }
      displayArrayDenom = [5, 12];
    }
    wordArray = shuffle(wordArray);
    gameDifficultyDisplay();
  };
  const nextEndlessLevel = () => {
    setCurrentLevel(currentLevelRef.current + 1);
    setCurrentLevelProgress(0);
    let number = document.getElementById('levelNumber');
    number?.classList.add('levelNumber');
    setTimeout(() => number?.classList.remove('levelNumber'), 1200);
  };
  const checkCorrectness = (
    e: any,
    secondKey: number|string,
    secondTarget: number|string,
    audio: any,
  ) => {
    if (
      firstSelectedRef.current === secondTarget
        && firstIDRef.current !== secondKey
    ) {
      let progressBarVal = Math.round(
        (imageIndex.length + 1) /
        (imageDOMArray.length + imageIndex.length) * 100
      );
      setProgress(`${progressBarVal}%`);
      let popupWords = reduxState.popupWords.filter(
        (datum) => datum.type === 'correct',
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          hits: reduxStateRef.current.stats.hits + 1,
          score: reduxStateRef.current.stats.score + 1,
          roundStats: {
            hits: reduxStateRef.current.stats.roundStats.hits + 1,
            score: reduxStateRef.current.stats.roundStats.score + 1,
          },
        })
      );
      if (audioFile !== null) {
        audioFile.pause();
        audioFile.currentTime = 0;
        audioFile = null;
      }
      audioFile = document.getElementById(audio);
      if (audioFile !== null) {
        audioFile.play();
      }
      correctSoundEffect?.play();
      // TODO: When Language column fills in, change popupwords from English
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness('correct');
      let progress = currentLevelProgressRef.current + 1;
      setCurrentLevelProgress(progress);
      let isEndless = routeLocation.state?.endless || false;
      if ( currentLevelRef.current + 2 === progress && isEndless === true) {
        setTimeout(nextEndlessLevel, 700);
      }
      wordDOMArray = wordDOMArray.filter((d) => {
        if (d.props.id === firstIDRef.current || d.props.id === secondKey) {
          wordIndex.push(d.key)
        }
        return d.props.id !== firstIDRef.current && d.props.id !== secondKey;
      });
      imageDOMArray = imageDOMArray.filter((d) => {
        if (
          d.props.id == firstIDRef.current ||
          d.props.id == secondKey
        ) {
          imageIndex.push(d.key);
        }
        return (
          d.props.id != firstIDRef.current &&
          d.props.id != secondKey
        );
      });
      difficultyData = difficultyData.filter(
        (datum) => datum.English !== firstSelectedRef.current
      );
      setScreenCover(true);
      setTimeout(() => setScreenCover(false), 1000);
    } else if (firstSelectedRef.current !== secondTarget) {
      let popupWords = reduxStateRef.current.popupWords.filter(
        (datum) => datum.type === 'incorrect',
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          misses: reduxStateRef.current.stats.misses + 1,
          score: reduxStateRef.current.stats.score - 1,
          roundStats: {
            misses: reduxStateRef.current.stats.roundStats.misses + 1,
            score: reduxStateRef.current.stats.roundStats.score - 1,
          },
        })
      );
      // TODO: When Language column fills in, change popupwords from English
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness('wrong');
      if (currentLevelProgressRef.current > 0) {
        setCurrentLevelProgress(currentLevelProgressRef.current - 1);
      }
      let firstObject = document.getElementById(`${firstIDRef.current}`);
      if (firstObject !== undefined && firstObject !== null) {
        firstObject.style.backgroundColor = normalColor;
      }
      let secondObject = document.getElementById(`${secondKey}`);
      if (secondObject !== undefined && secondObject !== null) {
        secondObject.style.backgroundColor = normalColor;
      }
    }
    setFirstID('');
    setFirstSelected(null);
    setFirstTarget(null);
    timeout = false;
    setTimeout(() => setCorrectness(null), 1000);
  };
  const stepDifficulty = () => {
    setProgress('0%');
    ready = false;
    switchDisplayVar = 'game';
    if (round < 3) {
      round = round + 1;
      changeDisplay();
    } else {
      checkWorld(fullTime);
    }
  };
  const gameDifficultyDisplay = () => {
    let key = 0;
    imageDOMArray = [];
    wordDOMArray = [];
    let viewMin = reduxStateRef.current.width > reduxStateRef.current.height
      ? reduxStateRef.current.height
      : reduxStateRef.current.width;
    let imgDim = difficultyVar === 'hard' ? viewMin / 7.5 : viewMin / 7.1;
    if (imgDim < 50) {
      imgDim = 50;
    }
    
    difficultyData.forEach((datum) => {
      let xyCoord = displayArray[key];
      let xyCoordWord = wordArray[key];
      let wordKey = key + 'word';
      wordIndex.forEach((word) => {
        while (word === wordKey) {
          key = key + 1;
          xyCoord = displayArray[key];
          xyCoordWord = wordArray[key];
          wordKey = key + 'word';
        }
      });
      key = key + 1;
      if ( xyCoord !== undefined) {
        let wordStyleHeight = reduxStateRef.current.height / (displayArray.length + 8);
        let wordStyle = {
          backgroundColor: normalColor,
          top: (reduxStateRef.current.height * xyCoordWord.y) / displayArrayDenom[1] - 40,
          left: (reduxStateRef.current.width * (xyCoordWord.x - 0.75)) / displayArrayDenom[0],
          width: reduxStateRef.current.width * 0.3,
          height: wordStyleHeight,
          maxHeight: 95,
        };
        let divStyle = {
          backgroundColor: normalColor,
          top:
            (reduxStateRef.current.height * xyCoord.y) / displayArrayDenom[1] -
            imgDim / 2,
          left:
            (reduxStateRef.current.width * xyCoord.x) / displayArrayDenom[0] -
            imgDim / 2,
          backgroundImage: `url(${fetchImage(datum.App_Art, dataset)})`,
        };
        if (difficultyVar === 'hard' && reduxStateRef.current.height < 550) {
          wordStyle.width = reduxStateRef.current.width / 7;
        }
        wordDOMArray.push(
          <div
            key={wordKey}
            id={wordKey}
            style={wordStyle}
            onClick={(e) => _selectCard(e, datum.English, datum.App_Audio, wordKey)}
            className='keyword noselect wti_wordclick'>
            {datum.Language}
          </div>
        );
        imageDOMArray.push(
          <div
            onClick={(e) => _selectCard(e, datum.English, datum.App_Audio, key)}
            key={key}
            id={`${key}`}
            style={divStyle}
            className={difficultyVar === 'hard' ? 'wti_imageclick_hard' : 'wti_imageclick'}
          />
        );
        ready = true;
      }
    });
  };
  const createAudio = () => {
    audioDOM = reduxState.sortedData.map((datum: any) => {
      if (datum.App_Audio.length === 0) {
        return null;
      }
      return (
        <audio
          id={datum.App_Audio}
          key={`${datum.App_Audio}${datum.Key}`}
          src={`${fetchAudio(datum.App_Audio, dataset)}`}
          // type='audio/mpeg'
        />
      )
    })
  };
  if (switchDisplayVar === 'stats') {
    if (routeLocation?.state?.endless ? routeLocation.state.endless : false) {
      ready = false;
      switchDisplayVar = 'game';
      changeDisplay();
      return null;
    }
    if (shortTimer > 1) {
      stepDifficulty();
    }
    if (audioFile !== null) {
      audioFile.pause();
      audioFile.currentTime = 0;
      audioFile = null;
    }
  } else {
    if (testNum !== reduxState.width * reduxState.height) {
      testNum = reduxState.width * reduxState.height;
      gameDifficultyDisplay();
    }
    if (imageDOMArray.length === 0 && ready === true) {
      setTimeout(() => {
        shortTimer = 0;
        switchDisplayVar = 'stats';
      }, 2000);
    }
  }
  const FloatingNumberContainer = styled.div`
    position: absolute;
    z-index: 999999;
    top: calc(${mouseClickYRef.current}px - 18vh);
    left: ${mouseClickXRef.current}px;
    height: 2px;
    width: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `;
  return (
    <div className='wti_fullscreen'>
      {children}
      {switchDisplayVar === 'stats' ? (
        <RoundBanner
          round={round + 1}
          findStars={findStars}
          starsObj={starsObj}
        />
      ) : (
        <div className='centerContentContainer'>
          {audioDOM}
          <FloatingNumberContainer>
            <FloatingNumber correctness={correctness} />
          </FloatingNumberContainer>
          <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
          {imageDOMArray}
          {wordDOMArray}
          <CloseEndless
            enabled={
              routeLocation?.state?.endless ? routeLocation.state.endless
                : false
            }
            onClick={setShowEndlessEnd}
          />
          <EndEndlessGame
            enabled={showEndlessEnd}
            currentLevel={currentLevel}
            setShowEndlessEnd={setShowEndlessEnd}
            updateLevelData={updateLevelData}
            endGame={undefined}
          />
        </div>
      )}
    </div>
  );
};

export default WordToImage;
