import './App.scss';
import { FC, useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import AppRouter from './Components/AppRouter';
import TopNavigation from './Components/UI/TopNavigation';
import BottomNavigation from './Components/UI/BottomNavigation';
import ClearDataPopup from './Components/UI/ClearDataPopup';
// import { fetchAudio, fetchImage } from './Components/nonUIFuncs';

import { useAppDispatch, useAppSelector } from './Store/hooks';
import { setWidth, setHeight, selectReduxSlice } from './Store/store';


let scoreDataLocal = [];
let levelDataLocal = [];

interface AppProps {
  scoreDataProp: [],
  levelDataProp: [],
  saveData(arg0: object[], arg1: object[]): any,
}

ReactGA.initialize("G-76YN2G99P4");
const App: FC<AppProps> = ({scoreDataProp, levelDataProp, saveData}) => {
  let location = useLocation();
  const scoreDataRef = useRef<any>([]);
  const datasetRef = useRef('');
  const dispatch = useAppDispatch();
  const reduxState = useAppSelector(selectReduxSlice);
  const [scoreData, setScoreData] = useState<object[]>([]);
  const [levelData, setLevelData] = useState<object[]>([]);
  const [showTopNav, setShowTopNav] = useState<boolean>(true);
  const [ showClearData, setShowClearData ] = useState(false);
  const [fullStarScores, setFullStarScores] = useState<Array<any>>([
    {difficulty: 1, currentScore: 0, totalScore: 0},
    {difficulty: 2, currentScore: 0, totalScore: 0},
    {difficulty: 3, currentScore: 0, totalScore: 0},
    {difficulty: 4, currentScore: 0, totalScore: 0},
    {difficulty: 5, currentScore: 0, totalScore: 0},
  ]);
  const [dataset, setDataset] = useState<string>('');

  const [subCat, setSubCat] = useState('');

  scoreDataRef.current = scoreData;
  datasetRef.current = dataset;
  // const [dataArray, setDataArray] = useState<Array<any>>([]); // Only being used for data error checking in development
  // const reduxState = useAppSelector(selectReduxSlice);
  // const location = useLocation();
  // const navigate = useNavigate();
  // dataRef.current = dataArray;
  useEffect(() => {
    const handleResize = () => {
      dispatch(setWidth(window.innerWidth));
      dispatch(setHeight(window.innerHeight));
    };
    window.addEventListener("resize", handleResize);
    if (localStorage?.ScoreData?.length <= 2) {
      let scoreDataToSet:object[] = filterScoreData(scoreDataProp);
      setScoreData(scoreDataToSet);
      scoreDataDisplayUpdate(scoreDataToSet);
    }
    if (localStorage?.LevelData?.length <= 2) {
      setLevelData(levelDataProp)
    }
    // findSetIDs(); // comment/uncomment when error checking data
    return () => {
      window.removeEventListener("resize", handleResize)
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let params = location.search.replace('?', '').split('&');
    let paramArray = params.map((p) => {
      let splitP = p.split('=');
      return { state: splitP[0], value: splitP[1] };
    });
    let locationParam = paramArray.find((p) => p.state === 'location')?.value;
    if (locationParam === 'Minto') {
      setDataset('b_');
    } else {
      setDataset('');
    }
  }, [location]);
  // const findSetIDs = () => {
  //   let data = [...reduxState.gameData];
  //   data = data.sort((a, b) => compare(a.SetID, b.SetID));
  //   let uniqueSetIDs: Array<string> = [];
  //   let uniqueDialect: Array<string> = [];

  //   data.forEach((d) => {
  //     if (!uniqueSetIDs.includes(d.SetID)) {
  //       uniqueSetIDs.push(d.SetID);
  //     }
  //     if (!uniqueDialect.includes(d.Dialect)) {
  //       uniqueDialect.push(d.Dialect);
  //     }
  //   });
  //   // console.log(uniqueDialect);
  //   uniqueSetIDs.forEach((str:string) => {
  //     let arr:Array<{}> = [];
  //     data.forEach((d) => {
  //       if (str === d.SetID) {
  //         arr.push(d);
  //       }
  //     });
  //     console.log('___________________________', arr.length);
  //     arr.forEach((d:any) => {
  //       console.log(d.English, d.Dialect)
  //     });
  //     setDataArray([...dataRef.current, arr])
  //   });
  // };
  const filterScoreData = (data:[]) => {
    let keys: number[] = [];
    let filtered: any[] = [];
    interface dProps {
      Key: number,
    };
    data.forEach((d:dProps) => {
      if (!keys.includes(d.Key)) {
        keys.push(d.Key);
        filtered.push(d);
      }
    });
    return filtered;
  };
  const updateScoreData = (
    newData:{
      difficulty: keyof Object,
      Key: number,
      score: number,
      time: number,
      correct: number,
      incorrect: number,
      firstStars: number,
      secondStars: number,
      stars: number,
      firstNextGoals: number,
      secondNextGoals: number,
    }
  ) => {
    console.log('newData', newData);
    scoreDataLocal = scoreData;
    let newScoreData = scoreDataLocal.map((game:any) => {
    // TODO: migrate away from using "any" as it is not specific
    // let newScoreData = scoreDataLocal.map((game:object) => {
      if (newData.Key === game.Key) {
        let gameScore = game.difficultyScores[newData.difficulty];
        if (
          (newData.firstStars + newData.secondStars)
            >= (gameScore.firstStars + gameScore.secondStars)
          && newData.score >= gameScore.score
        ) {
          return {
            ...game,
            difficultyScores: {
              ...game.difficultyScores,
              [newData.difficulty]: {
                ...game.difficultyScores[newData.difficulty],
                correct: newData.correct,
                firstNextGoals: newData.firstNextGoals,
                firstStars: newData.firstStars,
                incorrect: newData.incorrect,
                score: newData.score,
                secondNextGoals: newData.secondNextGoals,
                secondStars: newData.secondStars,
                stars: newData.stars,
                time: newData.time,
              },
            },
          }
        } else {
          return game;
        }
      } else {
        return game;
      }
    });
    setScoreData(newScoreData);
    scoreDataDisplayUpdate(newScoreData);
  };
  const updateLevelData = (
    data:{
      category:string,
      spreadGameType: string,
      gameType: string,
      difficulty: string,
      newLevel: string,
    }
  ) => {
    levelDataLocal = levelData;
    let categoryLength = data.category.length;
    // TODO: migrate away from using "any" as it is not specific
    let newLevelData = levelDataLocal.map((game:any) => {
      let gameCategory = game.category;
      if (typeof gameCategory !== 'string') {
        gameCategory = game.category[0];
      }
      if (
        (game.gameType === `${categoryLength} Categories` &&
          data.spreadGameType === game.spreadGameType) ||
        (data.gameType === game.gameType && data.category[0] === gameCategory)
      ) {
        return {
          ...game,
          category: data.category,
          levelEasy:
            data.difficulty === "easy" && data.newLevel > game.levelEasy
              ? data.newLevel
              : game.levelEasy,
          categoryEasy:
            data.difficulty === "easy" && data.newLevel > game.levelEasy
              ? data.category
              : game.categoryEasy,
          levelMedium:
            data.difficulty === "medium" && data.newLevel > game.levelMedium
              ? data.newLevel
              : game.levelMedium,
          categoryMedium:
            data.difficulty === "medium" && data.newLevel > game.levelMedium
              ? data.category
              : game.categoryMedium,
          levelHard:
            data.difficulty === "hard" && data.newLevel > game.levelHard
              ? data.newLevel
              : game.levelHard,
          categoryHard:
            data.difficulty === "hard" && data.newLevel > game.levelHard
              ? data.category
              : game.categoryHard,
        };
      } else {
        return game;
      }
    });
    setLevelData(newLevelData);
  };
  const clearStorage = () => {
    localStorage.clear();
    window.location.reload();
  };
  const scoreDataDisplayUpdate:any = (currentScoreData:any) => {
    let settingFullStarScores = [
      {difficulty: 1, currentScore: 0, totalScore: 0},
      {difficulty: 2, currentScore: 0, totalScore: 0},
      {difficulty: 3, currentScore: 0, totalScore: 0},
      {difficulty: 4, currentScore: 0, totalScore: 0},
      {difficulty: 5, currentScore: 0, totalScore: 0},
    ];
    currentScoreData.forEach((d: {
      difficultyScores: {
        easy: {difficulty: number, stars: number},
        medium: {difficulty: number, stars: number},
        hard: {difficulty: number, stars: number}
      }
    }) => {
        let easyDiffNum = d.difficultyScores.easy.difficulty;
        settingFullStarScores[easyDiffNum - 1].totalScore = settingFullStarScores[easyDiffNum - 1].totalScore + 3;
        settingFullStarScores[easyDiffNum - 1].currentScore = settingFullStarScores[easyDiffNum - 1].currentScore + d.difficultyScores.easy.stars;
        let medDiffNum = d.difficultyScores.medium.difficulty;
        settingFullStarScores[medDiffNum - 1].totalScore = settingFullStarScores[medDiffNum - 1].totalScore + 3;
        settingFullStarScores[medDiffNum - 1].currentScore = settingFullStarScores[medDiffNum - 1].currentScore + d.difficultyScores.medium.stars;
        let hardDiffNum = d.difficultyScores.hard.difficulty;
        settingFullStarScores[hardDiffNum - 1].totalScore = settingFullStarScores[hardDiffNum - 1].totalScore + 3;
        settingFullStarScores[hardDiffNum - 1].currentScore = settingFullStarScores[hardDiffNum - 1].currentScore + d.difficultyScores.hard.stars;
    });
    setFullStarScores(settingFullStarScores);
  };
  window.onbeforeunload = saveData(scoreData, levelData);
  // console.log(reduxState.b_gameData);
  let arr: any = [];
  reduxState.b_gameData.forEach((d) => {
    if (!arr.includes(d.subCat)) {
      arr.push(d.subCat);
    }
  });
  return (
      <div className='center'>
        <div className='centerContentContainer'>
          <AppRouter
            levelData={levelData}
            updateLevelData={updateLevelData}
            scoreData={scoreDataRef.current}
            updateScoreData={updateScoreData}
            setShowTopNav={setShowTopNav}
            dataset={datasetRef.current}
          />
        </div>
        <TopNavigation
          setShowClearData={setShowClearData}
          showClearData={showClearData}
          showNav={showTopNav}
        />
        <BottomNavigation fullStarScores={fullStarScores}  />
        {showClearData ? (
          <ClearDataPopup clearData={clearStorage} setShowClearData={setShowClearData} />
        ) : null}
      </div>
  );
};

export default App;


          // <div>
          //   {arr.map((str: string) => {
          //     let data = reduxState.b_gameData.filter(d => d.subCat === str);
          //     if (subCat === str) {
          //       return (
          //         <div
          //           onClick={() => setSubCat(str)}
          //           key={str}
          //           style={{backgroundColor: 'black', margin: 5, color: 'white'}}>
          //           {str}
          //           {data.map((d) => {
          //             let newAudio: any;
          //             try {
          //               newAudio = new Audio(fetchAudio(d.App_Audio, 'b_'));
          //             } catch (e) {
          //               console.log('error', d.Key, d.English);
          //             }
          //             if (d.App_Audio.length === 0) {
          //               return (
          //                 <div key={d.Key} style={{backgroundColor: 'red', margin: 5}}>
          //                   {d.Key}. {d.English}
          //                   <div style={{backgroundColor: 'blue', padding: 8}}>
          //                     No Audio Listed
          //                   </div>
          //                 </div>
          //               );
          //             } else {
          //               try {
          //                 return (
          //                   <div key={d.Key} style={{backgroundColor: 'red', margin: 5}}>
          //                     {d.Key}. {d.English}
          //                     <div style={{backgroundColor: 'green', padding: 8}} onClick={() => {newAudio.play()}}>
          //                       Play
          //                     </div>
          //                   </div>
          //                 );
          //               } catch (e) {
          //                 console.log('error', d.Key, d.English);
          //               }
          //             }
          //           })}
          //         </div>
          //       );
          //     } else {
          //       return (
          //         <div key={str} onClick={() => setSubCat(str)}>{str}</div>
          //       );
          //     }

          //   })}
          //   {/* {reduxState.b_gameData.map((d) => {
          //     // console.log(d.English, '--', d.App_Art)
          //     if (d.App_Audio.length === 0) {
          //       console.log(d.Key, '--', d.English);
          //     }
          //     let newAudio = new Audio(fetchAudio(d.App_Audio, 'b_'));
          //     return (
          //       <div key={d.Key} style={{backgroundColor: 'red', margin: 5}}>
          //         <div>{d.Key} --- {d.English} --- {d.Language}</div>
          //         <div
          //           style={{
          //             backgroundColor: 'green',
          //             padding: 10,
          //             margin: 5,
          //             width: 100,
          //             height: 20,
          //           }}
          //           onClick={() => newAudio.play()}>
          //           Play Audio
          //         </div>
          //       </div>
          //     );
          //   })} */}
          // </div>