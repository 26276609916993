import './index.scss';
import '../../App.scss';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../Store/hooks';
import { selectReduxSlice } from '../../Store/store';
import { redirectFromParams, fetchImage, capEveryWord } from '../nonUIFuncs';

interface LocationMenuProps {};

const LocationMenu: FC<LocationMenuProps> = () => {
  const navigate = useNavigate();
  const reduxState = useAppSelector(selectReduxSlice);
  console.log(reduxState);
  useEffect(() => {
    let string = "Denaakk'e Kids Zone - Locations";
    document.title = capEveryWord(string);
    let redirectPath:string = redirectFromParams(['difficulty']) || '';
    if (redirectPath.length > 0) {
      navigate(redirectPath);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let towns: Array<{name: string, position: {left: number, top: number, width: number, height: number}, style?: {}}> = [
    {
      name: 'Alatna',
      position: {
        left: 0.453,
        top: 0.233,
        width: 0.16,
        height: 0.07
      }
    },
    {
      name: 'Allakaket',
      position: {
        left: 0.628,
        top: 0.23,
        width: 0.22,
        height: 0.07
      }
    },
    {
      name: 'Galena',
      position: {
        left: 0.325,
        top: 0.528,
        width: 0.155,
        height: 0.09
      },
    },
    {
      name: 'Hughes',
      position: {
        left: 0.34,
        top: 0.32,
        width: 0.16,
        height: 0.071
      }
    },
    {
      name: 'Huslia',
      position: {
        left: 0.225,
        top: 0.397,
        width: 0.1555,
        height: 0.073
      }
    },
    {
      name: 'Kaltag',
      position: {
        left: 0.193,
        top: 0.722,
        width: 0.16,
        height: 0.096
      }
    },
    {
      name: 'Koyukuk',
      position: {
        left: 0.102,
        top: 0.535,
        width: 0.193,
        height: 0.067
      }
    },
    {
      name: 'Manley',
      position: {
        left: 0.645,
        top: 0.545,
        width: 0.134,
        height: 0.116
      }
    },
    {
      name: 'Minto',
      position: {
        left: 0.793,
        top: 0.547,
        width: 0.145,
        height: 0.089
      },
    },
    {
      name: 'Nulato',
      position: {
        left: 0.0685,
        top: 0.649,
        width: 0.162,
        height: 0.07
      }
    },
    {
      name: 'Rampart',
      position: {
        left: 0.6978,
        top: 0.453,
        width: 0.19,
        height: 0.083
      }
    },
    {
      name: 'Ruby',
      position: {
        left: 0.398,
        top: 0.619,
        width: 0.128,
        height: 0.093
      }
    },
    {
      name: 'Tanana',
      position: {
        left: 0.552,
        top: 0.426,
        width: 0.129,
        height: 0.115
      }
    },
  ];
  let townButtonStyle = {
    // backgroundColor: 'red',
    margin: 3,
  };
  const _townClick = (name: string) => {
    console.log(name);
    let windowParams = window.location.href.split('?')[1];
    navigate(`/choose-unit?${windowParams}&location=${name}`);
  };
  return (
    <div className='locationmenu_container all_pages_container'>
      {towns.map((town) => {
        let townStyle = {};
        if (town?.style !== undefined) {
          townStyle = town.style;
        }
        return (
          <div
            key={town.name}
            className='locationmenu_townbutton hovercursor'
            style={{
              ...townButtonStyle,
              backgroundImage: `url(${fetchImage(town.name.toLowerCase())}.png)`,
              width: reduxState.width * town.position.width,
              height: reduxState.height * town.position.height,
              left: reduxState.width * town.position.left,
              top: reduxState.height * town.position.top,
              ...townStyle
            }}
            onClick={() => _townClick(town.name)}
          />
        );
      })};
    </div>
  );
};

/* ------ Location menu WITH ANIMATE!!! DO NOT REMOVE!!! ------- */
// const LocationMenu: FC<LocationMenuProps> = () => {
//   const screenNameRef = useRef('');
//   const animationObjectRef = useRef<any>();
//   const navigate = useNavigate();
//   const reduxState = useAppSelector(selectReduxSlice);
//   const [ animationObject, setAnimationObject] =
//     useState<GetAnimationObjectParameter|null>(null);
//   const [screenName, setScreenName] = useState('');
//   let checkAnimateScreenInterval: ReturnType<typeof setTimeout>;
//   screenNameRef.current = screenName;
//   animationObjectRef.current = animationObject;
//   useEffect(() => {
//     let redirectPath:string = redirectFromParams(['difficulty']) || '';
//     if (redirectPath.length > 0) {
//       navigate(redirectPath);
//     }
//     return () => {
//       clearInterval(checkAnimateScreenInterval);
//       // window.location.reload();
//     };
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);
//   const getAnimationObject = (obj: any) => {
//     checkAnimateScreenInterval = setInterval(checkAnimateScreen, 500);
//     obj.sharedVariables = obj.sharedVariables || {screenName: ''};
//     setScreenName(obj.sharedVariables.screenName);
//     setAnimationObject(obj);
//   };

//   const checkAnimateScreen = () => {
//     if (animationObjectRef.current?.sharedVariables?.screenName === 'UI_Location') {
//       clearInterval(checkAnimateScreenInterval);
//       let townNames = reduxState.dialectStructure.map((d) => {return d.display});
//       townNames.forEach((str: string) => {
//         let buttonName = `${str}_Button`;
//         if (str === 'Allakaket' && animationObjectRef.current.children[0][buttonName] === undefined) {
//           animationObjectRef.current.children[0].Allakeket_Button.addEventListener('pressup', () => addThing(str));
//         // } else if (str === 'Minto') {
//         //   // Do stuff for Benhti data
//         } else {
//           animationObjectRef.current.children[0][buttonName].addEventListener('pressup', () => addThing(str));
//         }
//       });
//     } else if (animationObjectRef.current?.sharedFunctions?.ExternalNavTrigger) {
//       animationObjectRef.current?.sharedFunctions?.ExternalNavTrigger('UI_Location');
//     }
//   };
//   const addThing = (loc: string) => {
//     let windowParams = window.location.href.split('?')[1];
//     navigate(`/choose-unit?${windowParams}&location=${loc}`);
//   };
//   return (
//     <div className='welcome_container all_pages_container'>
//       <AnimateCanvas
//         getAnimationObject={(obj:any) => getAnimationObject(obj)}
//       />
//     </div>
//   );
// };

export default LocationMenu;
