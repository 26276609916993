import './index.scss';
import '../../../App.scss';
import { FC } from 'react';

interface PopupWordProps {
  popupWord: string;
  enabled: boolean;
};

const PopupWord: FC<PopupWordProps> = ({enabled, popupWord}) => {
  if (enabled) {
    return (
      <div className='popUpWord-Container popupWord-shakeBox'>
        <p className='popupWord-keyword'>{popupWord}</p>
      </div>
    );
  } else {
    return null;
  }

};

export default PopupWord;
