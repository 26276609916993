import './index.scss';
import { FC, useState, useEffect } from 'react';

interface CardFlipProps {
  cardData: {
    targetBodyWidth: number;
    targetBodyHeight: number;
    identifier: number;
    backImage: string;
    frontImage: string;
    height: number;
    width: number;
    App_Audio: string;
    Language: string;
    flipped: Function;
    selectCard: Function;
    rpY: number;
    rpX: number;
    positionArrayDenom: Array<number>;
  };
  flippedCurrent: any;
}

const CardFlip: FC<CardFlipProps> = ({cardData, flippedCurrent}) => {
  const [flipped, setFlipped] = useState(false);
  useEffect(() => {
    setFlipped(flippedCurrent[cardData.identifier] || false);
  }, [flippedCurrent]);
  let container = {
    height: cardData.targetBodyHeight,
    width: cardData.targetBodyWidth,
    top:
      (cardData.height * cardData.rpY) /
      cardData.positionArrayDenom[1] -
      cardData.targetBodyHeight / 2 +
      cardData.targetBodyHeight / 4,
    left: ((cardData.width * cardData.rpX) / cardData.positionArrayDenom[0]) - cardData.targetBodyWidth / 2,
  };
  let front = {
    height: cardData.targetBodyHeight,
    width: cardData.targetBodyWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: cardData.frontImage,
  };
  let back = {
    ...front,
    backgroundImage: cardData.backImage,
  };
  return (
    <div
      id={`${cardData.identifier}`}
      style={container}
      onClick={(e) => cardData.selectCard(
        e,
        cardData.identifier,
        cardData.backImage,
        cardData.App_Audio,
        cardData.Language,
      )}
      className={`cardflip_container ${(flipped) ? 'active' : null}`}>
      <div className='cardflip_flipper'>
        <div style={front} className='cardflip_front' />
        <div style={back} className='cardflip_back' />
      </div>
    </div>
  );
};

export default CardFlip;