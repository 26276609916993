import './index.scss';
import '../../App.scss';

import { useEffect, useRef, useState, FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { selectReduxSlice, setStats } from '../../Store/store';
import { useLocation } from 'react-router-dom';
import {
  fetchAudio,
  fetchImage,
  shuffle,
  getSearchParams,
  capEveryWord,
} from '../nonUIFuncs';
import styled from 'styled-components';

import PopupWord from '../UI/PopupWord';
import CloseEndless from '../UI/CloseEndless';
import EndEndlessGame from '../UI/EndEndlessGame';
import FloatingNumber from '../UI/FloatingNumber';
import RoundBanner from '../UI/RoundBanner';



let difficultyVar = 'easy';
let displayedClock = 0;
let currentTarget: any = undefined;
let shortTimer = 0;
let correctSoundEffect = new Audio(`${fetchAudio('correctSound.mp3')}`);
let bodyElement: any = null;
let chosenNumber: number|null = null;
let firstNumber: number|null = null;
let secondNumber: number|null = null;
let chosenRoundData: Array<any> = [];
let chosenNumberLanguage: string|null = null;
let firstNumberLanguage: string|null = null;
let secondNumberLanguage: string|null = null;
let addOrSubtract = 0;
let switchDisplayVar = 'stats';
let id = 0;
let showPopup = false;
let currentLevelVar = 0;
let audioFile: any = null;
let displayElements: any;
let runNewArray: any;
let round = 0;
let ready = false;
let fixed = false;

interface GameContainerProps {
  checkWorld: Function;
  updateLevelData: Function;
  children: any;
  setProgress: Function;
  findStars: Function;
  starsObj: {starOne: boolean; starTwo: boolean; starThree: boolean};
  dataset: string;
}

const BubblePop: FC<GameContainerProps> = ({
  checkWorld,
  updateLevelData,
  setProgress,
  children,
  findStars,
  starsObj,
  dataset,
}) => {
  const dispatch = useAppDispatch();
  const reduxState = useAppSelector(selectReduxSlice);
  const Location = useLocation();
  const Params = getSearchParams(Location);

  const DOMArrayRef = useRef<any>([]);
  const currentLevelRef = useRef(0);
  const currentLevelProgressRef = useRef(0);
  const cssArrayRef = useRef<any>([]);
  const reduxStateRef = useRef(reduxState);
  const mouseClickXRef = useRef(0);
  const mouseClickYRef = useRef(0);
  const chosenAudioRef = useRef('');

  const [ correctness, setCorrectness ] = useState<string|null>(null);
  const [ chosenPopupWord, setChosenPopupWord ] = useState('');
  const [ DOMArray, setDOMArray ] = useState<any>([]);
  const [ currentLevel, setCurrentLevel ] = useState(0);
  const [ currentLevelProgress, setCurrentLevelProgress ] = useState(0);
  const [ cssArray, setCssArray ] = useState<any>([]);
  const [ mouseClickX, setMouseClickX ] = useState(0);
  const [ mouseClickY, setMouseClickY ] = useState(0);
  const [ showEndlessEnd, setShowEndlessEnd ] = useState(false);
  const [ chosenAudio, setChosenAudio ] = useState('');

  cssArrayRef.current = cssArray;
  currentLevelProgressRef.current = currentLevelProgress;
  currentLevelRef.current = currentLevel;
  DOMArrayRef.current = DOMArray;
  reduxStateRef.current = reduxState;
  mouseClickXRef.current = mouseClickX;
  mouseClickYRef.current = mouseClickY;
  chosenAudioRef.current = chosenAudio;

  useEffect(() => {
    let string = `${Params.find((d) => d.id === "unit").value} - ${Params.find((d) => d.id === "game-name").value} - ${reduxStateRef.current.difficulty}`;
    document.title = capEveryWord(string);
    let timer = setInterval(() => {
      displayedClock = displayedClock + 1;
      shortTimer = shortTimer + 1;
    }, 1000);
    setCurrentLevel(0);
    currentLevelVar = 0;
    setCurrentLevelProgress(0);
    difficultyVar = reduxState.difficulty;
    switchDisplayVar = 'stats';
    bodyElement = document.getElementsByTagName('body')[0];
    bodyElement.style.overflow = 'hidden';
    ready = true;
    return () => {
      if (bodyElement !== null) {
        bodyElement.style.overflow = 'auto';
      }
      fixed = false;
      difficultyVar = 'easy';
      switchDisplayVar = 'stats';
      round = 0;
      clearInterval(timer);
      clearInterval(displayElements);
      clearTimeout(runNewArray);
      displayedClock = 0;
      shortTimer = 0;
    };
  }, []);
  const changeDisplay = () => {
    let previousTarget = currentTarget;
    let spliceNumber = 2;
    if (difficultyVar === 'easy') {
      spliceNumber = 2;
    } else if (difficultyVar === 'medium') {
      spliceNumber = 3;
    } else if (difficultyVar === 'hard') {
      spliceNumber = 5;
    }
    let mutatableData: any = [];
    reduxState.sortedData.forEach((d) => mutatableData.push(d));
    let sortedData = shuffle(mutatableData);
    chosenRoundData = [];
    if(reduxStateRef.current.specificGame.category === 'Math') {
      chosenNumber = minMaxScreen(2, reduxStateRef.current.sortedData.length);
      let fnRange = chosenNumber - 1;
      firstNumber = minMaxScreen(1, fnRange);
      secondNumber = chosenNumber - firstNumber;
      chosenNumberLanguage = reduxStateRef.current.sortedData[chosenNumber - 1].Language;
      firstNumberLanguage = reduxStateRef.current.sortedData[firstNumber - 1].Language;
      secondNumberLanguage = reduxStateRef.current.sortedData[secondNumber - 1].Language;
      addOrSubtract = Math.floor(Math.random() * 2);
      if (addOrSubtract === 0) {
        currentTarget =
          reduxStateRef.current.sortedData.find(
            (d) => Number(d.English) === secondNumber,
          );
      } else {
        currentTarget =
          reduxStateRef.current.sortedData.find(
            (d) => Number(d.English) === chosenNumber,
          );
      }
    } else {
      let randomChosenTargetNumber = Math.floor(
        Math.random() * reduxStateRef.current.sortedData.length
      );
      currentTarget = mutatableData[randomChosenTargetNumber];
      if (previousTarget !== undefined) {
        while (previousTarget?.English === currentTarget.English) {
          randomChosenTargetNumber = Math.floor(
            Math.random() * reduxStateRef.current.sortedData.length
          );
          currentTarget = mutatableData[randomChosenTargetNumber];
        }
      }
    }
    chosenRoundData.push(currentTarget);
    let possibleDistractors = sortedData.filter(
      (d) => d.English !== currentTarget.English,
    );
    let roundDistractors = possibleDistractors.splice(0, spliceNumber);
    roundDistractors.forEach((d) => chosenRoundData.push(d));
    newObjectArray();
  };
  const nextEndlessLevel = () => {
    setCurrentLevel(currentLevelRef.current + 1);
    currentLevelVar = currentLevelVar + 1;
    setCurrentLevelProgress(0);
    let number = document.getElementById('levelNumber');
    number?.classList.add('levelNumber');
    setTimeout(() => number?.classList.remove('levelNumber'), 1200);
  };
  const checkMath = (e: any) => {
    let eTarget = e.target.className.includes('contents')
      ? e.target
      : e.target.children[0];
    clearInterval(displayElements);
    clearInterval(runNewArray);
    let clickedObject = DOMArrayRef.current.find((DOMElement: any) => {
      return DOMElement.props.id === eTarget.id;
    });
    setMouseClickX(e.pageX);
    setMouseClickY(e.pageY);
    let clickedObjectImg;
    if (clickedObject.props.children.props.style !== undefined) {
      clickedObjectImg =
        clickedObject.props.children.props.style.backgroundImage;
    } else {
      clickedObjectImg =
        clickedObject.props.children.props.children.props.style.backgroundImage;
    }
    let targetImgPath = `url(${fetchImage(currentTarget.App_Art, dataset)})`;
    // let targetImgPath = `url(${fetchImage(currentTargetRef.current.App_Art)})`;
    if (targetImgPath === clickedObjectImg) {
      let popupWords = reduxStateRef.current.popupWords.filter((d) => d.type === 'correct');
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      correctSoundEffect.play();
      dispatch(
        setStats({
          hits: reduxStateRef.current.stats.hits + 1,
          score: reduxStateRef.current.stats.score + 1,
          roundStats: {
            hits: reduxStateRef.current.stats.roundStats.hits + 1,
            score: reduxStateRef.current.stats.roundStats.score + 1,
          },
        })
      );
      let progress = currentLevelProgressRef.current + 1;
      setCurrentLevelProgress(progress);
      if (currentLevelRef.current + 2 === progress && Location?.state?.endless) {
        setTimeout(nextEndlessLevel, 700);
      }
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness('correct');
    } else {
      let popupWords = reduxStateRef.current.popupWords.filter((d) => d.type === 'incorrect');
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          misses: reduxStateRef.current.stats.misses + 1,
          score: reduxStateRef.current.stats.score - 1,
          roundStats: {
            misses: reduxStateRef.current.stats.roundStats.misses + 1,
            score: reduxStateRef.current.stats.roundStats.score - 1,
          },
        })
      );
      if (currentLevelProgressRef.current > 0) {
        setCurrentLevelProgress(currentLevelProgressRef.current - 1);
      }
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness('wrong');
    }
    if (reduxStateRef.current.stats.roundStats.score + 1 <= 10) {
      changeDisplay();
    }
    setTimeout(() => setCorrectness(null), 1000);
  };
  const stepDifficulty = () => {
    setDOMArray([]);
    setCssArray([]);
    switchDisplayVar = 'game';
    displayedClock = 0;
    setProgress('0%');
    ready = false;
    if (round < 3) {
      round = round + 1;
      changeDisplay();
    } else {
      checkWorld();
    }
    dispatch(setStats({roundStats: {hits: 0, misses: 0, score: 0}}));
  };
  const newObjectArray = async () => {
    setCssArray([]);
    setDOMArray([]);
    
    shuffle(chosenRoundData);
    let newCssArray:any = [];
    for(let i = 0; i < chosenRoundData.length; i++) {
      id = id + 1;
      let currentData = chosenRoundData[i];
      let backgroundImage = currentData?.App_Art;
      let randomX = minMaxScreen(
        reduxStateRef.current.width * .12,
        reduxStateRef.current.width * .60
      );
      let randomR = Math.random() * 255;
      let randomG = Math.random() * 255;
      let randomB = Math.random() * 255;
      let containingDivStyle = {
        left: randomX,
        backgroundColor: `rgb(${randomR},${randomG},${randomB})`,
      };
      let divStyle = {
        backgroundColor: `rgb(${randomR},${randomG},${randomB})`,
        backgroundImage: `url(${fetchImage(backgroundImage, dataset)})`,
      };
      let uniqueStringContainer = `container${i}${difficultyVar}${randomX}`;
      newCssArray.push(
        <div
          onClick={(e) => checkMath(e)}
          id={`${i}`}
          style={containingDivStyle}
          key={uniqueStringContainer}
          className={
            `bubblepop_itemcontainer fallinganimation_${difficultyVar}`
          }>
            <div
              id={`${id}`}
              key={uniqueStringContainer}
              className='bubblepop_hitbox'>
                <div
                  id={`${i}`}
                  style={divStyle}
                  className='bubblepop_hitboxcontents'
                />
            </div>
        </div>
      );
    }
    ready = true;
    setCssArray(newCssArray);
    if (fixed) {
      playAudio(currentTarget.App_Audio);
    }
    displayElements = setInterval(() => {
      spawnObjects();
    }, 1000);
  };
  const spawnObjects = () => {
    clearInterval(runNewArray);
    if (!fixed) {
      clearInterval(displayElements);
      fixed = true;
      newObjectArray();
      return;
    }
    let randomizedList = shuffle(cssArrayRef.current);
    let newObject: any = undefined;
    if (randomizedList.length > 0) {
      newObject = randomizedList[0];
      setCssArray(randomizedList.filter(d => d.key !== newObject.key));
      setDOMArray([
        ...DOMArrayRef.current,
        newObject,
      ]);
    }
    if (newObject === undefined) {
      clearInterval(displayElements);
      if (difficultyVar === 'easy') {
        runNewArray = setTimeout(noAnswer, 13000);
      } else if (difficultyVar === 'medium') {
        runNewArray = setTimeout(noAnswer, 11500);
      } else {
        runNewArray = setTimeout(noAnswer, 10000);
      }
    }
  };
  const noAnswer = () => {
    setMouseClickX(reduxStateRef.current.width / 2);
    setMouseClickY(reduxStateRef.current.height / 2);
    dispatch(
      setStats({
        misses: reduxStateRef.current.stats.misses + 1,
        score: reduxStateRef.current.stats.score - 1,
        roundStats: {
          misses: reduxStateRef.current.stats.roundStats.misses + 1,
          score: reduxStateRef.current.stats.roundStats.score - 1,
        }
      })
    );
    setCorrectness('wrong');
    changeDisplay();
    setTimeout(() => setCorrectness(null), 1000);
  };
  const playAudio = (audioName: string) => {
    if (audioFile !== null) {
      audioFile.pause();
      audioFile.currentTime = 0;
      audioFile = null;
    }
    if (audioName !== chosenAudio) {
      audioFile = new Audio(fetchAudio(audioName, dataset));
      setChosenAudio(audioName);
      audioFile.play();
    }
  };
  const addSubtract = () => {
    return (
      <div
        className='bubblepop_keywordbox'
        onClick={() => playAudio(currentTarget.App_Audio)}>
        <div className='bubblepop_keyword noselect'>
          {
            addOrSubtract <= 0
              ? `${chosenNumberLanguage} - ${firstNumberLanguage}`
              : `${firstNumberLanguage} + ${secondNumberLanguage}`
          }
        </div>
      </div>
    );
  };
  const minMaxScreen = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  if (switchDisplayVar === 'stats') {
    if (Location?.state?.endless ? Location?.state?.endless : false) {
      ready = true;
      switchDisplayVar = 'game';
      changeDisplay();
      return null;
    }
    if (shortTimer > 1) {
      stepDifficulty();
    }
  } else {
    if (reduxStateRef.current.stats.roundStats.score >= 10 && ready === true) {
      clearTimeout(runNewArray);
      clearTimeout(displayElements);
      shortTimer = 0;
      switchDisplayVar = 'stats';
      playAudio('');
    }
  }
  const FloatingNumberContainer = styled.div`
    position: absolute;
    top: calc(${mouseClickYRef.current}px - 10vh);
    left: ${mouseClickXRef.current}px;
    z-index: 99999;
    height: 2px;
    width: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  return (
    <div className='bubblepop_outercontainer'>
      {children}
      {switchDisplayVar === 'stats' ? (
        <RoundBanner
          round={round + 1}
          findStars={findStars}
          starsObj={starsObj}
        />
      ) : (
        <div className='centerContentContainer'>
          <FloatingNumberContainer>
            <FloatingNumber correctness={correctness} />
          </FloatingNumberContainer>
          {reduxState.specificGame.category === 'Math' ? addSubtract() : (
            <div
              className='bubblepop_keywordbox'
              onClick={() => playAudio(currentTarget.App_Audio)}>
              <p 
                className='bubblepop_keyword noselect'
                style={{
                  fontSize:
                    currentTarget.Language.length > 20 
                      ? '2vmin'
                      : currentTarget.Language.length > 12 ? '3vmin' : '5vmin',
                }}>
                {currentTarget.Language}
              </p>
            </div>
          )}
          <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
          {DOMArrayRef.current}
          <CloseEndless
            enabled={
              Location?.state?.endless ? Location?.state?.endless
                : false
            }
            onClick={setShowEndlessEnd}
          />
          <EndEndlessGame
            enabled={showEndlessEnd}
            currentLevel={currentLevel}
            setShowEndlessEnd={setShowEndlessEnd}
            updateLevelData={updateLevelData}
            endGame={undefined}
          />
        </div>
      )}
    </div>
  );
};

export default BubblePop;
