import './index.scss';
import '../../App.scss';

import { useEffect, useRef, useState, FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { selectReduxSlice, setStats } from '../../Store/store';
import { useLocation } from 'react-router-dom';
import {
  fetchAudio,
  fetchImage,
  shuffle,
  placementArray,
  getSearchParams,
  capEveryWord,
} from '../nonUIFuncs';
import styled from 'styled-components';

import PopupWord from '../UI/PopupWord';
import CloseEndless from '../UI/CloseEndless';
import EndEndlessGame from '../UI/EndEndlessGame';
import FloatingNumber from '../UI/FloatingNumber';
import RoundBanner from '../UI/RoundBanner';

let displayArray: Array<any> = [];
let displayArrayDenom = [3,3];
let currentTarget: {
  App_Audio: string;
  English: string;
  App_Art: string;
} = {
  App_Audio: '',
  English: '',
  App_Art: '',
};
let imageArray: Array<any> = [];
let gameDOMArray: Array<any> = [];
let correctSoundEffect = new Audio(`${fetchAudio('correctSound.mp3')}`);
let clickedAudio: any = null;
const fullTime = 40;
let testNum = 1;
let positionIndex: Array<any> = [];
let showPopup = false;
let currentLevelVar = 0;
let playButton = `url(${fetchImage('playButton.png')})`;
let ready = false;
let shortTimer = 0;


interface GameContainerProps {
  checkWorld: Function;
  updateLevelData: Function;
  children: any;
  setProgress: Function;
  setScreenCover: Function;
  findStars: Function;
  starsObj: {starOne: boolean; starTwo: boolean; starThree: boolean};
  dataset: string;
}

const Wordless: FC<GameContainerProps> = ({
  checkWorld,
  updateLevelData,
  children,
  setProgress,
  setScreenCover,
  findStars,
  starsObj,
  dataset,
}) => {
  const dispatch = useAppDispatch();
  const Location = useLocation();
  const Params = getSearchParams(Location);
  const reduxState = useAppSelector(selectReduxSlice);

  const currentTimeRef = useRef(0);
  const difficultyRef = useRef('');
  const currentLevelRef = useRef(0);
  const currentLevelProgressRef = useRef(0);
  const reduxStateRef = useRef(reduxState);
  const mouseClickXRef = useRef(0);
  const mouseClickYRef = useRef(0);
  const roundRef = useRef(0);

  const [correctness, setCorrectness] = useState<string|null>(null);
  const [firstTime, setFirstTime] = useState(true);
  const [chosenPopupWord, setChosenPopupWord] = useState('');
  const [currentLevel, setCurrentLevel] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [display, setDisplay] = useState('stats');
  const [difficulty, setDifficulty] = useState('easy');
  const [currentLevelProgress, setCurrentLevelProgress] = useState(0);
  const [mouseClickX, setMouseClickX] = useState(0);
  const [mouseClickY, setMouseClickY] = useState(0);
  const [showEndlessEnd, setShowEndlessEnd] = useState(false);
  const [round, setRound] = useState(0);

  currentTimeRef.current = currentTime;
  difficultyRef.current = difficulty;
  currentLevelProgressRef.current = currentLevelProgress;
  currentLevelRef.current = currentLevel;
  reduxStateRef.current = reduxState;
  mouseClickXRef.current = mouseClickX;
  mouseClickYRef.current = mouseClickY;
  roundRef.current = round;

  useEffect(() => {
    let string = `${Params.find((d) => d.id === "unit").value} - ${Params.find((d) => d.id === "game-name").value} - ${reduxStateRef.current.difficulty}`;
    document.title = capEveryWord(string);
    let timer = setInterval(updateDisplayClock, 1000);
    setCurrentLevel(0);
    currentLevelVar = 0;
    setCurrentLevelProgress(0);
    setDisplay('stats');
    setDifficulty(reduxStateRef.current.difficulty);
    createAudio();
    ready = true;
    return () => {
      setDisplay('stats');
      setDifficulty('easy');
      clearInterval(timer);
      setCurrentTime(0);
      shortTimer = 0;
      setRound(0);
      gameDOMArray = [];
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (reduxStateRef.current.sortedData && firstTime === true) {
      changeDisplay();
      setFirstTime(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxStateRef.current.sortedData, firstTime]);
  const updateDisplayClock = () => {
    shortTimer = shortTimer + 1;
    if (Location?.state?.endless !== true) {
      let testVal = Math.floor(((currentTimeRef.current + 1)/fullTime)*100);
      setProgress(testVal >= 100 ? '100%' : `${testVal}%`);
      setCurrentTime(currentTimeRef.current + 1);
    }
  };
  const changeDisplay = () => {
    positionIndex = [];
    let previousTarget = currentTarget;
    setScreenCover(false);
    imageArray = [];
    let shuffled = shuffle(reduxStateRef.current.sortedData);
    currentTarget = shuffled[0];
    if (previousTarget !== undefined) {
      while (previousTarget.English === currentTarget.English) {
        shuffled = shuffle(reduxStateRef.current.sortedData);
        currentTarget = shuffled[0];
      }
    }
    playAudio(currentTarget.App_Audio);
    imageArray.push(currentTarget);
    let distractorAmount = 1;
    let fullDistractorArray = shuffled.filter(
      (d) => d.English !== currentTarget.English,
    );
    if (difficultyRef.current === 'easy') {
      displayArray = placementArray(1, 1.5, 3, 3.5, 1, 1);
      displayArrayDenom = [4, 4];
    } else if (difficultyRef.current === 'medium') {
      displayArray = placementArray(0.5, 1.5, 3.5, 3.5, 1, 1);
      displayArrayDenom = [4, 4];
    } else {
      displayArray = placementArray(1.3, 1.25, 4.3, 3.5, 1, 1);
      displayArrayDenom = [6, 5];
    }
    distractorAmount = displayArray.length - 1;
    let slicedDistractorArray = fullDistractorArray.slice(
      0,
      distractorAmount,
    );
    slicedDistractorArray.forEach((d) => imageArray.push(d));
    imageArray = shuffle(imageArray);
    gameDifficultyDisplay();
  };
  const nextEndlessLevel = () => {
    setCurrentLevel(currentLevelRef.current + 1);
    currentLevelVar = currentLevelVar + 1;
    setCurrentLevelProgress(0);
    let number = document.getElementById('levelNumber');
    number?.classList.add('levelNumber');
    setTimeout(() => number?.classList.remove('levelNumber'), 1200);
  };
  const checkCorrectness = (e: any) => {
    let clickedObject = gameDOMArray.find(
      (DOMElement) => DOMElement.props.id === e.target.id,
    );
    let audioFile = reduxStateRef.current.sortedData.find(
      (d) => d.Language === clickedObject.props.Language
    )?.App_Audio || '';
    setMouseClickX(e.pageX);
    setMouseClickY(e.pageY);
    playAudio(audioFile);
    let clickedObjectImg =
      clickedObject.props.children.props.style.backgroundImage;
    let targetImgPath = `url(${fetchImage(currentTarget.App_Art, dataset)})`;
    if (targetImgPath === clickedObjectImg) {
      let popupWords = reduxStateRef.current.popupWords.filter(
        (d) => d.type === 'correct',
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      setTimeout(changeDisplay, 2000);
      correctSoundEffect.play();
      dispatch(
        setStats({
          hits: reduxStateRef.current.stats.hits + 1,
          score: reduxStateRef.current.stats.score + 1,
          roundStats:{
            hits: reduxStateRef.current.stats.roundStats.hits + 1,
            score: reduxStateRef.current.stats.roundStats.score + 1,
          },
        })
      );
      let progress = currentLevelProgressRef.current + 1;
      setCurrentLevelProgress(progress);
      if (
        currentLevelRef.current + 2 === progress &&
        Location?.state?.endless === true
      ) {
        setTimeout(nextEndlessLevel, 700);
      }
      setScreenCover(true);
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness('correct');
      setTimeout(() => setScreenCover(false), 2000);
    } else {
      let popupWords = reduxStateRef.current.popupWords.filter(
        (d) => d.type === 'incorrect',
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      clickedAudio.onEnded = () => playAudio(currentTarget.App_Audio);
      dispatch(
        setStats({
          misses: reduxStateRef.current.stats.misses + 1,
          score: reduxStateRef.current.stats.score - 1,
          roundStats: {
            misses: reduxStateRef.current.stats.roundStats.misses + 1,
            score: reduxStateRef.current.stats.roundStats.score - 1,
          },
        })
      );
      if (currentLevelProgressRef.current > 0) {
        setCurrentLevelProgress(currentLevelProgressRef.current - 1);
      }
      setChosenPopupWord(popupWords[randomPopupNum].English);
      setCorrectness('wrong');
    }
    setTimeout(() => setCorrectness(null), 1000);
    let removedElement = gameDOMArray.find((DOMElement, index) => {
      if (DOMElement.props.id === e.target.id) {
        let newIndex = index;
        positionIndex.forEach((position) => {
          if (position === newIndex || positionIndex.length < newIndex) {
            newIndex = index + 1;
          }
        });
        positionIndex.push(newIndex);
      }
      return DOMElement.props.id === e.target.id;
    });
    gameDOMArray = gameDOMArray.filter(
      (DOMElement) => DOMElement.props.id !== e.target.id,
    );
    imageArray = imageArray.filter((d) => 
      d.Language !== removedElement.props.Language
    );
  };
  const stepDifficulty = () => {
    setProgress('0%');
    ready = false;
    setDisplay('game');
    setCurrentTime(0);
    if (roundRef.current < 3) {
      setRound(roundRef.current + 1);
      changeDisplay();
    } else {
      checkWorld();
    }
  };
  const gameDifficultyDisplay = () => {
    let key = 0;
    gameDOMArray = [];
    imageArray.forEach((d) => {
      let imgDimm = reduxStateRef.current.width / 6;
      let buffer = 15;
      if (reduxStateRef.current.width > reduxStateRef.current.height) {
        imgDimm =  reduxStateRef.current.height / 6;
      }
      if (difficultyRef.current !== 'hard') {
        if (imgDimm < 50) {
          imgDimm = 50;
        } else if (imgDimm > 375) {
          imgDimm = 375;
        }
      } else {
        imgDimm = reduxStateRef.current.width / 8;
        if (reduxStateRef.current.width > reduxStateRef.current.height) {
          imgDimm = reduxStateRef.current.height / 8;
        }
        if (imgDimm < 35) {
          imgDimm = 35;
        } else if (imgDimm > 300) {
          imgDimm = 300;
        }
      }
      positionIndex.forEach((pos) => {
        while (key === pos) {
          key = pos + 1;
        }
      });
      let xyCoord = displayArray[key];
      key = key + 1;
      if (xyCoord !== undefined) {
        let divStyle = {
          top:
            (reduxStateRef.current.height * xyCoord.y) / displayArrayDenom[1] - 
            (imgDimm + buffer) / 2,
          left:
            (reduxStateRef.current.width * xyCoord.x) / displayArrayDenom[0] - 
            (imgDimm + buffer) / 2,
          width: imgDimm + buffer,
          height: imgDimm + buffer,
        };
        let contentStyle = {
          backgroundImage: `url(${fetchImage(d.App_Art, dataset)})`,
          height: imgDimm,
          width: imgDimm,
        };
        gameDOMArray.push(
          <div
            key={key}
            id={`${key}`}
            style={divStyle}
            className='wordless_vocabcontainer'>
            <div
              id={`${key}`}
              style={contentStyle}
              onClick={checkCorrectness}
              className='wordless_vocab'
            />
          </div>
        );
        ready = true;
      }
    });
  };
  const playAudio = (file: string) => {
    if (clickedAudio !== null) {
      clickedAudio.pause();
      clickedAudio.currentTime = 0;
      clickedAudio = null;
    }
    clickedAudio = new Audio(fetchAudio(file, dataset));
    clickedAudio.play();
  };
  const createAudio = () => {
    let distractorArrayAudio = reduxStateRef.current.sortedData.forEach((dis) => {
      if (dis.App_Audio.length === 0) {
        return;
      }
      return (
        <audio
          id={dis.App_Audio}
          key={dis.App_Audio}
          src={`${fetchAudio(dis.App_Audio, dataset)}`}
        />
      );
    })
    return distractorArrayAudio;
  };
  if (display === 'stats') {
    if (Location?.state?.endless ? Location?.state?.endless : false) {
      ready = false;
      setDisplay('game');
      changeDisplay();
      return null;
    }
    if (shortTimer > 1) {
      stepDifficulty();
    }
    if (clickedAudio !== null) {
      clickedAudio.pause();
      clickedAudio.currentTime = 0;
      clickedAudio = null;
    }
  } else {
    if (testNum !== reduxState.height * reduxState.width) {
      testNum = reduxState.height * reduxState.width;
      gameDifficultyDisplay();
    }
    // TODO: what sets the display to 'stats'?
    if (currentTimeRef.current === fullTime && ready === true) {
      shortTimer = 0;
      setDisplay('stats');
    }
  }
  let innerImg = {
    backgroundImage: playButton,
  };
  const FloatingNumberContainer = styled.div`
    position: absolute;
    z-index: 999999;
    top: calc(${mouseClickYRef.current}px - 18vh);
    left: ${mouseClickXRef.current}px;
    height: 2px;
    width: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `;
  return (
    <div className='wordless_fullscreen'>
      {children}
      {display === 'stats' ? (
        <RoundBanner
          round={roundRef.current + 1}
          findStars={findStars}
          starsObj={starsObj}
        />
      ) : (
        <div className='centerContentContainer'>
          <FloatingNumberContainer>
            <FloatingNumber correctness={correctness} />
          </FloatingNumberContainer>
          <div
            id='shakeBox'
            onClick={() => playAudio(currentTarget.App_Audio)}
            className={
              difficultyRef.current === 'hard'
                ? 'wordless_keywordbox_hard shakebutton'
                : 'wordless_keywordbox shakebutton'
            }>
            <div className='wordless_innerimg' style={innerImg} />
          </div>
          <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
          {gameDOMArray}
          <CloseEndless
            enabled={Location?.state?.endless}
            onClick={setShowEndlessEnd}
          />
          <EndEndlessGame
            enabled={showEndlessEnd}
            currentLevel={currentLevel}
            setShowEndlessEnd={setShowEndlessEnd}
            updateLevelData={updateLevelData}
            endGame={undefined}
          />
        </div>
      )}
    </div>
  );
};

export default Wordless;
