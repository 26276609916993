import '../../index.scss';
import './index.scss';
import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../Store/hooks';
import { clearStats, setDifficulty } from '../../Store/store';
import { useLocation } from 'react-router-dom';
import { fetchImage, getSearchParams } from '../nonUIFuncs';

interface PlayScreenProps {
  setScreen: Function,
  gameDescription: string | undefined,
  scoreData: Array<scoreDataProps>,
  gameTypeImage: string,
};

interface difficultyScoreProps {
  difficulty: number;
}

interface scoreDataProps {
  gameName: string;
  category: string;
  difficultyScores: {
    easy: difficultyScoreProps;
    medium: difficultyScoreProps;
    hard: difficultyScoreProps;
  };
};

const PlayScreen: React.FC<PlayScreenProps> = ({setScreen, gameDescription, scoreData, gameTypeImage}) => {
  const [ mediumDisabled, setMediumDisabled ] = useState(true);
  const [ hardDisabled, setHardDisabled ] = useState(true);
  const location = useLocation();
  const params = getSearchParams(location);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (scoreData.length > 0) {
      let difficultyParam = params.find((p) => p.id === 'difficulty').value;
      let gameNameParam = params.find((p) => p.id === 'game-name').value;
      let unitParam = params.find((p) => p.id === 'unit').value;

      let game = scoreData.find((d) => d.gameName === gameNameParam && d.category === unitParam);
      if (game !== undefined) {
        if (game?.difficultyScores.medium.difficulty <= difficultyParam) {
          setMediumDisabled(false);
        }
        if (game?.difficultyScores.hard.difficulty <= difficultyParam) {
          setHardDisabled(false);
        }
      }
    }
  }, [scoreData]);
  return (
    <div className="playscreen_container">
      <div className="playscreen_content">
        <div className="playscreen_buttoncontainer">
          <div
            className="playscreen_playbutton hovercursor"
            onClick={() => {
              setScreen('gameScreen');
              dispatch(clearStats());
              dispatch(setDifficulty('easy'));
            }}>
            <h1 className="playscreen_playtext">Easy!</h1>
          </div>
          <div
            className={`
              playscreen_playbutton
              ${mediumDisabled ? '_disabled' : 'hovercursor'}
            `}
            onClick={() => {
              setScreen('gameScreen');
              dispatch(clearStats());
              dispatch(setDifficulty('medium'));
            }}>
            <h1 className="playscreen_playtext">Medium!</h1>
          </div>
          <div
            className={`
              playscreen_playbutton
              ${hardDisabled ? '_disabled' : 'hovercursor'}
            `}
            onClick={() => {
              setScreen('gameScreen');
              dispatch(clearStats());
              dispatch(setDifficulty('hard'));
            }}>
            <h1 className="playscreen_playtext">Hard!</h1>
          </div>
        </div>
        <div className="playscreen_descriptionbox">
          <p className="playscreen_descriptiontext">{gameDescription}</p>
        </div>
      </div>
      <div className='playscreen_gametypebadgecontainer'>
        <div
          className='playscreen_gametypebadge'
          style={{backgroundImage: `url(${fetchImage(gameTypeImage)})`}}
        />
      </div>
    </div>
  );
};

export default PlayScreen;
