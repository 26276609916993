import './index.scss';
import '../../App.scss';
import { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../Store/hooks';
import { selectReduxSlice } from '../../Store/store';
import { redirectFromParams, getSearchParams, capEveryWord } from '../nonUIFuncs';
import IndivGame from '../UI/GameTypeButton';


interface DifficultyProps {
  difficulty: number;
  firstStars: number;
  secondStars: number;
  stars: number;
}
interface GameMenuProps {
  scoreData: Array<{
    category: string;
    gameType: string;
    gameName: string;
    difficultyScores: {
      easy: DifficultyProps;
      medium: DifficultyProps;
      hard: DifficultyProps;
    };
  }>;
  dataset: string;
};

interface gameArrayProps {
  name: string,
  progress: string,
  stars: number,
  image: string,
};

const GameTypeMenu: FC<GameMenuProps> = ({scoreData, dataset}) => {
  const gameArrRef = useRef<Array<gameArrayProps>>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const params = getSearchParams(location);
  const unitParamObj = params.find((d:any) => d.id === 'unit');
  const reduxState = useAppSelector(selectReduxSlice);
  const dispatch = useAppDispatch();
  const [gameArray, setGameArray] = useState<Array<gameArrayProps>>([]);
  const [unitGames, setUnitGames] = useState<Array<{}>>([]);
  gameArrRef.current = gameArray;
  // let unit = unitParamObj.modifier;
  useEffect(() => {
    let string = `${unitParamObj.value} games`;
    document.title = capEveryWord(string);
    let redirectPath:string = redirectFromParams(['difficulty', 'location', 'unit']) || '';
    if (redirectPath.length > 0) {
      navigate(redirectPath);
    }
  }, []);
  useEffect(() => {
    if (dataset === 'b_') {
      if (scoreData.length > 0) {
        getGames(reduxState.b_data);
      }
    } else {
      if (scoreData.length > 0) {
        getGames(reduxState.data);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreData, dataset]);
  const getGames = (data: any) => {
    let uniqueGameNames: string[] = gameArrRef.current.map((d) => d.name);
    let foundUnitGames = data.filter((d: any) => d.category === unitParamObj.value);
    setUnitGames(foundUnitGames);
    let categoryParam = unitParamObj.value;
    let difficutlyParam = params.find((d) => d.id === 'difficulty').value;
    let filteredScoreData = scoreData.filter((d) => d.category === categoryParam);
    foundUnitGames.forEach((datum: any) => {
      if (!uniqueGameNames.includes(datum.displayGameType)) {
        uniqueGameNames.push(datum.displayGameType);
        let gameImg = datum.displayGameType.replaceAll('-', '_').replaceAll(' ', '_');
        let current = gameArrRef.current;
        if (datum.difficulty <= Number(difficutlyParam)) {
          let progressData = filteredScoreData.filter((d) => d.gameType === datum.game);
          let currentStars = 0;
          let totalStars = 0;
          progressData.forEach((d) => {
            let gameScoringData = reduxState.goldStar.find((indivGS) => indivGS.game === d.gameType);
            if (d.difficultyScores.easy.difficulty <= Number(difficutlyParam)) {
              currentStars = currentStars + d.difficultyScores.easy.stars;
              if (gameScoringData?.secondType !== 'none') {
                totalStars = totalStars + 6;
              } else {
                totalStars = totalStars + 3;
              }
            }
            if (d.difficultyScores.medium.difficulty <= Number(difficutlyParam)) {
              currentStars = currentStars + d.difficultyScores.medium.firstStars + d.difficultyScores.medium.secondStars;
              if (gameScoringData?.secondType !== 'none') {
                totalStars = totalStars + 6;
              } else {
                totalStars = totalStars + 3;
              }
            }
            if (d.difficultyScores.hard.difficulty <= Number(difficutlyParam)) {
              currentStars = currentStars + d.difficultyScores.hard.firstStars + d.difficultyScores.hard.secondStars;
              if (gameScoringData?.secondType !== 'none') {
                totalStars = totalStars + 6;
              } else {
                totalStars = totalStars + 3;
              }
            }
          });
          let progress = Math.round((currentStars / totalStars) * 100);
          let starProgress = 0;
          if (progress >= 99) {
            starProgress = 3;
          } else if (progress >= 50 && progress < 99) {
            starProgress = 2;
          } else if (progress >= 1 && progress < 50) {
            starProgress = 1;
          } else {
            starProgress = 0;
          }
          current.push({
            name: datum.displayGameType,
            progress: `${progress}%`,
            stars: starProgress,
            image: `Game_Type_${gameImg}2x.png`,
          });
          setGameArray(current);
        }
      }
    });
  };
  return (
    <div className='gamemenu_container'>
      <div className='gamemenu_contents'>
        {gameArrRef.current.map((obj) => {
          return (
            <IndivGame
              path='/choose-sub-unit'
              onmouseover={() => {}}
              key={obj.name}
              name={obj.name}
              progress={obj.progress}
              stars={obj.stars}
              image={obj.image}
              paramToSet='game-type'
              classNamePre='indivgame'
            />
          );
        })}
      </div>
    </div>
  );
};

export default GameTypeMenu;
