import './index.scss';
import { useState, useEffect, FC, useRef } from 'react';
import { useAppSelector, useAppDispatch } from '../../Store/hooks';
import { selectReduxSlice, setInitialGameData } from '../../Store/store';
import PlayScreen from '../PlayScreen';
import PlayAgainScreen from '../PlayAgainScreen';
import GameContainer from '../GameContainer';
import {
  redirectFromParams,
  findSingleGameData,
  findSquishName,
  getSearchParams,
} from '../nonUIFuncs';
import { useNavigate, useLocation } from 'react-router-dom';
import ProgressBar from '../UI/ProgressBar';

interface GameSwitcherProps {
  screen: string;
  gameDescription: string;
  setScreen: Function;
  gameTime: number|null;
  setGameTime: Function;
  updateLevelData: Function;
  updateScoreData: Function;
  scoreData: any;
  setProgress: Function;
  scoreType: string;
  gameTypeImage: string;
  findStars: Function;
  starsObj: {
    starOne: boolean;
    starTwo: boolean;
    starThree: boolean;
  };
  game: {score: number; time: number};
  bestScore: string;
  gameToSave: any;
  starsToAdd: number;
  dataset: string;
};

const GameSwitcher: FC<GameSwitcherProps> = (
  {
    screen,
    setScreen,
    gameDescription,
    setGameTime,
    gameTime,
    updateLevelData,
    updateScoreData,
    scoreData,
    setProgress,
    scoreType,
    gameTypeImage,
    findStars,
    starsObj,
    game,
    bestScore,
    gameToSave,
    starsToAdd,
    dataset,
  }
) => {
  switch (screen) {
    case 'playScreen':
      return (
        <PlayScreen
          setScreen={setScreen}
          gameDescription={gameDescription}
          scoreData={scoreData}
          gameTypeImage={gameTypeImage}
        />
      );
    case 'gameScreen':
      return (
        <GameContainer
          gameTime={gameTime}
          setScreen={setScreen}
          setGameTime={setGameTime}
          updateLevelData={updateLevelData}
          setProgress={setProgress}
          scoreType={scoreType}
          gameTypeImage={gameTypeImage}
          findStars={findStars}
          starsObj={starsObj}
          dataset={dataset}
        />
      );
    case 'playAgainScreen':
      return (
        <PlayAgainScreen
          setScreen={setScreen}
          gameTime={gameTime}
          updateScoreData={updateScoreData}
          scoreType={scoreType}
          gameTypeImage={gameTypeImage}
          starsObj={starsObj}
          game={game}
          bestScore={bestScore}
          gameToSave={gameToSave}
          starsToAdd={starsToAdd}
        />
      );
    default:
      return (
        <PlayScreen
          setScreen={setScreen}
          gameDescription={gameDescription}
          scoreData={scoreData}
          gameTypeImage={gameTypeImage}
        />
      );
  }
};

interface FullGameContainerProps {
  setShowTopNav: Function;
  updateLevelData: Function;
  updateScoreData: Function;
  scoreData: any;
  dataset: string;
}

const FullGameContainer: FC<FullGameContainerProps> = (
  {setShowTopNav, updateLevelData, updateScoreData, scoreData, dataset}
) => {
  const reduxStateRef = useRef<any>();
  const gameTimeRef = useRef(0);
  const [screen, setScreen] = useState<string>('playScreen');
  const [gameTime, setGameTime] = useState<number>(0); // This may need a ref to get correct time in playAgainScreen
  const [progress, setProgress] = useState<string>('0%');
  const [gameTypeImage, setGameTypeImage] = useState<string>('');
  const [starOne, setStarOne] = useState(false);
  const [starTwo, setStarTwo] = useState(false);
  const [starThree, setStarThree] = useState(false);
  const [game, setGame] = useState<any>({});
  const reduxState = useAppSelector(selectReduxSlice);
  const location = useLocation();

  const [gameToSave, setGameToSave] = useState({});
  const [starsToAdd, setStarsToAdd] = useState(0);
  const [bestScore, setBestScore] = useState('');

  const dispatch = useAppDispatch();
  const Params = getSearchParams(location);
  // const params = location.search
  //   // .replace('?&', '')
  //   .split('&')
  //   .map((p) => {
  //     let param = p.replaceAll('%20', ' ').replaceAll('%27', "'").split('=');
  //     let paramObj = {state: param[0], value: param[1], unitNumber: 0};
  //     if (param[0] === 'unit') {
  //       if (paramObj.value.includes('10')) {
  //         paramObj = {
  //           ...paramObj,
  //           unitNumber: Number(paramObj.value.slice(0, 2)),
  //           value: paramObj.value.slice(2),
  //         }
  //       } else {
  //         paramObj = {
  //           ...paramObj,
  //           unitNumber: Number(paramObj.value.slice(0, 1)),
  //           value: paramObj.value.slice(1),
  //         }
  //       }
  //     }
  //     return paramObj;
  //   });
  const locationParam = Params.find((p) => p.id === 'location')?.value;
  const gameTypeParam: any = Params.find((p) => p.id === 'game-type')?.value;
  const SquishName = findSquishName(gameTypeParam);
  const navigate = useNavigate();
  const dialectStructure = reduxState.dialectStructure.find(
    (d) => d.display === locationParam,
  );
  reduxStateRef.current = reduxState;
  gameTimeRef.current = gameTime;
  useEffect(() => {
    let gameImg = gameTypeParam.replaceAll('-', '_').replaceAll(' ', '_');
    setGameTypeImage(`Game_Type_${gameImg}2x.png`);
    let redirectPath:string =
      redirectFromParams(
        ['difficulty', 'location', 'unit', 'game-type', 'game-name']
      ) || '';
    if (redirectPath.length > 0) {
      navigate(redirectPath);
    }
    getGameData();
    return () => {
      setShowTopNav(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getGameData();
  }, [dataset])
  useEffect(() => {
    setShowTopNav(screen === 'playScreen' ? true : false);
  }, [screen]);
  const getGameData = () => {
    let selectedGame = reduxState.data.find(
      (d) => d.category === Params.find(
        (p) => p.id === 'unit'
      )?.value
      && d.displayGameName === Params.find(
        (p) => p.id === 'game-name'
      )?.value
    );
    if (selectedGame === undefined) {
      selectedGame = reduxState.b_data.find(
        (d) => d.category === Params.find(
          (p) => p.id === 'unit'
        )?.value
        && d.displayGameName === Params.find(
          (p) => p.id === 'game-name'
        )?.value
      );
    }
    let singleGameData:any =
      findSingleGameData(
        {target: selectedGame?.target || ''},
        reduxState,
        locationParam,
        dataset,
      );
      
    dispatch(setInitialGameData(
      location,
      locationParam === 'Minto' ? reduxState.b_data : reduxState.data,
      locationParam === 'Minto' ? reduxState.b_gameData : reduxState.gameData,
      gameTypeParam || 'Word To Image',
      singleGameData,
    ));
  };

  const findStars = (time: number) => {
    setGameTime(time);
    let gameInfo:any = reduxState.goldStar.find(
      (d) => d.game === reduxState.specificGame.game,
    );
    let difficultyGoldStar = {
      oneStar: gameInfo?.oneStarEasy,
      twoStar: gameInfo?.twoStarEasy,
      threeStar: gameInfo?.threeStarEasy,
      oneStarTwo: gameInfo?.oneStarTwoEasy,
      twoStarTwo: gameInfo?.twoStarTwoEasy,
      threeStarTwo: gameInfo?.threeStarTwoEasy,
    };
    if (reduxState.difficulty === 'medium') {
      difficultyGoldStar = {
        oneStar: gameInfo?.oneStarMedium,
        twoStar: gameInfo?.twoStarMedium,
        threeStar: gameInfo?.threeStarMedium,
        oneStarTwo: gameInfo?.oneStarTwoMedium,
        twoStarTwo: gameInfo?.twoStarTwoMedium,
        threeStarTwo: gameInfo?.threeStarTwoMedium,
      };
    } else if (reduxState.difficulty === 'hard') {
      difficultyGoldStar = {
        oneStar: gameInfo?.oneStarHard,
        twoStar: gameInfo?.twoStarHard,
        threeStar: gameInfo?.threeStarHard,
        oneStarTwo: gameInfo?.oneStarTwoHard,
        twoStarTwo: gameInfo?.twoStarTwoHard,
        threeStarTwo: gameInfo?.threeStarTwoHard,
      };
    }
    gameInfo = {
      game: gameInfo?.game,
      Key: gameInfo?.Key,
      notImplimented: gameInfo?.notImplimented,
      scoreType: gameInfo?.scoreType,
      ratings: [
        {
          type: gameInfo?.type,
          threeStar: Number(difficultyGoldStar.threeStar),
          twoStar: Number(difficultyGoldStar.twoStar),
          oneStar: Number(difficultyGoldStar.oneStar),
          stars: 0,
          nextGoals: null,
        },
        {
          type: gameInfo?.secondType,
          threeStar: Number(difficultyGoldStar.threeStarTwo),
          twoStar: Number(difficultyGoldStar.twoStarTwo),
          oneStar: Number(difficultyGoldStar.oneStarTwo),
          stars: 0,
          nextGoals: null,
        },
      ],
    };
    gameInfo.ratings = gameInfo?.ratings.filter((d:any) => d.type !== 'none');
    let correctedTime = time === null ? 99999 : time;
    const checkFuncObj = {
      correct: (val: number) => reduxState.stats.score >= val,
      time: (val: number) => correctedTime <= val,
      incorrect: (val: number) => reduxState.stats.misses <= val,
      accuracy: (val: number) => reduxState.stats.hits / (reduxState.stats.hits + reduxState.stats.misses) >= val,
    };
    gameInfo.ratings.forEach((d: {
      type: keyof Object,
      stars: number,
      nextGoals: number,
      threeStar: number,
      twoStar: number,
      oneStar: number,
    }) => {
      let checkFunc = checkFuncObj[d.type];
      if (checkFunc(d.threeStar)) {
        d.stars = 3;
      } else if (checkFunc(d.twoStar)) {
        d.stars = 2;
        d.nextGoals = d.threeStar;
      } else if (checkFunc(d.oneStar)) {
        d.stars = 1;
        d.nextGoals = d.twoStar;
      } else {
        d.nextGoals = d.oneStar;
      }
    });
    let object = {
      difficulty: reduxState.difficulty,
      score: reduxState.stats.score,
      time: time,
      correct: reduxState.stats.hits,
      incorrect: reduxState.stats.misses,
      Key: reduxState.specificGame.Key,
      stars: gameInfo.ratings[0].stars,
      firstStars: gameInfo.ratings[0].stars,
      firstNextGoals: gameInfo.ratings[0].nextGoals,
      secondStars: null,
      secondNextGoals: null,
    };
    let fullGame = scoreData.find((d: {Key: number}) => d.Key === reduxState.specificGame.Key);
    // game = fullGame.difficultyScores[object.difficulty];
    setGame(fullGame.difficultyScores[object.difficulty]);
    if (gameInfo.ratings.length > 1) {
      if (fullGame.secondType !== 'none') {
        object.stars = gameInfo.ratings[0].stars + gameInfo.ratings[1].stars;
        object.secondStars = gameInfo.ratings[1].stars;
        object.secondNextGoals = gameInfo.ratings[1].nextGoals;
      }
    }
    setBestScore(game.score > object.score ? 'previous' : 'current');
    let starNum = Math.floor(object.stars / (fullGame.secondType === undefined ? 1 : 2));
    if (starNum >= 1) {
      setStarOne(true);
    }
    if (starNum >= 2) {
      setStarTwo(true);
    }
    if (starNum >= 3) {
      setStarThree(true);
    }
    setGameToSave(object);
    setStarsToAdd(object.stars - game.stars);
    if (starsToAdd < 0) {
      setStarsToAdd(0);
      setGameToSave(game);
    }
  };
  let fullScreen = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  let gameDesc = reduxState.gameDescriptions.find(
    (desc) => desc.game === gameTypeParam.replaceAll('-', ' ')
  )?.description;
  let scoreType = reduxStateRef.current.goldStar.find(
    (gs: {game: string}) => gs.game === SquishName
  ).scoreType;
  return (
    <div className='fullgamecontainer_container' style={fullScreen}>
      <GameSwitcher
        updateScoreData={updateScoreData}
        screen={screen}
        setScreen={setScreen}
        gameTime={gameTimeRef.current}
        setGameTime={setGameTime}
        gameDescription={
          gameDesc !== undefined ? gameDesc : 'No Description Found!'
        }
        updateLevelData={updateLevelData}
        scoreData={scoreData}
        setProgress={setProgress}
        scoreType={scoreType}
        gameTypeImage={gameTypeImage}
        findStars={findStars}
        starsObj={{starOne:starOne, starTwo: starTwo, starThree: starThree}}
        game={game}
        bestScore={bestScore}
        gameToSave={gameToSave}
        starsToAdd={starsToAdd}
        dataset={dataset}
      />
      {/* Progress Bar / Timer */}
      {screen === 'gameScreen' ? (
        <div className="gamecontainer_progressbar_container">
          <ProgressBar
            width={
              reduxStateRef.current.height > 500
                ? 'clamp(200px, 25vw, 350px)'
                : 'clamp(100px, 12.5vw, 175px)'
            }
            id={`game-screen`}
            progress={progress}
            isTimer={scoreType === 'normal' ? true : false}
          />
        </div>
      ) : null}
    </div>
  );
};

export default FullGameContainer;
