import './index.scss';
import '../../App.scss';

import { useEffect, useRef, useState, FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { selectReduxSlice, setStats } from '../../Store/store';
import { useLocation } from 'react-router-dom';
import {
  fetchAudio,
  fetchImage,
  shuffle,
  placementArray,
  compare,
  getSearchParams,
  capEveryWord,
} from '../nonUIFuncs';
import styled from 'styled-components';

import PopupWord from '../UI/PopupWord';
import CloseEndless from '../UI/CloseEndless';
import EndEndlessGame from '../UI/EndEndlessGame';
import FloatingNumber from '../UI/FloatingNumber';
import RoundBanner from '../UI/RoundBanner';

interface currentTargetProps {
  English: string;
  amount: number;
  Language: string;
  LanguageNumber: string;
  App_Audio: string;
}

let difficultyVar = 'easy';
let displayedClock = 0;
let displayArray: Array<any> = [];
let displayArrayDenom = [3, 3];
let imageArray: Array<any> = [];
let shortTimer = 0;
let correctSoundEffect = new Audio(`${fetchAudio('correctSound.mp3')}`);
let subCatAudio = [];
let clickedAudio: any = null;
let switchDisplayVar = 'stats';
let testNum = 1;
let positionIndex: Array<any> = [];
let showPopup = false;
let currentLevelVar = 0;
let currentIndex = 0;
let currentTargetAudio: any = null;
let audioDOM:Array<any> = [];
let round = 0;
let ready = false;
let gameDOMArray: any = [];
let currentTarget:currentTargetProps = {
  English: '',
  amount: 1,
  Language: '',
  LanguageNumber: '',
  App_Audio: '',
};
let roundData:Array<currentTargetProps> = [{
  English: '',
  amount: 1,
  Language: '',
  LanguageNumber: '',
  App_Audio: '',
}];
let keywordString:string = '';

interface GameContainerProps {
  checkWorld: Function;
  scoreType?: string;
  time?: number;
  updateLevelData: Function;
  children: any;
  setProgress: Function;
  findStars: Function;
  starsObj: {starOne: boolean; starTwo: boolean; starThree: boolean};
  dataset: string;
}

const Counting: FC<GameContainerProps> = ({
  checkWorld,
  updateLevelData,
  children,
  setProgress,
  findStars,
  starsObj,
  dataset,
}) => {
  const dispatch = useAppDispatch();
  const reduxState = useAppSelector(selectReduxSlice);
  const Location = useLocation();
  const Params = getSearchParams(Location);

  const clickedArrayRef = useRef<Array<any>>([]);
  const currentLevelRef = useRef(0);
  const currentLevelProgressRef = useRef(0);
  const reduxStateRef = useRef(reduxState);

  const [correctness, setCorrectness] = useState<string|null>(null);
  const [clickedArray, setClickedArray] = useState<Array<any>>([]);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [chosenPopupWord, setChosenPopupWord] = useState('');
  const [currentLevelProgress, setCurrentLevelProgress] = useState(0);
  const [showEndlessEnd, setShowEndlessEnd] = useState(false);

  clickedArrayRef.current = clickedArray;
  currentLevelProgressRef.current = currentLevelProgress;
  currentLevelRef.current = currentLevel;
  reduxStateRef.current = reduxState;

  useEffect(() => {
    let string = `${Params.find((d) => d.id === "unit").value} - ${Params.find((d) => d.id === "game-name").value} - ${reduxStateRef.current.difficulty}`;
    document.title = capEveryWord(string);
    let timer = setInterval(updateDisplayedClock, 1000);
    setCurrentLevel(0);
    currentLevelVar = 0;
    setCurrentLevelProgress(0);
    switchDisplayVar = 'stats';
    difficultyVar = reduxStateRef.current.difficulty;
    reduxStateRef.current.sortedData.forEach((d) => {
      subCatAudio.push({
        playableAudio: new Audio(`${fetchAudio(d.App_Audio, dataset)}`),
        image: d.App_Art,
      });
    });
    ready = true;
    return () => {
      switchDisplayVar = 'stats';
      difficultyVar = 'easy';
      clearInterval(timer);
      displayedClock = 0;
      gameDOMArray = [];
      shortTimer = 0;
      round = 0;
    };
  }, []);
  const updateDisplayedClock = () => {
    if (Location?.state?.endless !== true) {
      displayedClock = displayedClock + 1;
    }
    shortTimer = shortTimer + 1;
  };
  const changeDisplay = () => {
    currentIndex = 0;
    let sliceNumber = 4;
    let iterations = 6;
    if (difficultyVar === 'medium') {
      sliceNumber = 6;
      iterations = 8;
    } else if (difficultyVar === 'hard') {
      sliceNumber = 8;
      iterations = 10;
    } else {
      positionIndex = [];
    }
    if (clickedAudio !== null) {
      clickedAudio.pause();
      clickedAudio.currentTime = 0;
    }
    let shuffled = shuffle(reduxStateRef.current.sortedData).slice(0, sliceNumber);
    imageArray = [];
    for (let i = 0; i < iterations; i++) {
      imageArray.push(...shuffled);
    }
    let tall = reduxStateRef.current.height > reduxStateRef.current.width;
    if (difficultyVar === 'easy') {
      displayArray = placementArray(0.45, 1.1, 4.25, 3.9, 1, 0.8);
      displayArrayDenom = [5, 5];
      if (tall) {
        displayArray = placementArray(0.45, 1.5, 4.45, 4.5, 1, 0.8);
        displayArrayDenom = [5, 6];
      }
    } else if (difficultyVar === 'medium') {
      displayArray = placementArray(0.45, 1.2, 5.65, 4.85, 0.9, 0.75);
      displayArrayDenom = [7, 6];
      if (tall) {
        displayArray = placementArray(0.55, 1.5, 4.55, 6, .9, .7);
        displayArrayDenom = [6, 8];
      }
    } else {
      displayArray = placementArray(.67, 1.5, 6.87, 5.7, 0.795, 0.7);
      displayArrayDenom = [9, 7];
      if (tall) {
        displayArray = placementArray(0.45, 1.8, 5.85, 7.7, 0.9, 0.75);
        displayArrayDenom = [7, 10];
      }
    }
    shuffleData();
  };
  const shuffleData = () => {
    let numberData = [...reduxStateRef.current.numberData].sort((a, b) => compare(a.English, b.English));
    imageArray = shuffle(imageArray);
    roundData = [];
    let englishArray: Array<string> = [];
    imageArray = imageArray.slice(0, displayArray.length);
    let uniqueKey = 0;
    imageArray = imageArray.map((d) => {
      if (englishArray.includes(d.English)) {
        roundData = roundData.map((key) => {
          if (key.English === d.English) {
            let newKey = {
              ...key,
              amount: key.amount + 1,
              LanguageNumber: numberData[key.amount + 1].Language,
            };
            return newKey;
          } else {
            return key;
          }
        });
      } else {
        englishArray.push(d.English);
        roundData.push({
          English: d.English,
          amount: 1,
          Language: d.Language,
          LanguageNumber: numberData[1].Language,
          App_Audio: d.App_Audio,
        });
      }
      uniqueKey = uniqueKey + 1;
      let newDatum = {
        ...d,
        newKey: `${d.English}${uniqueKey}`,
      };
      return newDatum;
    });
    currentTarget = roundData[currentIndex];
    keywordString = `${roundData[currentIndex].LanguageNumber} - ${roundData[currentIndex].Language}`;
    currentTargetAudio = new Audio(`${fetchAudio(roundData[currentIndex].App_Audio, dataset)}`);
    playAudioWithNumber(currentTargetAudio);
    gameDifficultyDisplay();
  };
  const playAudioWithNumber = (currentTargetAudio: any) => {
    let numberData: any = reduxStateRef.current.numberData.find((d) => Number(d.English) === roundData[currentIndex].amount);
    let numberAudio = new Audio(`${fetchAudio(numberData.App_Audio, dataset)}`);
    numberAudio.play();
    numberAudio.onended = () => currentTargetAudio.play();
  };
  const nextEndlessLevel = () => {
    setCurrentLevel(currentLevelRef.current + 1);
    currentLevelVar = currentLevelVar + 1;
    setCurrentLevelProgress(0);
    let number = document.getElementById("levelNumber");
    number?.classList.add("levelNumber");
    setTimeout(() => number?.classList.remove("levelNumber"), 1200);
  };
  const checkCorrectness = () => {
    let correct = 'incorrect';
    let i = 0;
    if (clickedArrayRef.current.length === currentTarget.amount) {
      clickedArrayRef.current.forEach((string: string) => {
        if (string.includes(currentTarget.English)) {
          i = i + 1;
        }
      });
    }
    if (i === currentTarget.amount) {
      correct = "correct";
    }
    if (correct === "correct") {
      let popupWords = reduxStateRef.current.popupWords.filter(
        (d) => d.type === "correct"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          hits: reduxStateRef.current.stats.hits + 1,
          score: reduxStateRef.current.stats.score + 1,
          roundStats: {
            hits: reduxStateRef.current.stats.roundStats.hits + 1,
            score: reduxStateRef.current.stats.roundStats.score + 1,
          },
        }),
      );
      correctSoundEffect.play();
      let progress = currentLevelProgressRef.current + 1;
      setCurrentLevelProgress(progress);
      if (
        currentLevelRef.current + 2 === progress
        && Location?.state?.endless === true
      ) {
        setTimeout(nextEndlessLevel, 700);
      }
      setChosenPopupWord(popupWords[randomPopupNum].Language);
      setCorrectness('correct');
      gameDOMArray = gameDOMArray.filter(
        (datum:{props:{id:string}}) => !datum.props.id.includes(currentTarget.English) // TODO: Might be the wrong path, check here if erroring
      );
      currentIndex = currentIndex + 1;
      currentTarget = roundData[currentIndex];
      keywordString = `${roundData[currentIndex]?.LanguageNumber} - ${roundData[currentIndex]?.Language}`;
      if (currentTarget === undefined) {
        if (Location?.state?.endless === true) {
          clickedArrayRef.current.forEach((string) => {
            let element = document.getElementById(string);
            element?.classList.remove("counting_clicked");
            element?.classList.add("counting_notclicked");
            setClickedArray([]);
          });
          changeDisplay();
        }
      } else {
        currentTargetAudio = new Audio(`${fetchAudio(currentTarget.App_Audio, dataset)}`);
        // currentTargetAudio.play();
        playAudioWithNumber(currentTargetAudio);

      }
      setClickedArray([]);
    } else {
      let popupWords = reduxStateRef.current.popupWords.filter(
        (d) => d.type === "incorrect"
      );
      let randomPopupNum = Math.floor(Math.random() * popupWords.length);
      showPopup = true;
      setTimeout(() => (showPopup = false), 1300);
      dispatch(
        setStats({
          misses: reduxStateRef.current.stats.misses + 1,
          score: reduxStateRef.current.stats.score - 1,
          roundStats: {
            misses: reduxStateRef.current.stats.roundStats.misses + 1,
            score: reduxStateRef.current.stats.roundStats.score - 1,
          },
        })
      );
      if (currentLevelProgressRef.current > 0) {
        setCurrentLevelProgress(currentLevelProgressRef.current - 1);
      }
      setChosenPopupWord(popupWords[randomPopupNum].Language);
      setCorrectness('wrong');
      clickedArrayRef.current.forEach((string) => {
        let element = document.getElementById(string);
        element?.classList.remove("counting_clicked");
        element?.classList.add("counting_notclicked");
        setClickedArray([]);
      });
    }
    setTimeout(() => setCorrectness(null), 1000);
  };
  const stepDifficulty = () => {
    setProgress('0%');
    ready = false;
    switchDisplayVar = 'game';
    if (clickedAudio !== null) {
      clickedAudio.pause();
      clickedAudio.currentTime = 0;
    }
    if (round < 3) {
      round = round + 1;
      changeDisplay();
    } else {
      checkWorld();
    }
  };
  const adjustElements = () => {
    let newArray = [...gameDOMArray];
    let imgDimm = reduxStateRef.current.width / 10;
    if (difficultyVar === "hard") {
      imgDimm = reduxStateRef.current.width / 13;
    }
    let buffer = 15;
    if (reduxStateRef.current.width > reduxStateRef.current.height) {
      imgDimm = reduxStateRef.current.height / 10;
      if (difficultyVar === "hard") {
        imgDimm = reduxStateRef.current.height / 13;
      }
    }
    if (imgDimm < 50 && difficultyVar !== "hard") {
      imgDimm = 50;
    } else if (imgDimm > 375) {
      imgDimm = 375;
    }
    newArray = newArray.map((element) => {
      let coords = element.props.children.props['data-xycoord'];
      let container = {
        top:
          (reduxStateRef.current.height * coords.y) /
            displayArrayDenom[1] - (imgDimm + buffer) / 2,
        left:
          (reduxStateRef.current.width * coords.x) /
            displayArrayDenom[0] - (imgDimm + buffer) / 2,
      };
      return (
        <div
          key={element.key}
          id={element.props.id}
          style={container}
          className={element.props.className}>
          <div
            {...element.props.children.props}
            style={{
              ...element.props.children.props.style,
              width: imgDimm,
              height: imgDimm,
            }}
          />
        </div>
      );
    });
    gameDOMArray = newArray;
  };
  const gameDifficultyDisplay = () => {
    let key = 0;
    gameDOMArray = [];
    let imgDimm = reduxStateRef.current.width / 10;
    if (difficultyVar === "hard") {
      imgDimm = reduxStateRef.current.width / 13;
    }
    let buffer = 15;
    if (reduxStateRef.current.width > reduxStateRef.current.height) {
      imgDimm = reduxStateRef.current.height / 10;
      if (difficultyVar === "hard") {
        imgDimm = reduxStateRef.current.height / 13;
      }
    }
    if (imgDimm < 50 && difficultyVar !== "hard") {
      imgDimm = 50;
    } else if (imgDimm > 375) {
      imgDimm = 375;
    }
    imageArray = shuffle(imageArray);
    let arr: Array<any> = [];
    imageArray.forEach((d) => {
      positionIndex.forEach((pos) => {
        while (key === pos) {
          key = pos + 1;
        }
      });
      let xyCoord: {x:number, y:number} = displayArray[key];
      key = key + 1;
      if (xyCoord !== undefined) {
        let image = {
          backgroundImage: `url(${fetchImage(d.App_Art, dataset)})`,
          height: imgDimm,
          width: imgDimm,
        };
        let container = {
          top:
            (reduxStateRef.current.height * xyCoord.y) / displayArrayDenom[1] -
            (imgDimm + buffer) / 2,
          left:
            (reduxStateRef.current.width * xyCoord.x) / displayArrayDenom[0] -
            (imgDimm + buffer) / 2,
        };
        arr.push(
          <div
            id={d.newKey}
            key={key}
            style={container}
            className="counting_notclicked">
            <div
              data-xycoord={xyCoord}
              className="counting_item"
              style={image}
              onClick={() => editClicked(d.newKey)}
            />
          </div>
        );
      }
    });
    gameDOMArray = arr;
    ready = true;
  };
  const editClicked = (clickedKey:string) => {
    let element = document.getElementById(clickedKey);
    if (clickedArrayRef.current.includes(clickedKey)) {
      element?.classList.remove("counting_clicked");
      element?.classList.add("counting_notclicked");
      setClickedArray(clickedArrayRef.current.filter((key) => key !== clickedKey));
    } else {
      element?.classList.add("counting_clicked");
      element?.classList.remove("counting_notclicked");
      setClickedArray([...clickedArrayRef.current, clickedKey]);
    }
  };
  if (switchDisplayVar === 'stats') {
    if (Location?.state?.endless || false) {
      ready = false;
      switchDisplayVar = 'game';
      changeDisplay();
      return null;
    }
    if (shortTimer > 1) {
      stepDifficulty();
    }
  } else {
    if (testNum !== reduxStateRef.current.height * reduxStateRef.current.width) {
      testNum = reduxStateRef.current.height * reduxStateRef.current.width;
      adjustElements();
    }
    if (gameDOMArray.length === 0 && ready === true) {
      shortTimer = 0;
      switchDisplayVar = 'stats';
      if (clickedAudio !== null) {
        clickedAudio.pause();
        clickedAudio.currentTime = 0;
      }
    }
  }
  const FloatingNumberContainer = styled.div`
    position: absolute;
    z-index: 999999;
    top: calc(${reduxStateRef.current.height / 2}px - 18vh);
    left: ${reduxStateRef.current.width / 2}px;
    height: 2px;
    width: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `;
  return (
    <div className='counting_fullscreen'>
      {children}
      {switchDisplayVar === 'stats' ? (
        <RoundBanner
          round={round + 1}
          findStars={findStars}
          starsObj={starsObj}
        />
      ) : (
        <div className='centerContentContainer'>
          {audioDOM}
          <FloatingNumberContainer>
            <FloatingNumber correctness={correctness} />
          </FloatingNumberContainer>
          <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
          {gameDOMArray || null}
          <CloseEndless
            enabled={Location?.state?.endless || false}
            onClick={setShowEndlessEnd}
          />
          <EndEndlessGame
            enabled={showEndlessEnd}
            currentLevel={currentLevel}
            setShowEndlessEnd={setShowEndlessEnd}
            updateLevelData={updateLevelData}
            endGame={undefined}
          />
          {keywordString.length !== 0 ? (
            <div
              className='topcontainer counting_centercontainer'
              onClick={() => playAudioWithNumber(currentTargetAudio)}>
              <div className='counting_topcontainertext'>
                {keywordString}
              </div>
              <div
                onClick={checkCorrectness}
                className='counting_checkbutton'>
                Check
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Counting;
