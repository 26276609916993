import './index.scss';
import { FC, useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../Store/hooks';
import { selectReduxSlice, findPlayerLevel } from '../../Store/store';
import { goBack, fetchImage } from '../nonUIFuncs';
import { useLocation, Link } from 'react-router-dom';

interface PlayAgainScreenProps {
  setScreen: Function;
  gameTime: number|null;
  updateScoreData: Function;
  scoreType: string;
  gameTypeImage: string;
  starsObj: {
    starOne: boolean;
    starTwo: boolean;
    starThree: boolean;
  };
  game: {score: number; time: number};
  bestScore: string;
  gameToSave: any;
  starsToAdd: number;
}

const PlayAgainScreen: FC<PlayAgainScreenProps> = ({
  setScreen,
  gameTime,
  updateScoreData,
  scoreType,
  gameTypeImage,
  starsObj,
  game,
  bestScore,
  gameToSave,
  starsToAdd,
}) => {
  const reduxState = useAppSelector(selectReduxSlice);
  const dispatch = useAppDispatch();
  const location = useLocation();
  // const [game, setGame] = useState({time: 0, score: 0, stars: 0});
  let unitName = '';
  let Key:number = 1;
  let searchArr:string = location.search.replace('?', '').split('&').find((str:string) => str.includes('unit=')) || '';
  // let game = {time: 0, score: 0, stars: 0};
  useEffect(() => {
    // findStars();
  }, []);
  if (searchArr !== undefined) {
    unitName = searchArr.split('=')[1].replaceAll('%20', ' ').replaceAll('%27', "'");
    if (isNaN(Number(unitName.slice(0,2)))) {
      Key = Number(unitName.slice(0,1));
      unitName = unitName.slice(1);
    } else {
      Key = Number(unitName.slice(0,2));
      unitName = unitName.slice(2);
    }
  }
  // const findStars = () => {
  //   let gameInfo:any = reduxState.goldStar.find(
  //     (d) => d.game === reduxState.specificGame.game,
  //   );
  //   let difficultyGoldStar = {
  //     oneStar: gameInfo?.oneStarEasy,
  //     twoStar: gameInfo?.twoStarEasy,
  //     threeStar: gameInfo?.threeStarEasy,
  //     oneStarTwo: gameInfo?.oneStarTwoEasy,
  //     twoStarTwo: gameInfo?.twoStarTwoEasy,
  //     threeStarTwo: gameInfo?.threeStarTwoEasy,
  //   };
  //   if (reduxState.difficulty === 'medium') {
  //     difficultyGoldStar = {
  //       oneStar: gameInfo?.oneStarMedium,
  //       twoStar: gameInfo?.twoStarMedium,
  //       threeStar: gameInfo?.threeStarMedium,
  //       oneStarTwo: gameInfo?.oneStarTwoMedium,
  //       twoStarTwo: gameInfo?.twoStarTwoMedium,
  //       threeStarTwo: gameInfo?.threeStarTwoMedium,
  //     };
  //   } else if (reduxState.difficulty === 'hard') {
  //     difficultyGoldStar = {
  //       oneStar: gameInfo?.oneStarHard,
  //       twoStar: gameInfo?.twoStarHard,
  //       threeStar: gameInfo?.threeStarHard,
  //       oneStarTwo: gameInfo?.oneStarTwoHard,
  //       twoStarTwo: gameInfo?.twoStarTwoHard,
  //       threeStarTwo: gameInfo?.threeStarTwoHard,
  //     };
  //   }
  //   gameInfo = {
  //     game: gameInfo?.game,
  //     Key: gameInfo?.Key,
  //     notImplimented: gameInfo?.notImplimented,
  //     scoreType: gameInfo?.scoreType,
  //     ratings: [
  //       {
  //         type: gameInfo?.type,
  //         threeStar: Number(difficultyGoldStar.threeStar),
  //         twoStar: Number(difficultyGoldStar.twoStar),
  //         oneStar: Number(difficultyGoldStar.oneStar),
  //         stars: 0,
  //         nextGoals: null,
  //       },
  //       {
  //         type: gameInfo?.secondType,
  //         threeStar: Number(difficultyGoldStar.threeStarTwo),
  //         twoStar: Number(difficultyGoldStar.twoStarTwo),
  //         oneStar: Number(difficultyGoldStar.oneStarTwo),
  //         stars: 0,
  //         nextGoals: null,
  //       },
  //     ],
  //   };
  //   gameInfo.ratings = gameInfo?.ratings.filter((d:any) => d.type !== 'none');
  //   let correctedTime = gameTime === null ? 99999 : gameTime;
  //   const checkFuncObj = {
  //     correct: (val: number) => reduxState.stats.score >= val,
  //     time: (val: number) => correctedTime <= val,
  //     incorrect: (val: number) => reduxState.stats.misses <= val,
  //     accuracy: (val: number) => reduxState.stats.hits / (reduxState.stats.hits + reduxState.stats.misses) >= val,
  //   };
  //   gameInfo.ratings.forEach((d: {
  //     type: keyof Object,
  //     stars: number,
  //     nextGoals: number,
  //     threeStar: number,
  //     twoStar: number,
  //     oneStar: number,
  //   }) => {
  //     let checkFunc = checkFuncObj[d.type];
  //     if (checkFunc(d.threeStar)) {
  //       d.stars = 3;
  //     } else if (checkFunc(d.twoStar)) {
  //       d.stars = 2;
  //       d.nextGoals = d.threeStar;
  //     } else if (checkFunc(d.oneStar)) {
  //       d.stars = 1;
  //       d.nextGoals = d.twoStar;
  //     } else {
  //       d.nextGoals = d.oneStar;
  //     }
  //   });
  //   let object = {
  //     difficulty: reduxState.difficulty,
  //     score: reduxState.stats.score,
  //     time: gameTime,
  //     correct: reduxState.stats.hits,
  //     incorrect: reduxState.stats.misses,
  //     Key: reduxState.specificGame.Key,
  //     stars: gameInfo.ratings[0].stars,
  //     firstStars: gameInfo.ratings[0].stars,
  //     firstNextGoals: gameInfo.ratings[0].nextGoals,
  //     secondStars: null,
  //     secondNextGoals: null,
  //   };
  //   let fullGame = scoreData.find((d: {Key: number}) => d.Key === reduxState.specificGame.Key);
  //   // game = fullGame.difficultyScores[object.difficulty];
  //   setGame(fullGame.difficultyScores[object.difficulty]);
  //   if (gameInfo.ratings.length > 1) {
  //     if (fullGame.secondType !== 'none') {
  //       object.stars = gameInfo.ratings[0].stars + gameInfo.ratings[1].stars;
  //       object.secondStars = gameInfo.ratings[1].stars;
  //       object.secondNextGoals = gameInfo.ratings[1].nextGoals;
  //     }
  //   }
  //   setBestScore(game.score > object.score ? 'previous' : 'current');
  //   let starNum = Math.floor(object.stars / (fullGame.secondType === undefined ? 1 : 2));
  //   if (starNum >= 1) {
  //     setStarOne(true);
  //   }
  //   if (starNum >= 2) {
  //     setStarTwo(true);
  //   }
  //   if (starNum >= 3) {
  //     setStarThree(true);
  //   }
  //   setGameToSave(object);
  //   setStarsToAdd(object.stars - game.stars);
  //   if (starsToAdd < 0) {
  //     setStarsToAdd(0);
  //     setGameToSave(game);
  //   }
  // };

  return (
    <div>
      <div className="playagain_container">
        <div className="playagain_container_background" />
        <div className="playagain_contents">
          {/* Stat Keeper Start */}
          <div className="playagain_statkeeper">
            <div className="playagain_statkeeper_contents">
              {scoreType === 'time' ? (
                <div className="playagain_statkeeper_column_left">
                  <div className="playagain_statbanner">
                    <div className="playagain_scorecontainer">
                      <p className="playagain_statname">Time</p>
                    </div>
                    <p className="playagain_statnumber">{gameTime}</p>
                  </div>
                </div>
              ) : (
                <div className="playagain_statkeeper_column_left">
                <div className="playagain_statbanner">
                  <div className="playagain_scorecontainer">
                    <p className="playagain_statname">Hits</p>
                  </div>
                  <p className="playagain_statnumber">{reduxState.stats.hits}</p>
                </div>
                <div className="playagain_statbanner">
                  <div className="playagain_scorecontainer">
                    <p className="playagain_statname">Misses</p>
                  </div>
                  <p className="playagain_statnumber">{reduxState.stats.misses}</p>
                </div>
                <div className="playagain_statbanner">
                  <div className="playagain_scorecontainer">
                    <p className="playagain_statname">Score</p>
                  </div>
                  <p className="playagain_statnumber">{reduxState.stats.score}</p>
                </div>
                {gameTime !== null && scoreType !== 'normal' ? (
                  <div className="playagain_statbanner">
                    <div className="playagain_scorecontainer">
                      <p className="playagain_statname">Time</p>
                    </div>
                    <p className="playagain_statnumber">{gameTime}</p>
                  </div>
                ) : null}
              </div>
              )}
              <div style={{width: 0, border: '2px dotted rgba(55,55,55,0.4)', height: '90%', margin: 'clamp(5px, 2.5vmin, 20px)'}} />
              <div className="playagain_statkeeper_column">
                <div
                  className="playagain_closebutton"
                  onClick={() => setScreen('playScreen')}
                />
                <div className="playagain_levelcontainer">
                  <div className="playagain_leveltextcontainer">
                    <p className="playagain_leveltext">
                      Level {reduxState.playerLevelNumbers.playerLevel} {/* TODO: Automate this number */}
                    </p>
                  </div>
                </div>
                <div className="playagain_starcontainer">
                  <div className="playagain_starone">
                    {starsObj.starOne ? <div className="playagain_fullstar"/> : null}
                  </div>
                  <div className="playagain_startwo">
                    {starsObj.starTwo ? <div className="playagain_fullstar"/> : null}
                  </div>
                  <div className="playagain_starthree">
                    {starsObj.starThree ? <div className="playagain_fullstar"/> : null}
                  </div>
                </div>
                <div className="playagain_bluestreak">
                  <div className="playagain_fullscorecontainer">
                    <div className="playagain_scorecontainer">
                      <p className="playagain_yourscore">Your Score</p>
                    </div>
                    {scoreType === 'time' ? (
                      <p className="playagain_scorenumber">{gameTime}</p>
                    ) : (
                      <p className="playagain_scorenumber">{reduxState.stats.score}</p>
                    )}
                  </div>
                  {bestScore === 'current' ? (
                    <div className="playagain_highestscoreimg" />
                  ) : null}
                </div>
                <div className="playagain_bluestreak">
                  {reduxState.width > 500 ? (<div className="playagain_greenarrow" />) : null}
                  <div className="playagain_fullscorecontainer">
                    <div className="playagain_scorecontainer">
                      <p className="playagain_previousbest">Previous Best</p>
                    </div>
                    {scoreType === 'time' ? (
                      <p className="playagain_scorenumber">{game.time || 0}</p>
                    ) : (
                      <p className="playagain_scorenumber">{game.score || 0}</p>
                    )}
                  </div>
                  {bestScore === 'previous' ? (
                    <div className="playagain_highestscoreimg" />
                  ) : null}
                </div>
                <div
                  className="playagain_continuebutton"
                  onClick={() => {
                    updateScoreData(gameToSave); // TODO: send through the best score, not the most recent
                    dispatch(findPlayerLevel(starsToAdd, reduxState.playerLevelNumbers));
                    setScreen('playScreen');
                  }}
                />
              </div>
            </div>
          </div>
          {/* Stat Keeper End */}
        </div>
      </div>
      {/* UI Start */}
      <div>
        {/* Top Banner */}
        <div className="playagain_topbanner">
          <div className="playagain_topbanner_contents">
            <div
              className="playagain_topbanner_badge"
              style={{backgroundImage: `url(${fetchImage(`Common_Category_Badge_${Key}2x.png`)})`}}
            />
            <h1 className="playagain_topbanner_headertext">{unitName}</h1>
            <div
              className="playagain_topbanner_badge"
              style={{backgroundImage: `url(${fetchImage(`Common_Category_Badge_${Key}2x.png`)})`}}
            />
          </div> 
        </div>
        {/* Back Button */}
        <Link
          to={{
            pathname: goBack(location).pathname,
            search: goBack(location).search,
          }}
          className="playagain_backbutton"
        />
        <div className='playagain_gametypebadgecontainer'>
          <div
            className='playagain_gametypebadge'
            style={{backgroundImage: `url(${fetchImage(gameTypeImage)})`}}
          />
        </div>
      </div>
      {/* UI End */}
    </div>
  );
};

export default PlayAgainScreen;




