import './index.scss';
import '../../App.scss'
import { FC, useEffect, useState, useRef } from 'react';
import {
  fetchImage,
  redirectFromParams,
  compare,
  useLocationParams,
  getSearchParams,
} from '../nonUIFuncs';
import {
  useNavigate,
  useLocation,
  Link,
} from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../Store/hooks';
import { selectReduxSlice, getBottomPercentageValues } from '../../Store/store';

interface UnitProps {
  image: string;
  Key: number;
  unitName: string;
  displayStars: number;
}
const Unit: FC<UnitProps> = ({image, unitName, Key, displayStars}) => {
  return (
    <div className='unit_container'>
      <div className='unit_background' />
      <div className='unit_header'>
        <h4 className='unit_headertext'>{unitName}</h4>
      </div>
      <div className='unit_contents'>
        <div className='unit_centercontents'>
          <div
            className='unit_image'
            style={{backgroundImage:`url(${fetchImage(image)})`}}>
            <Link
              to={{
                pathname: '/choose-game-type',
                search: useLocationParams(`unit=${Key+unitName}`, [])
              }}
              className='unit_playbutton hovercursor'>
              {/* className='unit_playbutton hovercursor'
              onClick={() => filterData()}> */}
              <p className='unit_playbuttontext'>Play</p>
            </Link>
          </div>
        </div>
        <div className={`unit_stars_${displayStars}`} />
      </div>
    </div>
  );
};
interface unitsProps {
  name: string;
  unitName: string;
  image: string;
  Key: number;
  displayStars: number;
}
interface difficultyScoreProps {
  difficulty: number;
  category?: string;
  gameName?: string;
  gameType?: string;
  key?: string;
}
interface scoreDataProps {
  difficultyScores: {
    easy: difficultyScoreProps;
    medium: difficultyScoreProps;
    hard: difficultyScoreProps;
  };
  category: string;
  gameName: string;
  gameType: string;
}
interface UnitMenuProps {
  scoreData: any;
  dataset: string;
}

interface UnitDisplaySmallScreenProps {
  units: Array<{
    image: string;
    unitName: string;
    Key: number;
    displayStars: number;
  }>;
}

const UnitDisplaySmallScreen: FC<UnitDisplaySmallScreenProps> = ({units}) => {
  const currentUnitRef = useRef(1);
  const [currentUnit, setCurrentUnit] = useState(1);
  currentUnitRef.current = currentUnit;
  const backwardUnit = () => {
    if (currentUnitRef.current === 1) {
      setCurrentUnit(units.length);
    } else {
      setCurrentUnit(currentUnitRef.current - 1);
    }
  };
  const forwardUnit = () => {
    if (currentUnitRef.current === units.length) {
      setCurrentUnit(1);
    } else { 
      setCurrentUnit(currentUnitRef.current + 1);
    }
  };
  let currentUnitData =
    units.find((d) => d.Key === currentUnit)
      || {image: '', unitName: '', Key: 1, displayStars: 0};
  return (
    <div className='unitmenu_smallscreencontainer'>
      <div onClick={backwardUnit} className='unitmenu_backwardunit' />
      <Unit {...currentUnitData} />
      <div onClick={forwardUnit} className='unitmenu_forwardunit' />
    </div>
  );
};

const UnitMenu: FC<UnitMenuProps> = ({scoreData, dataset}) => {
  const reduxState = useAppSelector(selectReduxSlice);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let difficultyFromLocation = Number(getSearchParams(location).find((param) => param.id === 'difficulty').value);
  const reduxStateRef = useRef(reduxState);
  const [units, setUnits] = useState<Array<unitsProps>>([]);
  reduxStateRef.current = reduxState;

  useEffect(() => {
    document.title = `${dataset === 'b_' ? 'Benhti' : "Denaakk'e"} Kids Zone - Units`;
    let redirectPath:string = redirectFromParams(['difficulty', 'location']) || '';
    if (redirectPath.length > 0) {
      navigate(redirectPath);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (dataset === 'b_') {
      findDifficultyGames(reduxStateRef.current.b_data, reduxStateRef.current.b_gameData);
    } else {
      findDifficultyGames(reduxStateRef.current.data, reduxStateRef.current.gameData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreData, dataset]);
  interface unitArrayProps {
    Key: number;
    name: string;
    unitName: string;
    image: string;
    currentStars: number;
    totalStars: number;
    displayStars: number;
  }
  let unitArr: Array<unitArrayProps> = [];
  let unitNumArr:number[] = [];
  const findDifficultyGames = (data: any, gameData: any) => {
    let games: Array<difficultyScoreProps> = [];
    scoreData.forEach((d: scoreDataProps) => {
      if (d.difficultyScores.easy.difficulty === difficultyFromLocation) {
        games.push({
          ...d.difficultyScores.easy,
          category: d.category,
          gameName: d.gameName,
          gameType: d.gameType,
          key: `${d.category}${d.gameName}Easy`,
        });
      } else if (
        d.difficultyScores.medium.difficulty === difficultyFromLocation
      ) {
        games.push({
          ...d.difficultyScores.medium,
          category: d.category,
          gameName: d.gameName,
          gameType: d.gameType,
          key: `${d.category}${d.gameName}Medium`,
        });
      } else if (
        d.difficultyScores.hard.difficulty === difficultyFromLocation
      ) {
        games.push({
          ...d.difficultyScores.hard,
          category: d.category,
          gameName: d.gameName,
          gameType: d.gameType,
          key: `${d.category}${d.gameName}Hard`,
        });
      }
    });
    // setDifficultyGames(games);
    findUnits(games, data, gameData);
  };
  const findUnits = (games: any, data: any, gameData: any) => {
    interface dataProps {
      lesson: number;
      homeName: string;
    }
    gameData.forEach((datum: dataProps) => {
      if (
        !unitNumArr.includes(datum.lesson) &&
        typeof datum.lesson === 'number'
      ) {
        unitNumArr.push(datum.lesson);
        unitArr.push({
          Key: datum.lesson,
          name: `Unit ${datum.lesson}`,
          unitName: datum.homeName,
          image: `Category_Selection_${datum.lesson}2x.png`,
          currentStars: 0,
          totalStars: 0,
          displayStars: 0,
        });
      }
      unitArr.sort((a, b) => compare(a.Key, b.Key));
    });
    findStars(unitArr, games, data);
  };
  const findStars = (unitArr: any, games: any, data: any) => {
    let testTotal = 0;
    let testCurrent = 0;
    let adjustedUnits = unitArr.map((unit: any) => {
      let currentStars = 0;
      let totalStars = 0;
      let displayStars = 0;
      games.forEach((d:any) => {
        if (d.category === unit.unitName) {
          currentStars = currentStars + d.stars;
          let goldStar = reduxStateRef.current.goldStar.find((g) => g.game === d.gameType);
          if (goldStar?.secondType !== 'none') {
            totalStars = totalStars + 6;
          } else {
            totalStars = totalStars + 3;
          }
        }
      });
      testTotal = testTotal + totalStars;
      testCurrent = testCurrent + currentStars;
      let percent = (currentStars / totalStars) * 100;
      if (percent >= 99) {
        displayStars = 3;
      } else if (percent >= 50 && percent < 99) {
        displayStars = 2;
      } else if (percent >= 1 && percent < 50) {
        displayStars = 1;
      } else {
        displayStars = 0;
      }
      return {
        ...unit,
        totalStars: totalStars,
        currentStars: currentStars,
        displayStars: displayStars,
      };
    });
    let categoriesWGames: Array<string> = [];
    data.forEach((d: any) => {
      if (!categoriesWGames.includes(d.category)) {
        categoriesWGames.push(d.category);
      }
    });
    let filteredUnits =
      adjustedUnits.filter((d: any) => categoriesWGames.includes(d.unitName));
    dispatch(getBottomPercentageValues(testCurrent, testTotal));
    setUnits(filteredUnits);
  };
  return (
    <div className='all_pages_container unitmenu_container'>
      <div className='unitmenu_bannercontainer'>
        <div className='unitmenu_banner'>
          <h1>Pick A Unit!</h1>
        </div>
        <div className='unitmenu_guidecharacter' />
      </div>
      {reduxStateRef.current.width > 775 ? (
        <div className='unitmenu_contents'>
          {units.map((unit) => {
            return <Unit key={unit.name} {...unit} />;
          })}
        </div>
      ) : (
        <UnitDisplaySmallScreen units={units} />
      )}

    </div>
  );
};

export default UnitMenu;
