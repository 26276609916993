import './index.scss';
import '../../../App.scss';
import { FC, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { useLocationParams, fetchImage } from '../../nonUIFuncs';
import ProgressBar from '../ProgressBar';

interface IndviGameProps {
  path: string;
  name: string;
  progress: string;
  stars: number;
  image: string;
  paramToSet: string;
  classNamePre: string;
  onmouseover: MouseEventHandler;
  onClick?: MouseEventHandler;
  squishName?: string;
}

const IndivGame: FC<IndviGameProps> = (
  {
    name,
    progress,
    stars,
    image,
    paramToSet,
    classNamePre = 'indivgame',
    onmouseover,
    path,
    onClick,
  }
) => {
  // const dispatch = useAppDispatch();
  return (
    <Link
      onMouseOver={onmouseover}
      to={{
        pathname: path,
        search: useLocationParams(`${paramToSet}=${name}`, [])
      }}
      onClick={onClick}
      className={`${classNamePre}_container hovercursor`}>
      <div className={`${classNamePre}_innercontainer`}>
        <div className={`${classNamePre}_badge`}>
          <div
            className={`${classNamePre}_gameimg`}
            style={{backgroundImage: `url(${fetchImage(image)})`}}
          />
          <div className={`${classNamePre}_badgebanner`}>
            <div
              className={`${classNamePre}_${stars >= 1 ? 'Star' : 'Star_Base'}`}
            />
            <div
              className={`${classNamePre}_${stars >= 2 ? 'Star' : 'Star_Base'}`}
            />
            <div
              className={`${classNamePre}_${stars >= 3 ? 'Star' : 'Star_Base'}`}
            />
          </div>
        </div>
        <div className={`${classNamePre}_titlecontainer`}>
          <h2 className={`${classNamePre}_title`}>{name}</h2>
          <div className={`${classNamePre}_progressbarcontainer`}>
            <ProgressBar
              width='clamp(37px, 7.5vw, 98px)'
              id={`${name}_IndivGame`}
              progress={progress}
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default IndivGame;
