import './index.scss';
import { useState, useRef, useEffect, FC } from 'react';
import { useAppSelector, useAppDispatch } from '../../Store/hooks';
import { selectReduxSlice, setDialectNumberData } from '../../Store/store';
import {
  goBack,
  fetchImage,
  findSingleGameData,
  getSearchParams
} from '../nonUIFuncs';
import { useLocation, Link } from 'react-router-dom';
import WordToImage from '../WordToImage';
import WhackAMole from '../WhackAMole';
import Matching from '../Matching';
import GoodGuess from '../GoodGuess';
import Wordless from '../Wordless';
import BubblePop from '../BubblePop';
import Counting from '../Counting';

interface StarsObjProps {
    starOne: boolean;
    starTwo: boolean;
    starThree: boolean;
  };

interface AllGameSwitcherProps {
  gameType: string;
  checkWorld: Function;
  updateLevelData: Function;
  children: any;
  setProgress: Function;
  screenCover: boolean;
  setScreenCover: Function;
  findStars: Function;
  starsObj: StarsObjProps;
  dataset: string;
};

const AllGameSwitcher: FC<AllGameSwitcherProps> = (
  {
    gameType,
    checkWorld,
    updateLevelData,
    children,
    setProgress,
    screenCover,
    setScreenCover,
    findStars,
    starsObj,
    dataset,
  }
) => {
  switch (gameType) {
  case 'Word To Image':
    return (
      <WordToImage
        checkWorld={checkWorld}
        updateLevelData={updateLevelData}
        children={children}
        setProgress={setProgress}
        setScreenCover={setScreenCover}
        findStars={findStars}
        starsObj={starsObj}
        dataset={dataset}
      />
    );
  case 'Whack-A-Mole':
    return (
      <WhackAMole
        children={children}
        setProgress={setProgress}
        checkWorld={checkWorld}
        updateLevelData={updateLevelData}
        findStars={findStars}
        starsObj={starsObj}
        dataset={dataset}
      />
    );
  case 'Matching':
    return (
      <Matching
        checkWorld={checkWorld}
        updateLevelData={updateLevelData}
        setProgress={setProgress}
        children={children}
        findStars={findStars}
        starsObj={starsObj}
        dataset={dataset}
      />
    );
  case 'Good Guess':
    return (
      <GoodGuess
        checkWorld={checkWorld}
        updateLevelData={updateLevelData}
        children={children}
        setProgress={setProgress}
        findStars={findStars}
        starsObj={starsObj}
        dataset={dataset}
      />
    );
  case 'Wordless':
    return (
      <Wordless
        checkWorld={checkWorld}
        updateLevelData={updateLevelData}
        children={children}
        setProgress={setProgress}
        setScreenCover={setScreenCover}
        findStars={findStars}
        starsObj={starsObj}
        dataset={dataset}
      />
    );
  case 'Bubble Pop':
    return (
      <BubblePop
        checkWorld={checkWorld}
        updateLevelData={updateLevelData}
        children={children}
        setProgress={setProgress}
        findStars={findStars}
        starsObj={starsObj}
        dataset={dataset}
      />
    );
  case 'Counting':
    return (  
      <Counting
        checkWorld={checkWorld}
        updateLevelData={updateLevelData}
        children={children}
        setProgress={setProgress}
        findStars={findStars}
        starsObj={starsObj}
        dataset={dataset}
      />
    );
  default: return (
    <div>
      No Game Found
    </div>
  );
  }
};

interface GameContainerProps {
  setScreen: Function;
  setGameTime: Function;
  updateLevelData: Function;
  gameTime: number|null;
  setProgress: Function;
  scoreType: string;
  gameTypeImage: string;
  findStars: Function;
  starsObj: {
    starOne: boolean;
    starTwo: boolean;
    starThree: boolean;
  };
  dataset: string;
}

const GameContainer: FC<GameContainerProps> = ({
  setScreen,
  setGameTime,
  updateLevelData,
  setProgress,
  scoreType,
  gameTypeImage,
  findStars,
  starsObj,
  dataset,
}) => {
  const displayTimeRef = useRef(0);
  const [displayTime, setDisplayTime] = useState(0);
  const [screenCover, setScreenCover] = useState(false);
  const reduxState = useAppSelector(selectReduxSlice);
  const dispatch = useAppDispatch();
  const reduxStateRef = useRef(reduxState);
  const location = useLocation();
  const params = getSearchParams(location);
  const locationParam = params.find((d) => d.id === 'location')?.value;
  reduxStateRef.current = reduxState;
  displayTimeRef.current = displayTime;

  useEffect(() => {
    let timer = setInterval(() => setDisplayTime(displayTimeRef.current + 1), 1000);
    dispatch(
      setDialectNumberData(
        findSingleGameData(
          {target: 'How Many Are There? 1'}, reduxState, locationParam, dataset
        ),
      ),
    );
    return () => {
      clearInterval(timer);
    };
  }, []);
  let unitName = '';
  let Key:number = 1;
  let gameType =
    location.search
      .replace('?', '')
      .split('&')
      .find((str:string) => str.includes('game-type='))
      ?.split('=')[1]
      .replaceAll('%20', ' ')
      .replaceAll('%27', "'");
  let searchArr =
    location.search
      .replace('?', '')
      .split('&')
      .find((str:string) => str.includes('unit='));
  if (searchArr !== undefined) {
    unitName = searchArr.split('=')[1].replaceAll('%20', ' ').replaceAll('%27', "'");
    if (isNaN(Number(unitName.slice(0,2)))) {
      Key = Number(unitName.slice(0,1));
      unitName = unitName.slice(1);
    } else {
      Key = Number(unitName.slice(0,2));
      unitName = unitName.slice(2);
    }
  }
  const checkWorld = () => {
    setGameTime(displayTimeRef.current);
    setScreen('playAgainScreen');
  }
  const findStarsUpdated = () => {
    setGameTime(displayTimeRef.current);
    findStars(displayTimeRef.current);
  };
  return (
    <div>
      <div className="gamecontainer_container">
        <div className="gamecontainer_container_background" />
        <div className='gamecontainer_contents'>
          <AllGameSwitcher
            setProgress={setProgress}
            checkWorld={checkWorld}
            gameType={typeof gameType === 'string' ? gameType : ''}
            updateLevelData={updateLevelData}
            screenCover={screenCover}
            setScreenCover={setScreenCover}
            findStars={findStarsUpdated}
            starsObj={starsObj}
            dataset={dataset}
            children={
              <div>
                {/* UI Start */}
                {/* Top Banner */}
                <div className="gamecontainer_topbanner">
                  <div className="gamecontainer_topbanner_contents">
                    <div
                      className="gamecontainer_topbanner_badge"
                      style={{
                        backgroundImage: `url(${fetchImage(`Common_Category_Badge_${Key}2x.png`)})`,
                      }}
                    />
                    <h1 className="gamecontainer_topbanner_headertext">{unitName}</h1>
                    <div
                      className="gamecontainer_topbanner_badge"
                      style={{
                        backgroundImage: `url(${fetchImage(`Common_Category_Badge_${Key}2x.png`)})`,
                      }}
                    />
                  </div>
                </div>
                {/* Back Button */}
                <Link
                  to={{
                    pathname: goBack(location).pathname,
                    search: goBack(location).search,
                  }}
                  className="gamecontainer_backbutton"
                />
                {/* UI End */}
              </div>
            }
          />
        </div>
        <div className={`gamecontainer_gametypebadgecontainer${scoreType === 'time' ? '_alt' : ''}`}>
          <div
            className='gamecontainer_gametypebadge'
            style={{backgroundImage: `url(${fetchImage(gameTypeImage)})`}}
          />
        </div>
        <div className='gamecontainer_statcontainer'>
          {scoreType !== 'time' ? (
            <div className='gamecontainer_statcontents'>
              <h2 className='gamecontainer_statcontents_stat'>
                {reduxState.stats.score}
              </h2>
              <div className='gamecontainer_statcontents_banner'>
                <p>Score</p>
              </div>
            </div>
          ) : (
            <div className='gamecontainer_statcontents' style={{opacity: 0}} />
          )}
          {scoreType !== 'normal' ? (
            <div className='gamecontainer_statcontents'>
              <h2 className='gamecontainer_statcontents_stat'>
                {displayTimeRef.current}
              </h2>
              <div className='gamecontainer_statcontents_banner'>
                <p>Time</p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {screenCover && (gameType === 'Wordless' || gameType === 'Word To Image') ? (
        <div
          className='timeoutdiv'
          onClick={() => setScreenCover(false)}
        />
      ) : null}
    </div>
  );
};

export default GameContainer;
