import './index.scss';
import '../../App.scss';

import { useEffect, useRef, useState, FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { selectReduxSlice, setStats } from '../../Store/store';
import { useLocation } from 'react-router-dom';
import { fetchAudio, fetchImage, shuffle, placementArray, getSearchParams, capEveryWord } from '../nonUIFuncs';

import CardFlip from '../UI/CardFlip';
import PopupWord from '../UI/PopupWord';
import CloseEndless from '../UI/CloseEndless';
import EndEndlessGame from '../UI/EndEndlessGame';
import RoundBanner from '../UI/RoundBanner';

const MATCH_ANIMATION_TIMER = 800;
let difficulty = 'easy';
let targetBodyHeight = 45;
let targetBodyWidth = 45;
let positionArrayLength: number|null = 1;
let cardFlipArray: Array<any> = [];
let cardAudio: any = null;
let filtered: any = undefined;
let switchData = 0;
let shortTimer = 0;
let switchDisplayVar = 'stats';
let firstImg: null|string = null;
let firstId: string = '';
let firstAudio: string = '';
let removedCard: Array<any> = [];
let flippedCurrent = {};
let resetWordBox: any = null;
let testNum = 0;
let showPopup = false;
let currentLevelVar = 0;
let correctSoundEffect = new Audio(`${fetchAudio('correctSound.mps')}`);
let ready = false;
let round = 0;

interface GameContainerProps {
  checkWorld: Function;
  updateLevelData: Function;
  setProgress: Function;
  children: any;
  findStars: Function;
  starsObj: {starOne: boolean; starTwo: boolean; starThree: boolean};
  dataset: string;
}

const Matching: FC<GameContainerProps> = ({
  checkWorld,
  updateLevelData,
  setProgress,
  children,
  findStars,
  starsObj,
  dataset,
}) => {
  const reduxState = useAppSelector(selectReduxSlice);
  const Location = useLocation();
  const Params = getSearchParams(Location);

  const displayedClockRef = useRef(0);
  const currentLevelRef = useRef(0);
  const currentLevelProgressRef = useRef(0);
  const reduxStateRef = useRef(reduxState);
  const flippedCurrentRef = useRef(flippedCurrent);

  const [correctMatchWord, setCorrectMatchWord] = useState(false);
  const [chosenPopupWord, setChosenPopupWord] = useState('');
  const [matchedWord, setMatchedWord] = useState('');
  const [currentLevel, setCurrentLevel] = useState(0);
  const [displayedClock, setDisplayedClock] = useState(0);
  const [currentLevelProgress, setCurrentLevelProgress] = useState(0);
  const [showEndlessEnd, setShowEndlessEnd] = useState(false);

  reduxStateRef.current = reduxState;

  // targetBodyHeight = reduxStateRef.current.height / 3.25;
  // targetBodyWidth = targetBodyHeight * 0.643;

  displayedClockRef.current = displayedClock;
  currentLevelProgressRef.current = currentLevelProgress;
  currentLevelRef.current = currentLevel;
  flippedCurrentRef.current = flippedCurrent;


  useEffect(() => {
    let string = `${Params.find((d) => d.id === "unit").value} - ${Params.find((d) => d.id === "game-name").value} - ${reduxStateRef.current.difficulty}`;
    document.title = capEveryWord(string);
    let timer = setInterval(updateDisplayClock, 1000);
    currentLevelVar = 0;
    setCurrentLevel(0);
    setCurrentLevelProgress(0);
    // targetBodyHeight = reduxStateRef.current.height / 3.25;
    // targetBodyWidth = targetBodyHeight * 0.643;
    switchDisplayVar = 'stats';
    flippedCurrent = {};
    removedCard = [];
    difficulty = reduxStateRef.current.difficulty;
    changeDisplay();
    ready = true;
    return () => {
      clearTimeout(resetWordBox);
      positionArrayLength = null;
      difficulty = 'easy';
      switchDisplayVar = 'stats';
      switchData = 0;
      round = 0;
      setDisplayedClock(0);
      clearInterval(timer);
      stopAudio();
      cardFlipArray = [];
      firstImg = null;
      firstId = '';
      firstAudio = '';
    }
  }, []);
  const updateDisplayClock = () => {
    setDisplayedClock(displayedClockRef.current + 1);
    shortTimer = shortTimer + 1;
  };
  const changeDisplay = () => {
    shortTimer = 0;
    switchData = switchData + 1;
    positionArrayLength = null;
    setDisplayedClock(0);
    firstImg = null;
    firstId = '';
    firstAudio = '';
    removedCard = [];
    flippedCurrent = {};
    cardFlipArray = [];
    let spliceNumber = 0;
    let positionArray: Array<any> = [];
    let positionArrayDenom: Array<any> = [];
    if (difficulty === 'easy') {
      spliceNumber = 4;
      positionArray = placementArray(0.4, 0.5, 4.4, 2.5, 1, 1);
      if (reduxStateRef.current.height > reduxStateRef.current.width) {
        targetBodyWidth = reduxStateRef.current.width / 5.25;
        targetBodyHeight = targetBodyWidth * (1 / 0.643);
        positionArrayDenom = [5, 3];
      } else {
        targetBodyHeight = reduxStateRef.current.height / 4;
        targetBodyWidth = targetBodyHeight * 0.643;
        positionArrayDenom = [5, 3];
      }
    } else if (difficulty === 'medium') {
      spliceNumber = 6;
      positionArrayDenom = [5,4];
      positionArray = placementArray(0.4, .7, 4.4, 3.1, 1, 0.82);
      if (reduxStateRef.current.height > reduxStateRef.current.width) {
        targetBodyWidth = reduxStateRef.current.width / 10;
        targetBodyHeight = targetBodyWidth * (1 / 0.643);
      } else {
        targetBodyHeight = reduxStateRef.current.height / 5.5;
        targetBodyWidth = targetBodyHeight * 0.643;
      }
    } else if (difficulty === 'hard') {
      spliceNumber = 8;
      positionArray = placementArray(0.35, 0.7, 4.35, 3.1, 1, 0.8);
      positionArrayDenom = [5, 5];
      if (reduxStateRef.current.height > reduxStateRef.current.width) {
        targetBodyWidth = reduxStateRef.current.width / 10;
        targetBodyHeight = targetBodyWidth * (1 / 0.643);
      } else {
        targetBodyHeight = reduxStateRef.current.height / 8;
        targetBodyWidth = targetBodyHeight * 0.643;
      }
    }
    let selected = shuffle(reduxStateRef.current.sortedData).splice(0, spliceNumber);
    let gameData = [...selected];
    let copiedA = selected.map((obj) => {
      return {...obj, id: obj.id + 'copy'};
    });
    gameData.push(...copiedA);
    positionArrayLength = positionArray.length;
    while (gameData.length > 0) {
      let randomPositionNum = Math.floor(Math.random() * positionArray.length);
      let rpX = positionArray[randomPositionNum].x;
      let rpY = positionArray[randomPositionNum].y;
      positionArray.splice(randomPositionNum, 1);
      let randomObjectNum = Math.floor(Math.random() * gameData.length);
      let currentObject = gameData[randomObjectNum];
      gameData.splice(randomObjectNum, 1);
      let targetSprite = `url(${fetchImage(currentObject.App_Art, dataset)})`;
      let faceDownImg = `url(${fetchImage('Category_Selection_BG_12x.png')})`;
      cardFlipArray.push(
        (currentObject = {
          Language: currentObject.Language,
          App_Audio: currentObject.App_Audio,
          identifier: `${currentObject.Key}${rpX}${rpY}`,
          targetBodyWidth: targetBodyWidth,
          targetBodyHeight: targetBodyHeight,
          frontImage: faceDownImg,
          backImage: targetSprite,
          height: reduxStateRef.current.height,
          width: reduxStateRef.current.width,
          rpX: rpX,
          rpY: rpY,
          positionArrayDenom: positionArrayDenom,
          selectCard: _selectCard,
          flipped: () => flippedCurrent,
        })
      );
    }
  };
  const editCardFlipArray = () => {
    if (difficulty === 'easy') {
      if (reduxStateRef.current.height > reduxStateRef.current.width) {
        targetBodyWidth = reduxStateRef.current.width / 5.25;
        targetBodyHeight = targetBodyWidth * (1/0.643);
      } else {
        targetBodyHeight = reduxStateRef.current.height / 4;
        targetBodyWidth = targetBodyHeight * 0.643;
      }
    } else {
      if (reduxStateRef.current.height > reduxStateRef.current.width) {
        targetBodyWidth = reduxStateRef.current.width / 10;
        targetBodyHeight = targetBodyWidth * (1/0.643);
      } else {
        if (difficulty === 'medium') {
          targetBodyHeight = reduxStateRef.current.height / 5.5;
        } else {
          targetBodyHeight = reduxStateRef.current.height / 8;
        }
        targetBodyWidth = targetBodyHeight * 0.643;
      }
    }
    cardFlipArray.forEach((d) => {
      d.height = reduxStateRef.current.height;
      d.width = reduxStateRef.current.width;
      d.targetBodyHeight = targetBodyHeight;
      d.targetBodyWidth = targetBodyWidth;
    });
  };
  const removeCorrectCards = () => {
    cardFlipArray = filtered;
  };
  const nextEndlessLevel = () => {
    setCurrentLevel(currentLevelRef.current + 1);
    currentLevelVar = currentLevelVar + 1;
    setCurrentLevelProgress(0);
    let number = document.getElementById('levelNumber');
    number?.classList.add('levelNumber');
    setTimeout(
      () => number?.classList.remove('levelNumber'),
      1200
    );
  };
  const checkCorrectness = (
    id: string,
    App_Art: string,
    App_Audio: string,
    flipped: any,
    Language: string,
  ) => {
    if (firstImg !== null && firstId !== id) {
      if (firstImg === App_Art && firstId !== id && firstAudio === App_Audio) {
        let popupWords = reduxStateRef.current.popupWords.filter(
          (d) => d.type === 'correct',
        );
        let randomPopupNum = Math.floor(Math.random() * popupWords.length);
        showPopup = true;
        setTimeout(() => (showPopup = false), 1300);
        let progress = currentLevelProgressRef.current + 1;
        setCurrentLevelProgress(progress);
        if (
          currentLevelRef.current + 2 === progress &&
          Location?.state?.endless === true
        ) {
          setTimeout(nextEndlessLevel, 700);
        }
        correctSoundEffect.play();
        filtered = cardFlipArray.filter(
          (d) => d.App_Audio !== firstAudio
        );
        flipped[id] = false;
        flipped[firstId] = false;
        setTimeout(() => {
          removeCorrectCards();
          removedCard = [...removedCard, id, firstId];
        }, MATCH_ANIMATION_TIMER);
        setMatchedWord(Language);
        setChosenPopupWord(popupWords[randomPopupNum].English);
        setCorrectMatchWord(true);
        resetWordBox = setTimeout(() => setCorrectMatchWord(false), 3000);
      } else {
        setTimeout(() => flippedCurrent = {}, 300);
        let popupWords = reduxStateRef.current.popupWords.filter((d) => d.type === 'incorrect');
        let randomPopupNum = Math.floor(Math.random() * popupWords.length);
        showPopup = true;
        setTimeout(() => (showPopup = false), 1300);
        flipped[id] = false;
        flipped[firstId] = false;
        setChosenPopupWord(popupWords[randomPopupNum].English);
      }
      if (removedCard.length !== positionArrayLength) {
        firstImg = null;
        firstId = '';
        firstAudio = '';
      }
    }
  };
  const _selectCard = (
    e: {target:any},
    id: string,
    App_Art: string,
    App_Audio: string,
    Language: string,
  ) => {
    if (cardAudio !== null) {
      cardAudio.pause();
      cardAudio = null;
    }
    cardAudio = new Audio(`${fetchAudio(App_Audio, dataset)}`);
    if (App_Audio.length > 0) {
      cardAudio.play();
    }
    let flipped: any = { ...flippedCurrent };
    flipped[id] = true;
    if (!e.target.matches('.cardflip_back')) {
      if (firstImg === null) {
        firstImg = App_Art;
        firstId = id;
        firstAudio = App_Audio;
      } else if (firstId !== id) {
        setTimeout(checkCorrectness, 1000, id, App_Art, App_Audio, flipped, Language);
      }
      flippedCurrent = flipped;
    }
  };
  const stopAudio = () => {
    if (cardAudio !== null) {
      cardAudio.pause();
      cardAudio = null;
    }
  };
  const setStatDisplay = () => {
    switchDisplayVar = 'stats';
    shortTimer = 0;
  };
  const stepDifficulty = () => {
    setProgress('0%');
    ready = false;
    switchDisplayVar = 'game';
    setDisplayedClock(0);
    if (round < 3) {
      round = round + 1;
      changeDisplay();
      ready = true;
    } else {
      checkWorld();
    }
  };
  if (switchDisplayVar === 'stats') {
    if (Location?.state?.endless === true) {
      switchDisplayVar = 'game';
      changeDisplay();
      ready = false;
      return null;
    }
    if (shortTimer > 1) {
      stepDifficulty();
    }
  } else {
    if (testNum !== reduxStateRef.current.width * reduxStateRef.current.height) {
      testNum = reduxStateRef.current.width * reduxStateRef.current.height;
      editCardFlipArray();
    }
    if (positionArrayLength === removedCard.length && ready === true) {
      setTimeout(setStatDisplay, MATCH_ANIMATION_TIMER);
      shortTimer = 0;
      switchDisplayVar = 'stats';
    }
  }
  return (
    <div className='matching_startscreen'>
      {children}
      {switchDisplayVar === 'stats' ? (
        <RoundBanner
          round={round + 1}
          findStars={findStars}
          starsObj={starsObj}
        />
      ) : (
        <div>
        {cardFlipArray.map((cd) => {
          return (
            <CardFlip
              key={cd.identifier}
              cardData={cd}
              flippedCurrent={flippedCurrentRef.current}
            />
          );
        })}
        {correctMatchWord ? (
          <div className='matching_keywordbox noselect'>
            <div
              className='keyword matching_keywordtext'
              style={{
                fontSize:
                  matchedWord.length > 21 
                    ? '2vmin'
                    : matchedWord.length > 12 ? '3vmin' : '5vmin',
              }}>
              {matchedWord}
            </div>
          </div>
        ) : null}
        <PopupWord popupWord={chosenPopupWord} enabled={showPopup} />
        <CloseEndless enabled={Location?.state?.endless} onClick={setShowEndlessEnd} />
        <EndEndlessGame
          enabled={showEndlessEnd}
          currentLevel={currentLevel}
          setShowEndlessEnd={setShowEndlessEnd}
          updateLevelData={updateLevelData}
          endGame={undefined}
        />
      </div>
      )}
    </div>
  );
};

export default Matching;
